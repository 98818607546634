import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, catchError, distinctUntilChanged, filter, of, switchMap, take, takeUntil } from 'rxjs';
import { LoginService } from './pages/auth/services/login.service';
import { InitialConfigService } from './services/initial-configuration';
import { CustomerService } from './services/utils/user/customer-handler.service';
import { UserSessionService } from './services/utils/user/user-session.service';
import { permissionsName, profileTypes } from './utils/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    private unsubscribeInit$: Subject<void>;
    private redirectToPortalRoutes = [
        '/login',
        '/password',
        '/signup',
        '/signup-guest',
        '/activate',
        '/activate-guest',
    ];
    constructor(
        private customerService: CustomerService,
        private ngxPermissionsService: NgxPermissionsService,
        private router: Router,
        private loginService: LoginService,
        private userSessionService: UserSessionService,
        private ngZone: NgZone,
        private initialConfigurationService: InitialConfigService
    ) {
        this.unsubscribe$ = new Subject<void>();
        this.unsubscribeInit$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeRouterEventsForPermissionsWorkaround();
        this.setInitialConfiguration();
        this.checkSessionTokenChange();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
        this.unsubscribeInit$.next();
        this.unsubscribeInit$.complete();
    }

    unsubscribe(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // TODO: Refactor this implementation to address the issue when a customer switches from authenticated to guest mode.
    // The current approach opens a new channel with new observables each time the profile changes,
    // which causes problems. Closing the observable is not a viable solution as it may lead to issues
    // when the token expires and the customer attempts to log in again.
    setInitialConfiguration(): void {
        this.userSessionService.onChangeProfile$().subscribe({
            next: (onChangeProfile) => {
                if (onChangeProfile) {
                    this.unsubscribe();
                    return this.setInitialConfig();
                }
            },
        });
    }

    private setInitialConfig(): void {
        this.initialConfigurationService
            .getCustomerNotifications()
            .pipe(
                take(1),
                catchError(() => of('Complete'))
            )
            .subscribe();

        // TODO: Implement a mechanism to display an error or block the system if this fails - medium
        this.initialConfigurationService.setRolesAndPermissions().pipe(takeUntil(this.unsubscribe$)).subscribe();

        // TODO: We shouldn't need to get the payment method events if the customer doesn't supports it
        this.initialConfigurationService
            .getEventsPaymentMethods()
            .pipe(
                catchError(() => of('Complete')),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        // TODO: We shouldn't need to get the payment method events if the customer doesn't supports it
        this.userSessionService
            .hasTokenAndIsNotExpired$()
            .pipe(
                filter((hasTokenAndIsNotExpired) => hasTokenAndIsNotExpired),
                distinctUntilChanged(),
                switchMap(() => this.initialConfigurationService.getPaymentMethodsAndSetByDefaultIfNeeded()),
                catchError(() => of('Complete')),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        // TODO: We shouldn't need to get the payment methods if the customer doesn't supports it
        this.userSessionService
            .hasTokenAndIsNotExpired$()
            .pipe(
                filter((hasTokenAndIsNotExpired) => hasTokenAndIsNotExpired),
                switchMap(() => this.initialConfigurationService.getPaymentMethodSelectedAndGetCart$()),
                catchError(() => of('Complete')),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.userSessionService
            .isAuthenticatedAndIsAValidToken$()
            .pipe(
                filter((authenticated) => authenticated),
                switchMap(() => this.initialConfigurationService.getPaymentMethodsOnCartAboveThreshold()),
                catchError(() => of('Complete')),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(); // TODO: We shouldn't need to get the payment methods if the customer doesn't supports it
    }

    checkSessionTokenChange(): void {
        this.userSessionService
            .hasToken$()
            .pipe(
                filter((token) => token),
                switchMap(() => this.userSessionService.isAuthenticated$()),
                filter((authenticated) => authenticated),
                takeUntil(this.unsubscribeInit$)
            )
            .subscribe({
                next: () => {
                    this.redirectToAdminPortal();
                },
            });
    }

    private redirectToAdminPortal(): void {
        const route = `/${this.router.url.split('/')[1]}`;
        if (!this.redirectToPortalRoutes.includes(route)) return;
        const customer = this.customerService.getCustomer();
        const customerProfile =
            customer?.profileType && customer.profileType !== profileTypes.FORWARDER_FACILITY
                ? customer.profileType
                : profileTypes.FORWARDER;
        this.customerService.setActiveProfileView(customerProfile);
        const url = this.loginService.getRedirectLink();
        this.ngZone.run(() => this.router.navigate([url]));
    }

    private subscribeRouterEventsForPermissionsWorkaround(): void {
        this.router.events.pipe(takeUntil(this.unsubscribeInit$)).subscribe((event) => {
            if (event instanceof NavigationStart && event.url.includes('admin/facilityPayments/newPayment?')) {
                this.ngxPermissionsService.addPermission(permissionsName.guest);
            }
        });
    }
}
