/* eslint-disable prettier/prettier */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentCart, Source } from '@cargos/sprintpay-models';
import { LoggerErrorService } from '@cargos/sprintpay-services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, finalize, switchMap, take, tap } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { PaymentToRequestEditService } from 'src/app/services/payment-to-request-edit.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { sprintPaySource } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-cart-buttons',
    templateUrl: './cart-buttons.component.html',
    styleUrls: ['./cart-buttons.component.scss'],
})
export class CartButtonsComponent implements OnInit, OnDestroy {
    public deletingCart = false;
    public isEditable = false;
    private unsubscribe$: Subject<void>;
    public companyName: string = '';
    public uniqueRequestor: boolean = false;
    public isSprintPaySource: boolean;
    @Input() paymentCart: PaymentCart = {} as PaymentCart;

    constructor(
        private cartBillService: CartBillService,
        private customerService: CustomerService,
        private paymentToRequestEditService: PaymentToRequestEditService,
        private paymentFluxService: PaymentFluxService,
        private router: Router,
        private ngxSpinnerService: NgxSpinnerService,
        private errorHandlerService: ErrorHandlerService,
        private initialConfigService: InitialConfigService,
        private loggerErrorService: LoggerErrorService
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.uniqueRequestor = this.customerService.isUniqueRequestor();
        this.companyName = this.customerService.getCompanyName();
        this.isSprintPaySource = sprintPaySource === (this.paymentCart.source as Source)?.name?.toLowerCase();
        this.validateButtonEdit();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    validateButtonEdit(): void {
        if (!this.paymentCart.originalInvoice) {
            const standardUser = !this.uniqueRequestor && !this.companyName;
            const uniqueRequestorCanPay =
                this.uniqueRequestor && this.paymentCart.originalRequestor?.id === this.paymentCart.customer?.id;
            this.isEditable =
                !this.paymentCart.facility?.externalLookup &&
                !this.paymentCart.facility?.awbLookupData &&
                (uniqueRequestorCanPay || standardUser) &&
                this.isSprintPaySource;
        }
    }

    editCart(): void {
        let redirect: string =
            this.companyName && this.uniqueRequestor
                ? '/admin/facilityPayments/newPayment/flux/summary'
                : '/admin/facilityPayments/newPayment/flux/paymentDetails';
        if (this.paymentCart?.facility?.notesForClients) {
            this.paymentCart.facility.notesForClients = [JSON.stringify(this.paymentCart.facility.notesForClients)];
        }
        let currentPayment: Payment = this.paymentToRequestEditService.getPaymentRequestDataCart(
            this.paymentCart,
            this.companyName ? redirect : '',
            this.companyName !== '' ? this.companyName : ''
        );

        this.companyName !== '' ? (currentPayment['step'] = 3) : '';
        currentPayment['fromCart'] = true;
        currentPayment['sprintPaySource'] = this.isSprintPaySource;
        this.paymentFluxService.setDynamicValues(currentPayment.dynamicValues);
        this.paymentFluxService.setCurrentPayment(currentPayment);
        this.router.navigate([redirect]);
    }

    deleteCart(): void {
        this.ngxSpinnerService.show();
        this.cartBillService
            .deletePaymentRequestById(this.paymentCart!.id!)
            .pipe(
                take(1),
                tap(() => {
                    if (this.paymentCart.isAboveThreshold) {
                        this.loggerErrorService.captureException(new Error('Single XL Transaction deleted from cart'), {
                            tags: { singleXLTransactionDeletedFromCart: true },
                            level: 'info',
                            extra: {
                                totalCart: this.cartBillService.getTotal(),
                                awb: this.paymentCart.awb,
                            },
                        });
                    }
                }),
                // ! Test this feature, to validate if the cart is updated afer the delete - Nancy
                switchMap(() => this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1))),
                finalize(() => this.ngxSpinnerService.hide())
            )
            .subscribe({
                error: (error) => {
                    Swal.fire({
                        html: `${this.errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        allowOutsideClick: false,
                    });
                },
            });
    }
}
