<ng-container *ngIf="profileHasSelect">
    <div
        id="switch-profile"
        class="d-flex justify-content-center align-items-center"
        *ngIf="hasMultiSelector; else userNameOnlyTemp"
    >
        <div class="switch-profile__wrapper d-flex justify-content-start align-items-center">
            <div class="switch-icon me-2 d-flex">
                <mat-icon class="material-symbols-outlined">group</mat-icon>
            </div>
            <div class="switch-profile me-2">
                <span class="d-block text-wrap" id="user-name">
                    <ng-container
                        *ngIf="selectedProfile?.activeProfileView !== profileTypesEnum.FORWARDER_ADMIN; else forwarderName"
                    >
                        <b class="highlight fw-bold"> {{selectedProfile?.name ?? ''}}</b>
                    </ng-container>
                    <ng-template #forwarderName>
                        <b class="highlight fw-bold"> {{roleName}}</b>
                    </ng-template>
                    &nbsp;-&nbsp; {{userName}}
                </span>
                <ng-container>
                    <span id="user-email">{{userEmail}}</span>
                </ng-container>
            </div>
        </div>
        <mat-divider class="ms-2 me-2" [vertical]="true"></mat-divider>

        <mat-form-field
            id="portal-selector"
            appearance="outline"
            style="font-size: smaller"
            class="select-portal my-4 my-lg-0 w-100"
        >
            <mat-label>Portal</mat-label>
            <mat-select [(value)]="selectedProfile" (valueChange)="onProfileChange($event)">
                <mat-option [value]="profile" *ngFor="let profile of profilesList">{{ profile.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-template #userNameOnlyTemp>
        <div class="single-portal">
            <div class="admin__toolbar--letter me-2">
                <span class="d-flex align-items-center justify align-middle justify-content-center text-uppercase"
                    >{{userInitials}}</span
                >
            </div>
            <div class="admin__toolbar--profile">
                <span>{{userName}}</span>
                <span class="lh-base">{{userEmail}}</span>
            </div>
        </div>
    </ng-template>
</ng-container>
