export enum SidenavOptionTypes {
    SECTION_ACTION = 'SECTION_ACTION',
    DIVIDER = 'DIVIDER',
    SECTION = 'SECTION',
    SUBSECTION = 'SUBSECTION',
    HELP = 'HELP',
    LOGOUT = 'LOGOUT',
    LOGIN = 'LOGIN',
}

export interface SidenavOption {
    type: SidenavOptionTypes;
    id: string;
    label?: string;
    icon?: string;
    route?: string;
    expandable?: boolean;
    children?: SidenavOption[];
    disabled?: boolean;
}
