import { Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CredentialsTokensType, CustomersType } from '@cargos/sprintpay-models';
import { LoggerErrorService } from '@cargos/sprintpay-services';
import {
    Observable,
    Subject,
    catchError,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    throwError,
} from 'rxjs';
import { SignUpAPIService } from '../../requests/signup-api.service';
import { ResetInformationService } from '../../reset-information.service';
import { SwalService } from '../../swal.service';
import { AuthService } from '../auth.service';
import { CartBillService } from '../cart/cart-service';
import { SessionWorkerService } from '../session-worker';
import { StorageService } from '../storage.service';
import { TokenService } from '../token.service';
import { CustomerService } from './customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class UserSessionService {
    private unsubscribe$: Subject<void>;
    private authenticatedCustomerTypes = [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.BUSINESS];
    private jwtHelperService: JwtHelperService = new JwtHelperService();

    constructor(
        private sessionWorkerService: SessionWorkerService,
        private authService: AuthService,
        private tokenService: TokenService,
        private signUpAPIService: SignUpAPIService,
        private customerService: CustomerService,
        private router: Router,
        private storageService: StorageService,
        private ngZone: NgZone,
        private injector: Injector,
        private matDialog: MatDialog,
        private loggerErrorService: LoggerErrorService,
        private cartBillService: CartBillService,
        private swalService: SwalService
    ) {
        this.unsubscribe$ = new Subject<void>();
        this.subscribeUpdateTokens();
        this.subscribeCloseSessions();
    }

    signInAsGuest(): Observable<CredentialsTokensType | null | undefined> {
        return this.sessionWorkerService.requestTokens().pipe(
            switchMap(({ tokens: workerTokens, isRequestAllowed }) => {
                if (!workerTokens && isRequestAllowed) {
                    return this.authService.signInAsGuest().pipe(
                        map((tokens: CredentialsTokensType) => {
                            this.authService.initConfigTokens(tokens);
                            this.postTokens(tokens);
                            return tokens;
                        })
                    );
                } else if (workerTokens) {
                    this.authService.initConfigTokens(workerTokens);
                }

                return of(workerTokens);
            })
        );
    }

    refreshToken(): Observable<string> {
        return this.sessionWorkerService.isTokenRefreshAllowed().pipe(
            switchMap(({ isRequestAllowed }) => {
                if (isRequestAllowed) {
                    return this.signUpAPIService.refreshToken(this.tokenService.instant_refresh_token).pipe(
                        map((tokens: CredentialsTokensType) => {
                            this.authService.initConfigTokens(tokens);
                            this.postTokens(tokens);
                            return tokens.authorizationToken;
                        }),
                        catchError(() => {
                            this.postTokens(null);
                            return throwError(() => new Error('Refresh token error'));
                        })
                    );
                }

                return of('');
            })
        );
    }

    subscribeUpdateTokens(): void {
        this.sessionWorkerService.updateTokens$
            .pipe(
                map((tokens) => {
                    if (tokens) {
                        this.authService.initConfigTokens(tokens);
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    subscribeCloseSessions(): void {
        this.sessionWorkerService.sessionsClosed$.pipe(filter((closed) => closed)).subscribe((closed) => {
            if (closed) {
                this.logoutAndResetInformation();
                this.router.navigate(['/login']);
            }
        });
    }

    hasToken$(): Observable<boolean> {
        return this.tokenService.getCurrentToken$().pipe(
            map((token) => !!token),
            distinctUntilChanged()
        );
    }

    hasTokenAndIsNotExpired$(): Observable<boolean> {
        return this.tokenService.getCurrentToken$().pipe(
            map((token) => (token !== null ? !this.jwtHelperService.isTokenExpired(token) : false)),
            distinctUntilChanged()
        );
    }

    isAuthenticated(): boolean {
        return this.authenticatedCustomerTypes.includes(this.customerService.getCustomerType());
    }

    isAuthenticated$(): Observable<boolean> {
        return this.tokenService.getCurrentToken$().pipe(
            map(() => {
                return this.authenticatedCustomerTypes.includes(this.customerService.getCustomerType());
            }),
            distinctUntilChanged()
        );
    }

    isAuthenticatedAndIsAValidToken$(): Observable<boolean> {
        return this.hasTokenAndIsNotExpired$().pipe(
            switchMap((isValidToken) => {
                if (isValidToken) {
                    return this.isAuthenticated$();
                }

                return of(false);
            }),
            distinctUntilChanged()
        );
    }

    onChangeProfile$(): Observable<string | null> {
        return this.tokenService.getCurrentToken$().pipe(
            map(() => this.customerService.getCustomerType()),
            distinctUntilChanged()
        );
    }

    /**
     * @method logout()
     * @description Ends the session of the users, deletes the storage (session and local) and takes the user to the login page
     */

    logout(): void {
        this.authService
            .reviewBeforeLogout()
            .pipe(
                take(1),
                finalize(() => {
                    this.sessionWorkerService.closeSessions();
                })
            )
            .subscribe({
                next: (): void => {
                    if (this.cartBillService.isCartAboveThreshold()) {
                        this.loggerErrorService.captureException(new Error('Abandoned Cart with XL Transactions'), {
                            tags: { abandonedCartWithXLTransactions: true },
                            level: 'info',
                            extra: {
                                totalCart: this.cartBillService.getTotal(),
                                awbs: this.cartBillService.getAWBsInCart(),
                            },
                        });
                    }
                    this.customerService.registerUser(null);
                    this.storageService.cleanStorage();
                    this.logoutAndResetInformation();
                    this.ngZone.run(() => this.router.navigate(['/login']));
                    this.swalService.clearSwal();
                },
            });
    }

    logoutAndResetInformation(): void {
        const resetInformation = this.injector.get(ResetInformationService);
        this.matDialog.closeAll();
        resetInformation.resetUserInformation();
    }

    postTokens(tokens: CredentialsTokensType | null, forceUpdate?: boolean): void {
        this.sessionWorkerService.postTokens(tokens, forceUpdate);
    }
}
