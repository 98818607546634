import { Customer } from '@cargos/sprintpay-models';
import * as moment from 'moment';
import { Facility } from 'src/app/models/payments/facility.model';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { paymentMethod, permissionsName } from '../constants';

export class Ordinary {
    static readonly customerReference = {
        title: 'Reference',
        placeholder: 'Enter your customer reference.',
        tooltip: 'Your own personal reference # so you can remember this payment. For example your file #.',
    };
    static readonly getCompletedPaymentsOrdinaryHistoryStatus: any = {
        DELIVERED_TO_FACILITY: 'Delivered to facility',
        VOIDED: 'Voided',
    };
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');

    static readonly ordinary = permissionsName.ordinary;
    static readonly paymentReferencePipe = new FormatPaymentReferencePipe();

    /*
     * OPEN INVOICES
     */

    static readonly ordinary_ColumnsOpenInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'requesterEmail',
        'facilityReference',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];
    static readonly ordinary_ColumnsNameOpenInvoices = [
        'select',
        'Invoice number',
        'Created date',
        'Customer reference',
        'Requester email',
        'Facility reference',
        'Amount',
        'Short Payment Amount',
        'Balance due',
        'Due date',
    ];
    static readonly ordinary_getCompletedPaymentsOpenInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        requesterEmail: item.paymentNotificationEmail
            ? item.paymentNotificationEmail
            : item.customer && item.customer.email
              ? item.customer.email
              : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        shortPaymentAmount: item.paymentShort ? item.paymentShort : 'N/A',
        balanceDue: item.balanceDue ? '$' + Number.parseFloat(item.balanceDue).toFixed(2) : 'N/A',
        dueDate: item.dueDate ? moment.utc(item.dueDate).format('MMM DD, YYYY') : '',
        overDue:
            moment.utc(item.dueDate).startOf('day').diff(Ordinary.currentDate, 'days') >= 0 &&
            moment.utc(item.dueDate).startOf('day').diff(Ordinary.currentDate, 'days') < 7
                ? 'almostDue'
                : moment.utc(item.dueDate).startOf('day').diff(Ordinary.currentDate, 'days') < 0
                  ? 'overdue'
                  : 'onTime',
        id: item.id,
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });
    static readonly ordinary_ColumnsSortableOpenInvoices: string[] = ['createdDate', 'amount', 'dueDate'];

    /*
     * PAID INVOICES
     */

    static readonly ordinary_ColumnsPaidInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'facilityReference',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];
    static readonly ordinary_ColumnsNamePaidInvoices = [
        'select',
        'Invoice Number',
        'Created date',
        'Customer reference',
        'Facility reference',
        'Amount',
        'Payment date',
        'Payment method',
    ];
    static readonly ordinary_getCompletedPaymentsPaidInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentDate: item.paymentDate ? moment(item.paymentDate).format('MMM DD, YYYY') : '',
        paymentMethod: item.paymentMethod ? (paymentMethod[item.paymentMethod.name] ?? item.paymentMethod.name) : 'N/A',
        confirmationId: item.confirmationId,
        id: item.id,
        hasDownloadableContent: true,
    });
    static readonly ordinary_ColumnsSortablePaidInvoices: string[] = [
        'createdDate',
        'paymentDate',
        'amount',
        'paymentMethod',
    ];

    /*
     * HISTORY
     */

    static readonly ordinary_ColumnsHistory = [
        'linkId',
        'createdDate',
        'awb',
        'customerReference',
        'amount',
        'facilityName',
        'facilityPaymentDeliveryNotificationShort',
        'status',
    ];
    static readonly ordinary_ColumnsNameHistory = [
        'Payment reference #',
        'Created date',
        'Facility reference',
        'Customer reference',
        'Amount',
        'Facility name',
        'Delivery type',
        'Status',
    ];
    static readonly ordinary_getCompletedPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        linkId: Ordinary.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        status: item.status ? Ordinary.getCompletedPaymentsOrdinaryHistoryStatus[item.status] : 'In progress',
        podName: item.podName ? item.podName : '',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
    });
    static readonly ordinary_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'customerReference',
        'amount',
        'status',
    ];

    /*
     * USERS
     */

    static readonly ordinary_ColumnsUsers = [
        'firstName',
        'lastName',
        'email',
        'homeLocation',
        'approverType',
        'approverLocations',
        'notificationEmail',
        'isMegafileEnabled',
    ];
    static readonly ordinary_ColumnsNameUsers = [
        'First name',
        'Last name',
        'Email address',
        'Home location',
        'Approver type',
        'Locations for approver visibility',
        'Email notifications',
        'Megafile',
    ];
    static readonly ordinary_getCompletedPaymentsUsers = (item: any) => ({
        firstName: item.firstName ? item.firstName : 'N/A',
        lastName: item.lastName ? item.lastName : 'N/A',
        email: item.email ? item.email : 'N/A',
        homeLocation: item.homeLocation ? item.homeLocation : 'N/A',
        approverType: item.approverType ? item.approverType : 'N/A',
        approverLocations: item.approverLocations ? item.approverLocations : 'N/A',
        notificationEmail: item.notificationEmail ? item.notificationEmail : 'N/A',
        isMegafileEnabled: item.isMegafileEnabled ? 'Yes' : 'N/A',
    });

    /*
     * GEODIS DOMAIN
     */

    /*
     * HISTORY
     */

    static readonly ordinaryGeodis_ColumnsHistory = [
        'linkId',
        'createdDate',
        'awb',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'facilityName',
        'facilityPaymentDeliveryNotificationShort',
        'status',
    ];
    static readonly ordinaryGeodis_ColumnsNameHistory = [
        'Payment Reference #',
        'Created date',
        'Facility reference',
        'GEODIS Job/Shipment',
        'GEODIS Local Client Name',
        'Amount',
        'Facility name',
        'Delivery type',
        'Status',
    ];
    static readonly ordinaryGeodis_getCompletedPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        linkId: Ordinary.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        status: item.status ? Ordinary.getCompletedPaymentsOrdinaryHistoryStatus[item.status] : 'In progress',
        podName: item.podName ? item.podName : '',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item?.externalData?.externalReferenceNum || 'N/A',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
    });
    static readonly ordinaryGeodis_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'status',
    ];

    /*
     * END GEODIS DOMAIN
     */

    /*
     * CEVA DOMAIN
     */

    /*
     * HISTORY
     */

    static readonly ordinaryCeva_ColumnsHistory = [
        'linkId',
        'createdDate',
        'awb',
        'customerReference',
        'amount',
        'facilityName',
        'status',
    ];
    static readonly ordinaryCeva_ColumnsNameHistory = [
        'Payment Reference #',
        'Created date',
        'Facility reference',
        'CEVA Dossier Number',
        'Amount',
        'Facility name',
        'Status',
    ];
    static readonly ordinaryCeva_getCompletedPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        linkId: Ordinary.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        status: item.status ? Ordinary.getCompletedPaymentsOrdinaryHistoryStatus[item.status] : 'In progress',
        podName: item.podName ? item.podName : '',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
    });

    static readonly ordinaryCeva_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'status',
    ];

    /*
     * END CEVA DOMAIN
     */

    static readonly getPaymentDetailsPaymentSummary = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'File Number'
                : facility.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : (facility.awbFieldLabel ?? 'N/A'),
            value: item.awb ? item.awb : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        {
            label: 'Amount',
            value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        {
            label: 'Payment Type',
            value: item.paymentType ? item.paymentType : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'HAWB/Container No.', value: item.hawb ? item.hawb : 'N/A', show: !facility.externalLookup },
    ];
    static readonly getCustomerReferencePaymentSummary = (item: any, customer: Customer | null) => [
        {
            label: customer?.expeditors ? 'File #' : Ordinary.customerReference.title,
            value: item?.customerRef ? item.customerRef : 'N/A',
            show: true,
        },
        {
            label: 'GL Code',
            value: item?.glCode ? item.glCode : 'N/A',
            show: customer?.hasGlCodes,
        },
        {
            label: 'GEODIS Local Client Name',
            value: item?.externalReferenceNum ? item.externalReferenceNum : 'N/A',
            show: !!item?.externalReferenceNum,
        },
        { label: 'Payment Notification Email', value: item?.email ? item.email : 'N/A', show: !customer?.isGuest },
        { label: 'Notes', value: item?.notes ? item.notes : 'N/A', show: true },
    ];

    static readonly getPaymentDetailsPaymentHistory = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'File Number'
                : facility.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : (facility.awbFieldLabel ?? 'N/A'),
            value: item.hawb ? item.hawb : 'N/A',
            show: !facility.externalLookup && !id,
        },
        { label: 'Payment Type', value: item.paymentType ? item.paymentType : 'N/A', show: true },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        { label: 'Cargo facility', value: facility.name ? facility.name : 'N/A', show: true },
        { label: 'Amount', value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A', show: true },
    ];
    static readonly getCustomerReferencePaymentHistory = (item: any, customer: Customer | null) => [
        {
            label: customer?.expeditors ? 'File #' : Ordinary.customerReference.title,
            value: item.customerReference ? item.customerReference : 'N/A',
            show: true,
        },
        {
            label: 'GEODIS Local Client Name',
            value: item.externalReferenceNum ? item.externalReferenceNum : 'N/A',
            show: !!item.externalReferenceNum,
        },
        {
            label: 'Payment Notification Email',
            value: item.notificationEmail ? item.notificationEmail : 'N/A',
            show: true,
        },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
}
