import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '@cargos/sprintpay-models';
import { take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { CartHandlerService } from 'src/app/services';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CartService } from 'src/app/services/utils/cart/cart-v1.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { companyName, companyNameForCoda } from 'src/app/utils/constants';
import { Sections } from 'src/app/utils/sections';

@Component({
    selector: 'app-payment-summary',
    templateUrl: './payment-summary.component.html',
    styles: [],
})
export class PaymentSummaryComponent implements OnInit {
    startingLevel: boolean = false;
    currentPayment: any;
    dynamicFieldsColumns: any;
    companyName: string;
    companyNames: any;
    payment: Payment;
    submitToApprover: boolean;
    dynamicFieldsReplaced: any;
    files: any;
    nameFiles: string[] = [];
    hasCurrentCharges: boolean = false;
    paymentDetailsSection: any;
    paymentCustomerReference: any;
    private customer: Customer | null;
    private chargesAddedToCart: boolean = false;
    private chargesSubmitted: boolean = false;

    constructor(
        private _cartService: CartService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService,
        private _router: Router,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService,
        private cartHandlerService: CartHandlerService
    ) {
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.startingLevel = !!this._sessionService.getElement('startingLevel');
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.customer = this._customerService.getCustomer();
        this.companyNames = companyName;
        this.companyName = this._customerService.getCompanyName();
        this.getAllFieldsWithReplacement();
        this.submitToApprover = false;
        this.payment = this.currentPayment;
        this.currentPayment['location'] =
            this.companyName && Object.keys(companyNameForCoda).indexOf(this.companyName.toLowerCase()) !== -1
                ? this.currentPayment[`${encodeURIComponent(this.companyName.toLowerCase())}CustomerReference`]
                      ?.locationName
                : this.customer?.approvalLevels
                  ? this.customer?.approvalLevels?.company?.firstLocation?.name
                  : 'N/A';
        this.getPaymentDetailsSection();
    }

    ngOnInit(): void {
        if (this.currentPayment && this.currentPayment.details) {
            const details = this.currentPayment.details;
            this.files = details?.nameFiles ? details.nameFiles.split(',') : '';
            details?.nameFiles
                ? details.nameFiles.split(',').map((item: any): void => {
                      this.nameFiles.push(item.split('/')[5]);
                  })
                : '';
        }
        this.hasCharges();
    }

    hasCharges(): void {
        this.chargesAddedToCart = this.cartHandlerService.has3rdPartyItems();
        this.chargesSubmitted = this.cartHandlerService.hasSubmittedItems();
    }

    currentCharges(charges: any): void {
        this.hasCurrentCharges = !!charges.length;
    }

    /**
     * @method _checkout()
     * @param (type?: any)
     * @description
     */

    checkout(): void {
        this._clearInformation();
        this._router.navigate([
            this.chargesAddedToCart ? 'admin/cart' : 'admin/facilityPayments/pendingApproval/paymentList',
        ]);
    }

    /**
     * @method backStep()
     * @description Return into the customer reference for companies
     */

    backStep(): void {
        this._router.navigate(['/admin/facilityPayments/newPayment/codafacility/paymentDetails']);
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        this.dynamicFieldsReplaced = [];
        this.dynamicFieldsService
            .getFieldsReplaced()
            .pipe(take(1))
            .subscribe({
                next: (fieldsReplaced) => {
                    this.dynamicFieldsReplaced = fieldsReplaced;
                    this.getPaymentCustomerReferenceSection();
                },
            });
    }

    /**
     * @method _clearInformation()
     * @description
     */

    private _clearInformation(): void {
        this._paymentFluxService.removeCurrentPayment();
        this._paymentFluxService.removeDynamicFields();
        this._paymentFluxService.removeDynamicValues();
        this.cartHandlerService.reset3rdPartyItems();
        this.cartHandlerService.resetSubmittedItems();
        this._sessionService.removeElement('HBL');
    }

    getPaymentDetailsSection(): void {
        this.paymentDetailsSection = Sections.getDetailsOfPaymentDetailsSummarySection(this.payment, this.companyName);
    }

    getPaymentCustomerReferenceSection(): void {
        const customerReferenceLabel = this.dynamicFieldsReplaced?.find((item) => item.name === 'customerRef');
        this.paymentCustomerReference = Sections.getDetailsOfCustomerReferenceSummarySection(
            this.payment.reference,
            this.customer,
            customerReferenceLabel?.label || '',
            this.companyName
        );
    }
}
