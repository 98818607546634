import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileException } from '@cargos/sprintpay-models';
import { FileExceptionType, WhiteListFiles } from '@cargos/sprintpay-models/dist/src/file/file-exceptions-const';
import { FileService } from '@cargos/sprintpay-services';
import { ngfModule } from 'angular-file';
import { Observable, Subject, finalize, forkJoin, map, of, switchMap, take, takeUntil, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { FileRemoverComponent } from '../file-remover/file-remover.component';
import { FileHandlerService } from '../services/file.service';

@Component({
    standalone: true,
    imports: [ngfModule, NgIf, MatProgressSpinnerModule, MatTooltipModule, FileRemoverComponent],
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnDestroy {
    public files: File[] = [];
    public filesUploaded: string[] = [];
    public validating = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() title = 'Upload files';
    @Input() subtitle =
        'Choose a file or drag it here. You must upload 3 files .pdf or images (jpeg, jpg, png) of max 1 MB each.';
    @Input() errorMessage: string = '';
    @Input() multipleFiles: '1' | '0' = '1';
    @Input() selectable: '1' | '0' = '1';
    @Input() maxFilesAllowed = 3;
    @Input() maxSize = 1024;
    @Input() whiteListFile = WhiteListFiles;
    @Input() loading = false;
    @Input() tooltipMessage: string = '';
    @Input() isDisabled: boolean = false;

    @Output() filesAdded = new EventEmitter<FormData[]>();

    constructor(
        private fileService: FileService,
        private fileHandlerService: FileHandlerService
    ) {
        this.errorMessage = `You must upload ${this.maxFilesAllowed} file(s) of max ${this.maxSize / 1024} MB each.`;

        this.subscribeFilesUploaded();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    filesChange(): void {
        if (this.validating) {
            return;
        }

        this.validating = true;
        const files = this.files.splice(0, this.files.length);

        this.validateFiles(files)
            .pipe(
                take(1),
                map(() => this.getFormDataFiles(files)),
                finalize(() => {
                    this.validating = false;
                })
            )
            .subscribe({
                next: (formDataFiles: FormData[]) => {
                    this.filesAdded.emit(formDataFiles);
                },
                error: (error: FileException) => {
                    this.validating = false;

                    Swal.fire({
                        title: error.title,
                        text: error.description,
                        icon: 'warning',
                    });
                },
            });
    }

    getFileNameFormData(file: FormData): string {
        return file.get('file')?.['name'] || '';
    }

    validateFiles(files: File[]): Observable<boolean> {
        return this.validateMaxFilesLength(files).pipe(
            switchMap(() => {
                return forkJoin([
                    ...files.map((file) =>
                        this.fileService.isValidFile(file, this.maxSize, this.whiteListFile).pipe(take(1))
                    ),
                ]).pipe(map((filesValidations) => filesValidations.every((validation) => validation)));
            })
        );
    }

    validateMaxFilesLength(files: File[]): Observable<boolean> {
        return of(this.filesUploaded.length + files.length > this.maxFilesAllowed).pipe(
            switchMap((isInvalid) => {
                if (!isInvalid) {
                    return of(true);
                }

                return throwError(() =>
                    FileException.fromJson({
                        code: FileExceptionType.LIMIT_INVALID,
                        description: this.errorMessage,
                    })
                );
            })
        );
    }

    getFormDataFiles(files: File[]): FormData[] {
        return (
            files.map((file) => {
                const formData: FormData = new FormData();
                formData.append('file', file);

                return formData;
            }) || []
        );
    }

    subscribeFilesUploaded(): void {
        this.fileHandlerService
            .getPathFiles()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (filesUploaded) => {
                    this.filesUploaded = filesUploaded;
                },
            });
    }
}
