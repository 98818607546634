import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { InformationCollectorByPaymentComponent } from '../information-collector-by-payment/collector/information-collector-by-payment.component';
import { StandardReferenceComponent } from './standard-reference.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        DisableControlDirective,
        InformationCollectorByPaymentComponent,
    ],
    declarations: [StandardReferenceComponent],
    exports: [StandardReferenceComponent],
})
export class StandardReferenceModule {}
