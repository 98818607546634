import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { DynamicField, FileException } from '@cargos/sprintpay-models';
import { Validation } from '@cargos/sprintpay-models/dist/src/dynamic-values/dynamic-fields';
import { Observable, Subject, forkJoin, take, takeUntil } from 'rxjs';
import { FileRequestService } from 'src/app/services/file-handler.service';
import { FileUploadComponent } from 'src/app/standalone-components/file-handler-dynamic/file-uploader/file-upload.component';
import { FileHandlerService } from 'src/app/standalone-components/file-handler-dynamic/services/file.service';
import Swal from 'sweetalert2';
import { DynamicErrorComponent } from '../dynamic-error/dynamic-error.component';

@Component({
    selector: 'app-dynamic-file',
    standalone: true,
    providers: [FileHandlerService],
    imports: [FileUploadComponent, DynamicErrorComponent],
    templateUrl: './dynamic-file.component.html',
    styleUrl: './dynamic-file.component.scss',
})
export class DynamicFileComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public formName: FormGroup;
    public fieldValidations: Validation[] = [];
    public filesAccepted: string[] = [];
    public fieldData: DynamicField = {};
    public uploading: boolean = false;
    public filesUploaded: string[] = [];

    @Input()
    set field(field: DynamicField) {
        this.fieldData = field;
        this.fieldValidations = field.validations || [];
        this.filesAccepted = this.getFileValidationByName('accept');
    }

    get fileInput(): AbstractControl<string> | null {
        return this.formName.get(this.fieldData?.name || '');
    }

    constructor(
        private formGroupDirective: FormGroupDirective,
        private fileHandlerService: FileHandlerService,
        private fileRequestService: FileRequestService
    ) {
        this.formName = formGroupDirective.control;
    }

    ngOnInit(): void {
        this.subscribeFilesUploaded();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getFileValidationByName(validationName: string): string[] {
        const valueOfValidation = this.fieldValidations.find((validation) => validation.name === validationName)
            ?.value as string;
        return valueOfValidation?.split(',');
    }

    filesAdded(files: FormData[]): void {
        if (this.uploading) {
            return;
        }

        this.uploading = true;

        this.uploadFiles(files)
            .pipe(take(1))
            .subscribe({
                next: (locationPaths: string[]) => {
                    this.uploading = false;
                    const newFileNames: string[] = [...locationPaths, ...this.filesUploaded];

                    this.fileHandlerService.setPathFiles(newFileNames);
                },
                error: (error: FileException) => {
                    this.uploading = false;

                    Swal.fire({
                        title: error.title,
                        text: error.description,
                        icon: 'warning',
                    });
                },
            });
    }

    uploadFiles(files: FormData[]): Observable<string[]> {
        return forkJoin(files.map((file) => this.fileRequestService.uploadFile(file).pipe(take(1))));
    }

    subscribeFilesUploaded(): void {
        this.fileHandlerService
            .getPathFiles()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (filesUploaded) => {
                    this.filesUploaded = filesUploaded;
                    this.fileInput?.patchValue(filesUploaded?.toString());
                },
            });
    }
}
