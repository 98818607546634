<div class="row">
    <div class="col-12">
        <ng-container *ngIf="!emailHasDomain; else emailHasDomainTemplate">
            <ng-container>
                <ng-container>
                    <div class="mb-4">
                        <h3 class="fs-6 lh-1 text-muted">New account type</h3>
                        <mat-radio-group [formControl]="accountAccountType">
                            <mat-radio-button
                                class="me-2 mb-2"
                                color="primary"
                                *ngFor="let accountType of accountTypes"
                                [value]="accountType.value"
                                (change)="setAccountType($event)"
                            >
                                {{accountType.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="selectedAccountType">
                        <h3 class="fs-6 lh-1 text-muted">Company information</h3>
                        @if (companyNameForm) {
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Company name</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    data-cy="companyName"
                                    [formControl]="companyNameForm"
                                    placeholder="Enter the company name"
                                    [errorStateMatcher]="matcher"
                                />
                                <mat-error *ngIf="companyNameForm?.hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                                <mat-error *ngIf="companyNameForm?.hasError('duplicatedCompany')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Update your Company name and
                                    resubmit
                                </mat-error>
                            </mat-form-field>
                        </div>

                        }
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Tax ID (optional)</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    id="taxID"
                                    name="taxID"
                                    data-cy="taxID"
                                    [formControl]="companyTaxIdForm"
                                    placeholder="Tax ID (optional)"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <!--START: Address -->
                <section>
                    <span class="text-muted mb-4 d-block fw-bolder">Address information</span>
                    <lib-address-information #addressFormTemplate class="mt-4 d-block" [addressForm]="addressForm">
                    </lib-address-information>
                    <!-- TODO Incorporate unconfirmedAddressFound validation and <lib-address-validation-prompt> to lib-address-information component -->
                    @if (suggestedAddress || unconfirmedAddressFound) {
                    <lib-address-validation-prompt
                        [inputAddress]="inputAddress"
                        [recommendedAddress]="suggestedAddress"
                        (addressSuggestionResponse)="handleSuggestedAddress($event)"
                    />
                    }
                </section>
                <!--END: Address -->
                <!--START: checkBox-->
                <div class="align-items-baseline mb-4">
                    <mat-checkbox [formControl]="billingAddress" class="me-3" [color]="'primary'">
                        Use as billing address
                    </mat-checkbox>
                </div>
                <!--END: checkBox-->
                <!--START: Billing address -->
                <section *ngIf="!billingAddress.value">
                    <span class="text-muted mb-4 d-block fw-bolder">Billing address</span>
                    <lib-address-information
                        #billingAddressFormTemplate
                        class="mt-4 d-block"
                        [addressForm]="billingAddressForm"
                    >
                    </lib-address-information>
                    <!-- TODO Incorporate unconfirmedAddressFound validation and <lib-address-validation-prompt> to lib-address-information component -->
                    @if (suggestedBillingAddress || unconfirmedBillingAddressFound) {
                    <lib-address-validation-prompt
                        [inputAddress]="inputBillingAddress"
                        [recommendedAddress]="suggestedBillingAddress"
                        (addressSuggestionResponse)="handleSuggestedBillingAddress($event)"
                    />
                    }
                </section>
                <!--END: Billing address -->
            </ng-container>
        </ng-container>
        <ng-template #emailHasDomainTemplate>
            <section>
                <span class="text-muted mb-4 d-block fw-bolder">Company information</span>
                <!--START: Company name -->
                <div class="form-group">
                    <mat-form-field class="d-block" appearance="outline">
                        <mat-label>Company name</mat-label>
                        <input
                            matInput
                            type="text"
                            id="companyName"
                            name="companyName"
                            data-cy="companyName"
                            [formControl]="companyForm.controls['companyName']"
                            placeholder="Enter the company name"
                            [errorStateMatcher]="matcher"
                        />
                        <mat-error *ngIf="companyNameForm?.hasError('restrictedBranch')">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>
                            Your Company requires additional authorization. Please reach out to your Company CargoSprint
                            representative or email us at
                            <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
                        </mat-error>
                        <mat-error *ngIf="companyNameForm?.hasError('required')">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--END: Company name -->
                <ng-container *ngIf="branchLocations.length">
                    <div class="form-group">
                        <mat-form-field class="d-block mb-2" appearance="outline">
                            <mat-label>Port Location</mat-label>
                            <mat-select
                                [formControl]="companyForm.controls['branchLocation']"
                                id="brachLocation"
                                name="brachLocation"
                                [errorStateMatcher]="matcher"
                            >
                                <mat-option *ngFor="let item of branchLocations" [value]="item.id">
                                    {{item.accountName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="branchLocationForm?.hasError('restrictedBranch')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Your Company requires additional
                                authorization. Please reach out to your Company CargoSprint representative or email us
                                at <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
                            </mat-error>
                            <mat-error *ngIf="branchLocationForm?.hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Branch location is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
            </section>
        </ng-template>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <button
                    mat-button
                    matStepperPrevious
                    type="button"
                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green mb-3 mb-md-0"
                >
                    Previous
                </button>
            </div>
            <div class="col-sm-12 col-md-6">
                <button
                    mat-flat-button
                    color="primary"
                    type="submit"
                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                    data-cy="next_stepTwo"
                    (click)="onSubmit()"
                    [disabled]="accountForm.invalid"
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</div>
