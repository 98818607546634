import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { UserProperties } from './user';

export class DSVUser implements UserProperties {
    constructor(
        public dynamicSections: DynamicSections[] = [],
        public dynamicFields: DynamicField[] = [
            {
                name: 'customerRef',
                label: 'Reference',
                section: 1,
                fieldType: 'input',
                company: undefined,
                emailDomain: 'dsv.com',
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage:
                    'Your own personal reference # so you can remember this payment. For example your file #.',
                validations: [
                    {
                        id: 0,
                        name: 'pattern',
                        value: [
                            /^(C|c)([a-zA-Z0-9]{8}|[a-zA-Z0-9]{9})$/,
                            /^(S|s)([a-zA-Z0-9]{10}|[a-zA-Z0-9]{11})$/,
                            /^(B|b)([a-zA-Z0-9]{10}|[a-zA-Z0-9]{11})$/,
                        ],
                        message:
                            'Must start with a C followed by 8-9 alphanumeric characters, or start with an S, followed by 10-11 alphanumeric characters, or must start with a B followed by 10-11 alphanumeric characters.',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 1,
                        name: 'maxlength',
                        value: '255',
                        message: 'Maximum length exceeded (255 characters maximum)',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your customer reference.',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
        ]
    ) {
        this.dynamicSections = [
            {
                id: 1,
                label: 'Payment Details',
                orderNumber: 0,
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Customer Reference',
                orderNumber: 1,
                emailDomain: null,
            },
        ];
    }
}
