import { CredentialsTokensType } from '@cargos/sprintpay-models';

export enum SessionWorkerMessageTypes {
    REQUEST_TOKENS = 'REQUEST_TOKENS',
    UPDATE_TOKENS = 'UPDATE_TOKENS',
    IS_TOKEN_REFRESH_ALLOWED = 'IS_TOKEN_REFRESH_ALLOWED',
    CLOSE_CONNECTION = 'CLOSE_CONNECTION',
    CLOSE_SESSIONS = 'CLOSE_SESSIONS',
}

export interface SessionWorkerMessage {
    type: SessionWorkerMessageTypes;
    tokens?: CredentialsTokensType | null;
    isRequestAllowed?: boolean;
    isInitialized?: boolean;
    forceUpdate?: boolean;
}

let tokens: CredentialsTokensType | null = null;
const connections: MessagePort[] = [];
let blockPort: MessagePort | null = null;

// @ts-ignore
self.onconnect = (event: MessageEvent) => {
    const [port] = event.ports;
    connections.push(port);

    port.onmessage = (message: MessageEvent<SessionWorkerMessage>) => {
        const { data, ports } = message;

        switch (data.type) {
            case SessionWorkerMessageTypes.REQUEST_TOKENS:
                if (!tokens && !blockPort) {
                    blockPort = port;
                    ports[0].postMessage({ tokens, isRequestAllowed: true });
                } else if ((!tokens && blockPort) || (tokens && !blockPort && !data.isInitialized)) {
                    ports[0].postMessage({ tokens, isRequestAllowed: false });
                } else if (tokens && !blockPort && data.isInitialized) {
                    blockPort = port;
                    ports[0].postMessage({
                        tokens: null,
                        isRequestAllowed: true,
                    });
                } else if (tokens && blockPort && blockPort === port) {
                    ports[0].postMessage({
                        tokens: null,
                        isRequestAllowed: true,
                    });
                }
                break;
            case SessionWorkerMessageTypes.IS_TOKEN_REFRESH_ALLOWED:
                if (!blockPort) {
                    blockPort = port;
                    ports[0].postMessage({ isRequestAllowed: true });
                } else {
                    ports[0].postMessage({ isRequestAllowed: false });
                }
                break;
            case SessionWorkerMessageTypes.UPDATE_TOKENS:
                if (blockPort === port) {
                    blockPort = null;
                }
                tokens = data.tokens || null;
                if (tokens) {
                    connections.forEach((connection) => {
                        if (connection !== port || data.forceUpdate) {
                            connection.postMessage({
                                type: SessionWorkerMessageTypes.UPDATE_TOKENS,
                                tokens,
                            });
                        }
                    });
                }
                break;
            case SessionWorkerMessageTypes.CLOSE_CONNECTION:
                connections.splice(connections.indexOf(port), 1);
                if (blockPort === port) {
                    blockPort = null;
                }
                break;
            case SessionWorkerMessageTypes.CLOSE_SESSIONS:
                tokens = null;
                connections.forEach((connection) => {
                    if (connection !== port) {
                        connection.postMessage({
                            type: SessionWorkerMessageTypes.CLOSE_SESSIONS,
                            tokens,
                        });
                    }
                });
                break;
        }
    };
};
