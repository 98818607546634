import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsTokensType } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/models/auth/signup.model';
import { CompanyDetails } from 'src/app/models/utils/company';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';
export interface ResponseEmail {
    companyDetails?: CompanyDetails;
    activationUuid?: string;
}

@Injectable({ providedIn: 'root' })
export class SignUpAPIService {
    private authentication: string;
    private customersUrl: string;

    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService
    ) {
        this.customersUrl = environment.uris.method.customer;
        this.authentication = environment.uris.method.authentication;
    }

    signUp(data: UserData): Observable<string> {
        const url = `${this.authentication}/signup/customer?source=sprintpay`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, data, { headers, responseType: 'text' });
    }

    validateEmail(email: string): Observable<ResponseEmail> {
        const url = `${this.authentication}/register/validate`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, { email }, { headers });
    }

    sendEmail(source: string, activationKey: string): Observable<any> {
        const url = `${this.authentication}/activationEmail/resend`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, { source, activationKey }, { headers });
    }

    refreshToken(refreshToken: string | null): Observable<CredentialsTokensType> {
        const url = `${this.authentication}/refreshToken?token=${refreshToken}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post<CredentialsTokensType>(url, {}, { headers });
    }

    upgradeGuest(data: UserData): Observable<string> {
        const url = `${this.customersUrl}/guest/update`;
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

        const token = this.tokenService.getCurrentUser();

        if (!token) {
            throw new Error('Token is missing');
        }

        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.httpClient.post(url, data, { headers, responseType: 'text' });
    }
}
