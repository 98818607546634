import { Injectable } from '@angular/core';
import { DynamicField, DynamicSections, OptionsInput } from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { HandlerdFeaturesService } from './customer.service';
import { DynamicFieldAPIService } from './requests/dynamic-field-api.service';
export interface DynamicFieldsForPayment extends DynamicSections {
    fields?: DynamicField[];
}

export interface ColumnsFormat {
    column?: string;
    columnName?: string;
}

export interface ColumnsFieldsReplaced {
    name: any;
    label: any;
}

@Injectable({ providedIn: 'root' })
export class DynamicFieldsService {
    private loading = new BehaviorSubject<boolean>(false);
    private fieldsForPayment = new BehaviorSubject<DynamicFieldsForPayment[]>([]);
    private dynamicFieldsForPayment = new BehaviorSubject<DynamicField[]>([]);

    constructor(
        private dynamicFieldAPIService: DynamicFieldAPIService,
        private handlerdFeaturesService: HandlerdFeaturesService
    ) {}

    getDynamicColumns(): Observable<ColumnsFormat[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                if (fieldsForPayment.length) {
                    return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                        return (
                            fieldForPayment.fields
                                ?.filter((item): boolean => item.isHidden === false)
                                .map((field): ColumnsFormat => {
                                    return { columnName: field.label, column: field.name };
                                }) || []
                        );
                    });
                }
                return [];
            })
        );
    }

    getDynamicFields$(): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                    return fieldForPayment.fields || [];
                });
            })
        );
    }

    getDynamicFieldsCurrentValue(): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                    return fieldForPayment.fields || [];
                });
            })
        );
    }
    getFieldsReplaced(): Observable<ColumnsFieldsReplaced[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldsForPayment: DynamicFieldsForPayment) => {
                    return (
                        fieldsForPayment.fields
                            ?.filter((item): boolean => !!item.isReplacement)
                            .map((field): ColumnsFieldsReplaced => {
                                return { name: field.name, label: field.label };
                            }) || []
                    );
                });
            })
        );
    }

    getFormFields(section: number): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((dynamicFieldsForPayment) => {
                if (section >= 0 && section < dynamicFieldsForPayment.length) {
                    return dynamicFieldsForPayment[section].fields || [];
                }
                return [];
            })
        );
    }

    getHeaderdinamicAndReplaceable(keyHeaders: string[], staticHeaders: string[], dinamicFields: any): string[] {
        dinamicFields.map((field: { name: string; label: string }) => {
            const index = keyHeaders.findIndex((value) => value === field.name);
            if (index > 0) {
                staticHeaders[index] = field.label;
            }
        });
        return staticHeaders;
    }

    /**
     * @method getLabelsdinamicAndReplaceable()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getLabelsdinamicAndReplaceable(staticNames: string[], dinamicFields: any) {
        let dynamicLabels: any = {};
        Object.values(dinamicFields).map((item: any) => {
            if (staticNames.indexOf(item.name) !== -1) {
                dynamicLabels[`${item.name}`] = item.label;
            }
        });
        return dynamicLabels;
    }

    /**
     * @method getFileFieldsDynamic()
     * @param fields
     * @description Review if dynamic fields has file input
     */
    getFileFieldsDynamic(): {
        id: number;
        name: string;
    }[] {
        return this.fieldsForPayment.value.flatMap((fieldsForPayment) => {
            return (
                fieldsForPayment?.fields
                    ?.filter(
                        (field: DynamicField) => field.fieldType === 'file' && !field.isReplacement && !field.isHidden
                    )
                    .map((field: DynamicField) => ({
                        id: field.id!, // Asegúrate de que `id` y `name` siempre estén definidos, de lo contrario usa una verificación más adecuada
                        name: field.name!,
                    })) || []
            );
        });
    }

    /**
     * @method verifyIfDynamicFieldisFile()
     * @param id
     * @param dynamicFileFields
     * @description Review if dynamic fields is a dynamic file field
     */
    verifyIfDynamicFieldisFile(
        id: number,
        dynamicFileFields: { id: number; name: string }[]
    ): { id: number; name: string }[] {
        return dynamicFileFields.filter((field: { id: number; name: string }) => id === field.id);
    }

    getDynamicFieldsByCompany(section?: number): Observable<DynamicField[]> {
        return this.dynamicFieldAPIService.getDynamicFieldsBySection(section).pipe(
            catchError(() => {
                return of([]);
            })
        );
    }

    getDynamicSectionsByCompany(companyId?: number): Observable<DynamicSections[]> {
        return this.dynamicFieldAPIService.getDynamicSectionsByCompany(companyId).pipe(
            catchError(() => {
                return of([]);
            })
        );
    }

    get instant_current_dynamic_fields_for_payment(): DynamicFieldsForPayment[] {
        return this.fieldsForPayment.value;
    }

    setDynamicFieldsForPayment(dynamicFieldsForPayment: DynamicFieldsForPayment[]): void {
        this.fieldsForPayment.next(dynamicFieldsForPayment);
    }

    getDynamicFieldsForPayment$(): Observable<DynamicFieldsForPayment[]> {
        return this.fieldsForPayment.asObservable();
    }

    setLoadingDynamicFields(loading: boolean): void {
        this.loading.next(loading);
    }

    getLoadingDynamicFields(): Observable<boolean> {
        return this.loading.asObservable();
    }

    getDynamicSections(companyId?: number | null): Observable<DynamicSections[]> {
        if (!!companyId) {
            return this.getDynamicSectionsByCompany(companyId).pipe(
                switchMap((sections) => {
                    if (sections.length) {
                        return of(sections);
                    }
                    return this.getStaticSections();
                })
            );
        }
        return this.getStaticSections();
    }

    getStaticSections(): Observable<DynamicSections[]> {
        return this.handlerdFeaturesService.getCustomerSections();
    }

    getStaticFields(): Observable<DynamicField[]> {
        return this.handlerdFeaturesService.getCustomerFields();
    }

    filterDuplicatedFields(staticFields: DynamicField[] = [], dynamicFields: DynamicField[] = []): DynamicField[] {
        const newDynamicFields: DynamicField[] = [...dynamicFields];

        staticFields?.forEach((staticField) => {
            if (!dynamicFields?.find((field) => field.name === staticField.name && field.isReplacement)) {
                newDynamicFields.push(staticField);
            }
        });
        return newDynamicFields;
    }

    removeNotAllowedFields(staticFields: DynamicField[] = []): DynamicField[] {
        if (!this.handlerdFeaturesService.hasGlCodes()) {
            staticFields = staticFields.filter((staticField) => staticField.name !== 'glCode');
        }

        // if (!!this.customerFeaturesService.getFirstLocationId()) {
        //     staticFields = staticFields.filter(
        //         (staticField) => staticField.name !== 'locationId' && staticField.name !== 'locationddId'
        //     );
        // }

        return staticFields;
    }

    getDynamicFields(): Observable<DynamicField[]> {
        return this.getDynamicFieldsByCompany().pipe(
            map((fields) => {
                return this.setDisplayValue(fields);
            }),
            switchMap((fields) => {
                if (fields.length) {
                    this.dynamicFieldsForPayment.next(fields);
                    return of(fields);
                }
                this.dynamicFieldsForPayment.next(fields);
                return this.getStaticFields().pipe(
                    map((staticFields) => this.filterDuplicatedFields(staticFields, fields)),
                    map((staticFields) => this.removeNotAllowedFields(staticFields))
                );
            })
        );
    }

    setDisplayValue(fields: DynamicField[]): DynamicField[] {
        return fields.map((field) => {
            if (field.fieldType === 'dropdown' || field.fieldType === 'radioButton' || field.fieldType === 'checkbox') {
                const updatedOptions = field.options?.map((option) => {
                    if (option.displayValue === undefined || option.displayValue === null) {
                        return { ...option, displayValue: option.value };
                    }
                    return option;
                });
                return { ...field, options: updatedOptions };
            }
            return field;
        });
    }

    getSectionByNumber(sections: DynamicFieldsForPayment[], sectionNumber?: number): DynamicFieldsForPayment | null {
        return (
            sections.find((section) => {
                return section.orderNumber === sectionNumber;
            }) || null
        );
    }

    addDynamicFieldsToSection(
        dynamicSections: DynamicSections[] = [],
        dynamicFields: DynamicField[] = []
    ): DynamicFieldsForPayment[] {
        const dynamicFieldsForPayment: DynamicFieldsForPayment[] = [];

        if (dynamicSections.length) {
            dynamicSections.forEach((section, index) => {
                dynamicFieldsForPayment[index] = section;

                if (section.id) {
                    const fieldsPerSection = dynamicFields.filter((field) => field.section === section.orderNumber);

                    dynamicFieldsForPayment[index].fields = fieldsPerSection;
                }
            });
        } else if (dynamicFields.length) {
            dynamicFields.forEach((field) => {
                if (!this.getSectionByNumber(dynamicFieldsForPayment, field.section)) {
                    dynamicFieldsForPayment.push({
                        orderNumber: field.section,
                    });
                }

                const section = this.getSectionByNumber(dynamicFieldsForPayment, field.section);

                if (section) {
                    const fieldsPerSection = dynamicFields.filter((field) => field.section === section.orderNumber);
                    section.fields = fieldsPerSection;
                }
            });
        }

        return dynamicFieldsForPayment;
    }

    removeEmptySections(dynamicFields: DynamicFieldsForPayment[]) {
        return dynamicFields.filter((section) => !!section.fields?.length);
    }

    orderSectionByOrderNumber(sections: DynamicFieldsForPayment[] = []): DynamicFieldsForPayment[] {
        try {
            return sections.sort((a, b) => {
                return (a?.['orderNumber'] || 0) - (b?.['orderNumber'] || 0);
            });
        } catch (error) {
            return sections;
        }
    }

    getDynamicFieldsPerSection(companyId: number | null): Observable<DynamicFieldsForPayment[]> {
        this.setLoadingDynamicFields(true);
        return this.getDynamicSections(companyId).pipe(
            switchMap((dynamicSections: DynamicSections[]) => {
                return this.getDynamicFields().pipe(
                    map((dynamicFields) => {
                        //todo remover codigo temporal
                        return this.addDynamicFieldsToSection(dynamicSections, dynamicFields);
                    })
                );
            }),
            map((dynamicFields) => {
                const dynamicFieldsOrdered = this.orderSectionByOrderNumber(dynamicFields);
                this.setDynamicFieldsForPayment(dynamicFieldsOrdered);

                this.setLoadingDynamicFields(false);
                return dynamicFieldsOrdered;
            }),
            catchError((error) => {
                this.setLoadingDynamicFields(false);

                this.setDynamicFieldsForPayment([]);
                return throwError(() => error);
            })
        );
    }

    getFieldByDependencyFieldId(dependentFieldId: number): OptionsInput[] {
        const dynamicFields = this.instant_current_dynamic_fields_for_payment || [];
        return dynamicFields.flatMap((dynamicField) => {
            return dynamicField.fields?.find((field) => field.dependentFieldId === dependentFieldId)?.options || [];
        });
    }
}
