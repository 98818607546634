import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Validation } from '@cargos/sprintpay-models';
import { CustomPatterns, CustomValidators } from '@cargos/sprintpay-utils';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';

@Injectable({
    providedIn: 'root',
})
export class InformationCollectorByPaymentService {
    public formGroups = new BehaviorSubject<FormGroup[]>([]);
    private collectorForms = new BehaviorSubject<(FormGroup | null)[]>([]);

    constructor(private paymentFluxService: PaymentFluxService) {}

    setFormGroups(formGroups: FormGroup[]): void {
        this.formGroups.next(formGroups);
    }

    getFormGroups(): Observable<FormGroup[]> {
        return this.formGroups.asObservable();
    }

    validateForms(): Observable<boolean> {
        return this.getFormGroups().pipe(
            map((formGroups) => {
                if (!formGroups?.length) {
                    return false;
                }
                return formGroups.every((formGroup) => {
                    return !!formGroup?.valid;
                });
            })
        );
    }

    get instant_formGroupsValidateForms(): boolean {
        return this.formGroups.value.every((form) => form.valid);
    }

    setCollectorForms(collectorForms: FormGroup | null, index: number): void {
        const newCollectorForms: (FormGroup | null)[] = this.collectorForms.value || [];
        newCollectorForms[index] = collectorForms;

        this.collectorForms.next(newCollectorForms);
    }

    getCollectorForms$(): Observable<(FormGroup | null)[]> {
        return this.collectorForms.asObservable();
    }

    get instant_collector_forms(): (FormGroup | null)[] {
        return this.collectorForms.value;
    }

    markFormAsTouched(): Observable<string> {
        return this.getFormGroups().pipe(
            map((forms) => {
                forms?.forEach((form) => {
                    form.markAllAsTouched();
                });
                return 'Complete';
            })
        );
    }

    getValidators(validations: Validation[]): ValidatorFn[] | null {
        if (!validations.length) {
            return null;
        }

        const validators: ValidatorFn[] = [];

        validations?.forEach(({ value, name }) => {
            switch (name) {
                case 'min':
                    validators.push(Validators.min(Number(value)));
                    break;
                case 'max':
                    validators.push(Validators.max(Number(value)));
                    break;
                case 'required':
                    if (value === 'true') {
                        validators.push(Validators.required);
                    }
                    break;
                case 'requiredTrue':
                    if (value === 'true') {
                        validators.push(Validators.requiredTrue);
                    }
                    break;
                case 'email':
                    if (value === 'true') {
                        validators.push(CustomValidators.regex(new RegExp(CustomPatterns.EMAIL)));
                    }
                    break;
                case 'minLength':
                    validators.push(Validators.minLength(Number(value)));
                    break;
                case 'maxLength':
                    validators.push(Validators.maxLength(Number(value)));
                    break;
                case 'pattern':
                    let patterns: string | RegExp | string[] | RegExp[] = [];
                    if (Array.isArray(value)) {
                        patterns = value.map((pattern: string | RegExp) => {
                            let newPattern: string | RegExp = pattern;
                            if (typeof pattern === 'string' || pattern instanceof String) {
                                newPattern = pattern.replace(/^\/|\/$/g, '');
                            }
                            return new RegExp(newPattern);
                        });
                    } else {
                        let newPattern: string = '';

                        if (typeof value === 'string' || value instanceof String) {
                            newPattern = value.replace(/^\/|\/$/g, '');
                        }

                        patterns = new RegExp(newPattern);
                    }

                    validators.push(CustomValidators.regex(patterns, 'pattern'));
                    break;
                case 'nullValidator':
                    if (value === 'true') {
                        validators.push(Validators.nullValidator);
                    }
                    break;
                default:
                    break;
            }
        });

        return validators;
    }

    dynamicValues(form: any): void {
        if (form.status === 'VALID' || form.status === 'DISABLED') {
            const dynamicValues = this.paymentFluxService.getDynamicValues();
            const dynamicObject = form.dynamic;
            this.paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, dynamicObject));
        }
    }

    getDynamicValues(): any {
        return this.paymentFluxService.getDynamicValues();
    }
}
