import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CredentialsTokensType } from '@cargos/sprintpay-models';
import { Observable, Subject, Subscription, forkJoin, map, of, switchMap, take, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/services/utils/storage.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user/user-session.service';
import { AuthService } from '../../../services/utils/auth.service';
import { LoginService } from '../services/login.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['activate.component.scss'],
})
export class ActivateComponent implements OnInit, OnDestroy {
    customer: any;
    timer: number;
    activationType: string;
    authorizationToken: any;
    isSSO: boolean;
    private subscription: Subscription;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private loginService: LoginService,
        private userSessionService: UserSessionService,
        private tokenService: TokenService,
        private storageService: StorageService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.storageService.cleanStorage();
        this.timer = 3;
        this.activationType = '';
        this.subscription = Subscription.EMPTY;
        this.authorizationToken = undefined;
        this.isSSO = false;
    }

    ngOnInit(): void {
        if (this.router.url.match('/login/sso')) {
            this._logicForSSO(true);
        } else if (this.router.url.match('/saml/SSO')) {
            this._logicForSSO();
        } else {
            this._logicForNormalActivate();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method _logicForSSO(uncompressToken)
     * @description Logic when the user it's an SSO login access
     * @param [uncompressToken=false]
     */
    private _logicForSSO(uncompressToken = false): void {
        this.isSSO = true;

        this.getTokensFromParams(uncompressToken)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (tokens) => {
                    if (tokens?.authorizationToken) {
                        this.authorizationToken = tokens.authorizationToken;
                        this.changeDetectorRef.detectChanges();
                        const inter: NodeJS.Timeout = setInterval((): void => {
                            this.timer--;
                            if (this.timer <= 0) {
                                clearInterval(inter);
                                this.authService.initConfigTokens({
                                    authorizationToken: this.authorizationToken,
                                    refreshToken: tokens.refreshToken,
                                });

                                this.userSessionService.postTokens(tokens, true);
                            }
                        }, 1000);
                    } else {
                        this.router.navigate(['login']);
                    }
                },
                error: () => {
                    this.authorizationToken = null;
                },
            });
    }

    /**
     * @method _logicForNormalActivate()
     * @description Logic when the user it's not an SSO login access
     */

    private _logicForNormalActivate(): void {
        const paramsActivatedRoute: Subscription = this.activatedRoute.paramMap.subscribe({
            next: (paramsAsMap: Params): void => {
                const compressToken = paramsAsMap['params'].authorization_token;
                this.activationType = paramsAsMap['params'].activation_type;
                const refreshToken = paramsAsMap['params'].refresh_token;
                this.authService
                    .uncompressToken(compressToken)
                    .pipe(
                        take(1),
                        switchMap((unCompress: any) => {
                            const unCompressToken = unCompress.result;
                            return this.loginService
                                .initLogin(unCompressToken)
                                .pipe(map((authorizationToken: string) => ({ authorizationToken, refreshToken })));
                        })
                    )
                    .subscribe({
                        next: (tokens: any): void => {
                            this.authorizationToken = tokens.authorizationToken;
                            this.authService.initConfigTokens(tokens);
                            this.userSessionService.postTokens(tokens);
                            const url = this.loginService.getRedirectLink();
                            const inter: NodeJS.Timeout = setInterval((): void => {
                                this.timer--;
                                if (this.timer <= 0) {
                                    clearInterval(inter);
                                    this.router.navigate([url]);
                                }
                            }, 1000);
                        },
                        error: (): void => {
                            this.router.navigate(['login']).catch(() => {
                                this.authorizationToken = null;
                            });
                        },
                    });
            },
            error: (): void => {
                this.authorizationToken = null;
            },
        });

        this.subscription.add(paramsActivatedRoute);
    }

    getTokensFromParams(uncompressToken = false): Observable<CredentialsTokensType | null> {
        return this.activatedRoute.queryParams.pipe(
            switchMap((params: Params) => {
                const authorizationTokenParam: string = params['access_token'];
                const refreshTokenParam: string = params['refresh_token'];

                if (authorizationTokenParam && refreshTokenParam && uncompressToken) {
                    return forkJoin([
                        this.authService.uncompressToken(authorizationTokenParam).pipe(take(1)),
                        this.authService.uncompressToken(refreshTokenParam).pipe(take(1)),
                    ]).pipe(
                        map(([authorizationTokenResponse, refreshTokenResponse]) => {
                            const authorizationToken = authorizationTokenResponse?.result || '';
                            const refreshToken = refreshTokenResponse?.result || '';

                            if (this.tokenService.isTokenValid(authorizationToken)) {
                                return {
                                    authorizationToken: authorizationToken || '',
                                    refreshToken: refreshToken || '',
                                };
                            }
                            return null;
                        })
                    );
                } else if (authorizationTokenParam && refreshTokenParam) {
                    return of({
                        authorizationToken: authorizationTokenParam || '',
                        refreshToken: authorizationTokenParam || '',
                    });
                }
                return of(null);
            })
        );
    }
}
