import { Injectable, OnDestroy, SecurityContext, effect } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment/moment';
import { switchMap } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { environment } from '../../environments/environment';
import { swalAlert } from '../models/utils/swal.models';
import { termsOfUseURL } from '../utils/constants';
import { CustomerAPIService } from './requests/customer-api.service';
import { SwalService } from './swal.service';
import { AuthService } from './utils/auth.service';
import { ErrorHandlerService } from './utils/error-handler.service';
import { TokenService } from './utils/token.service';
import { CustomerService } from './utils/user/customer-handler.service';
import { UserSessionService } from './utils/user/user-session.service';

@Injectable({ providedIn: 'root' })
export class TermsOfUseService implements OnDestroy {
    private readonly _authUrl: string;

    constructor(
        private _domSanitizer: DomSanitizer,
        private _errorHandlerService: ErrorHandlerService,
        private _authService: AuthService,
        private _customerService: CustomerService,
        private _tokenService: TokenService,
        private _customerAPIService: CustomerAPIService,
        private _userSessionService: UserSessionService,
        private swalService: SwalService
    ) {
        this._authUrl = environment.uris.method.authentication;
        effect(() => {
            this.displaySwal();
        });
    }

    ngOnDestroy(): void {
        Swal.close();
    }

    /**
     * @method validateShowTermsOfUse()
     * @param (customer: any)
     * @description Verify if the user accepted the last term of use
     */

    validateShowTermsOfUse(customer: any): void {
        const effectiveDate = customer && customer.effectiveDate ? customer.effectiveDate : null;
        const date: string = new Date(effectiveDate).toUTCString();
        const dueDate: moment.Moment = moment.utc(date);
        const diffDay: number = dueDate.startOf('day').diff(moment.utc(), 'days');
        const privacyPolicyUrl: string | null = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl(termsOfUseURL)
        );
        if (!customer.acceptTerms && diffDay < 1) {
            Swal.close();

            this.swalService.addSwal({
                type: 'terms',
                html: `<img class='mt-3' src='assets/images/sprintpay-horizontal-logo.svg' alt='SrintPay Logo' style='height: 50px: width: 100%'>
            <img src='assets/images/documents.svg' alt=' Ilustration'>
            <h5 class='mt-3' style='text-align: center; color: #032747;'>SprintPay Terms have changed</h5>
            <div style='padding: 1rem 1rem 0 1rem; text-align: center;'>
              <p style='font-weight:400; color: #000000; font-size: 18px;'>
                 <strong>As of November 21, 2024, our <a style='color:#14BB9C; text-decoration: underline;' href='${privacyPolicyUrl}' target='_blank'>Terms of Use</a> for SprintPay have been updated </strong> and need to be reviewed by you. By accepting, you acknowledge you’ve read them and agree to abide by their contents.
              </p>
            </div>`,
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: true,
                confirmButtonColor: '#14BB9C',
                confirmButtonText: 'Accept',
                cancelButtonText: 'Decline',
            });
        }
    }
    private displaySwal(): void {
        if (this.swalService.swals().length > 0 && !Swal.isVisible()) {
            const swalContent: swalAlert = this.swalService.swals()[0];
            if (swalContent.type === 'terms') {
                const accessToken: string | null = this._tokenService.getCurrentAccessControl();
                const customerId = JSON.parse(this._customerService.getDecodedToken()?.SPRINT_PAY_CUSTOMER)?.id;
                Swal.fire({
                    html: swalContent.html,
                    showCancelButton: swalContent.showCancelButton,
                    allowOutsideClick: swalContent.allowOutsideClick,
                    allowEscapeKey: swalContent.allowEscapeKey,
                    showConfirmButton: swalContent.showConfirmButton,
                    confirmButtonColor: swalContent.confirmButtonColor,
                    confirmButtonText: swalContent.confirmButtonText,
                    cancelButtonText: swalContent.cancelButtonText,
                }).then((result: SweetAlertResult): void => {
                    if (result.value) {
                        const utcDate: string = moment.utc(new Date()).format('YYYY-MM-DD 00:00:00');
                        this._authService
                            .getIPAddress()
                            .pipe(
                                switchMap((ip: string) => {
                                    return this._customerAPIService.getCustomerTermsOfUseRequest(
                                        customerId,
                                        ip,
                                        accessToken,
                                        utcDate
                                    );
                                })
                            )
                            .subscribe({
                                next: (response: any): void => {
                                    if (response && response.authorizationToken && response.refreshToken) {
                                        this._authService.initConfigTokens(response);
                                    }
                                },
                                error: (error: any): void => {
                                    Swal.fire({
                                        title: 'Oops...',
                                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        confirmButtonColor: '#14bb9c',
                                        allowOutsideClick: false,
                                    });
                                },
                            });
                    } else {
                        this._userSessionService.logout();
                    }
                    setTimeout(() => {
                        this.swalService.removeSwal(swalContent.id || 0);
                    }, 500);
                });
            }
        }
    }
}
