<!--START: DYNAMIC COMPANY CUSTOMER REFERENCE -->
<div id="company_reference" class="mt-4">
    <div id="company_reference--container">
        <div class="mt-4 d-block">
            <h6 class="step_section mb-4">{{ company.name }} Customer Reference</h6>
            <!--START: FORM -->
            <ng-container *ngIf="companyReferenceForm">
                <form class="row" [formGroup]="companyReferenceForm">
                    <!--START: PAYMENT TYPE -->
                    <ng-container *ngIf="paymentMethodTypes.length > 1 && !hasExternalLookup">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Payment Amount</mat-label>
                                <mat-select
                                    id="paymentAmount"
                                    formControlName="paymentAmount"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option
                                        *ngFor="let paymentType of paymentMethodTypes; trackBy: trackBy"
                                        [value]="paymentType"
                                    >
                                        {{paymentType}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="companyReferenceForm.controls['type'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: PAYMENT TYPE -->
                    <!--START: LOCATION -->
                    <ng-container *ngIf="!locationAssigned">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Location</mat-label>
                                <mat-select
                                    id="locationId"
                                    formControlName="locationId"
                                    [disabled]="onEdition"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option
                                        (onSelectionChange)="locationSelected(item, $event)"
                                        *ngFor="let item of locations; trackBy: trackBy"
                                        [value]="item.id"
                                    >
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="companyReferenceForm.controls['locationId'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: LOCATION  -->
                    <!--START: TYPE -->
                    <ng-container *ngIf="!hasExternalLookup">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Type</mat-label>
                                <mat-select
                                    id="type"
                                    [disabled]="disabledInput"
                                    formControlName="type"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option *ngFor="let item of typeOptions; trackBy: trackBy" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="companyReferenceForm.controls['type'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: TYPE -->
                    <!--START: DYNAMIC FIELDS-->
                    <app-information-collector-by-payment [section]="0"></app-information-collector-by-payment>
                    <!--END: DYNAMIC FIELDS-->
                </form>
                <!--END: FORM -->
            </ng-container>
        </div>
        <!--START: STEPPER NEXT BUTTON -->
        <div class="row">
            <div class="col-sm-12 col-md-3 offset-md-9">
                <div class="d-flex justify-content-end w-100">
                    <button
                        (mousedown)="getValues()"
                        mat-button
                        [ngClass]="(companyReferenceForm.invalid || !dynamicFormValid) ? 'btn__sprintpay--greenDisabled' : ''"
                        type="button"
                        class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <!--END: STEPPER NEXT BUTTON -->
    </div>
</div>
<!--END: DYNAMIC COMPANY CUSTOMER REFERENCE -->
