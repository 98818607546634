import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DynamicFieldDTO } from '../models/ui/dynamic-field-common.model';
import { RestService } from './rest.service';

export interface ColumnsFormat {
    column: any;
    columnName: any;
}

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    private readonly _paymentsUrl: string;

    constructor(private _restService: RestService) {
        this._paymentsUrl = environment.uris.method.payments;
    }

    /**
     * @method getFormFields()
     * @param (section: string)
     * @param (companyId: string)
     * @description
     * @return
     */

    getFormFields(section: string): Promise<DynamicFieldDTO[]> {
        const apiUrl: string = `${this._paymentsUrl}/company/dynamic-fields?section=${section}`;
        return this._restService.get<DynamicFieldDTO[]>(apiUrl, {});
    }

    filterSource(source: any): any {
        const key = this.findKeyElement(source);
        if (!key) {
            return source;
        }
        let previusValue: string = '';
        return source.map((item: any) => {
            if (item[key].includes('VIN')) {
                if (previusValue !== item[key]) {
                    previusValue = item[key];
                    return item;
                } else {
                    item[key] = '';
                }
            }
            return item;
        });
    }

    findKeyElement(source: any): string {
        const key = source.map((item: any) =>
            Object.entries(item)
                .map(([key, value]) => {
                    if (typeof value === 'string') {
                        let valueAsString = value as string;
                        if (valueAsString?.toLowerCase()?.includes('vin')) {
                            return key;
                        }
                    }
                    return '';
                })
                .find((element: string) => element !== '')
        );
        return key.find((element) => !!element);
    }
}
