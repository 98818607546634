import { Component } from '@angular/core';

@Component({
    selector: 'app-credit-card-hold',
    templateUrl: './credit-card-hold.component.html',
})
export class CreditCardHoldComponent {
    public cardClosed: boolean = false;

    closeBanner(): void {
        this.cardClosed = true;
    }
}
