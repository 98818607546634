import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class DynamicFieldAPIService {
    private paymentsUrl: string;

    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService
    ) {
        this.paymentsUrl = environment.uris.method.payments;
    }

    getDynamicFieldsBySection(section?: number): Observable<DynamicField[]> {
        let url: string = `${this.paymentsUrl}/company/dynamic-fields`;
        if (section) {
            url = `${url}?section=${section}`;
        }
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.get<DynamicField[]>(url, { headers });
    }

    getDynamicSectionsByCompany(companyId?: number): Observable<DynamicSections[]> {
        const url: string = `${this.paymentsUrl}/company/dynamic-sections?company=${companyId}`;
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const token = this.tokenService.getCurrentUser();
        if (!token) {
            throw new Error('Token is missing');
        }
        headers = headers.append('Authorization', `Bearer ${token}`);
        return this.httpClient.get<DynamicSections[]>(url, { headers });
    }
}
