import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, finalize, map, take } from 'rxjs';
import { UserSessionService } from '../services';
import { TokenService } from '../services/utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private _tokenService: TokenService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _userSessionService: UserSessionService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (state.url.includes('admin/facilityPayments/newPayment?') && Object.keys(route.queryParams).length) {
            this._ngxSpinnerService.show();
            return this._userSessionService.signInAsGuest().pipe(
                take(1),
                map((tokens) => !!tokens),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            );
        }

        if (this._tokenService.isAuthenticated()) {
            return true;
        } else {
            this._userSessionService.logout();
            return false;
        }
    }
}
