import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { UserProperties } from './user';

export class SchenkerUser implements UserProperties {
    constructor(
        public dynamicFields: DynamicField[] = [],
        public dynamicSections: DynamicSections[] = []
    ) {
        this.dynamicSections = [
            {
                id: 1,
                label: 'Schenker Customer Reference',
                orderNumber: 0,
                company: { id: 57, name: 'Schenker' },
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Payment Details',
                orderNumber: 1,
                company: { id: 57, name: 'Schenker' },
                emailDomain: null,
            },
            {
                id: 3,
                label: 'Customer Reference',
                orderNumber: 2,
                company: { id: 57, name: 'Schenker' },
                emailDomain: null,
            },
        ];
        this.dynamicFields = [];
    }
}
