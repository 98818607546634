import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerService, UserSessionService } from '../services';
import { TokenService } from '../services/utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard {
    constructor(
        private _router: Router,
        private _tokenService: TokenService,
        private _customerService: CustomerService,
        private _userSessionService: UserSessionService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isGuest = this._customerService.isGuest();

        if (state.url.toLowerCase().includes('schenker/account-type')) {
            window.location.replace(environment.projects.sprintPayv7 + `/schenker/account-type`);
            return false;
        }

        if (this._tokenService.isAuthenticated()) {
            const url = !isGuest ? '/admin/home' : '/login';
            this._router.navigate([url]);
            return false;
        } else {
            this._userSessionService.logout();
            return false;
        }
    }
}
