import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { environment } from 'src/environments/environment';
import { UserProperties } from './user';

export class DynamicCompany implements UserProperties {
    constructor(
        public dynamicFields: DynamicField[] = [],
        public dynamicSections: DynamicSections[] = []
    ) {
        const paymentURL = environment.uris.method.payments;

        this.dynamicSections = [
            {
                id: 1,
                label: 'Company Customer Reference',
                orderNumber: 0,
                company: { id: 57, name: '' },
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Payment Details',
                orderNumber: 1,
                company: { id: 57, name: '' },
                emailDomain: null,
            },
            {
                id: 3,
                label: 'Customer Reference',
                orderNumber: 2,
                company: { id: 57, name: '' },
                emailDomain: null,
            },
        ];
        this.dynamicFields = [
            {
                name: 'customerRef',
                label: 'Reference',
                section: 2,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage:
                    'Your own personal reference # so you can remember this payment. For example your file #.',
                validations: [
                    {
                        id: 2,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                name: 'locationId',
                label: 'Location',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${paymentURL}/customer/approval-flow/information`,
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
        ];
    }
}
