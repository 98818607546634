import { Component, OnInit } from '@angular/core';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { profileComponents } from 'src/app/utils/constants';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
})
export class NewUserComponent implements OnInit {
    isValidNewPayment: boolean;
    constructor(
        private securityService: SecurityService,
        private customerFeaturesService: CustomerFeaturesService
    ) {
        this.isValidNewPayment = false;
    }

    ngOnInit(): void {
        this.isValidNewPayment =
            this.securityService.verifyComponentsSecurity(profileComponents.newPaymentButton) &&
            this.securityService.verifyComponentsSecurity(profileComponents.newPayment) &&
            this.customerFeaturesService.isAllowedToCreatePaymentRequest();
    }
}
