import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { DynamicField } from '@cargos/sprintpay-models';
import { Validation } from '@cargos/sprintpay-models/dist/src/dynamic-values/dynamic-fields';
import { ErrorInputFieldComponent } from './models/field-error';

@Component({
    standalone: true,
    imports: [NgIf, NgFor],
    selector: 'app-dynamic-error',
    templateUrl: './dynamic-error.component.html',
    styleUrls: ['./dynamic-error.component.scss'],
})
export class DynamicErrorComponent {
    public messageConfig: ErrorInputFieldComponent[] = [];
    public dynamicField: DynamicField = {};

    @Input()
    set field(field: DynamicField) {
        this.dynamicField = field;
        this.messageConfig = this.buildErrorsByField(field.validations || []);
    }

    constructor(private formGroupDirective: FormGroupDirective) {}

    get input(): AbstractControl | null {
        return this.formGroupDirective.form?.get(this.dynamicField?.name || '');
    }

    get errorVisible(): boolean {
        if (!this.input?.errors) {
            return false;
        }

        return this.input.touched || this.input.dirty;
    }

    buildErrorsByField(validations: Validation[]): ErrorInputFieldComponent[] {
        return validations?.map((validation) => ({
            code: validation.name || '',
            message: validation.message || '',
        }));
    }
}
