import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { HomeService } from 'src/app/services/home.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { profileComponents } from 'src/app/utils/constants';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
    public facilitySecurity: Array<string> = [''];
    public openInvoiceSecurity: Array<string> = [''];
    public pendingApprovalSecurity: Array<string> = [''];
    public rejectedRequestSecurity: Array<string> = [''];
    public walletSecurity: Array<string> = [''];
    public facilityStatus: any | undefined;
    public openInvoiceStatus: any | undefined;
    public pendingApprovalStatus: any | undefined;
    public rejectedRequestStatus: any | undefined;
    public walletStatus: any | undefined;
    public isNewUser: boolean = false;
    public showNewPayment: boolean;
    public hasCompany: boolean;
    public isCustomerOnHold: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private paymentFluxService: PaymentFluxService,
        private ngxSpinnerService: NgxSpinnerService,
        private homeService: HomeService,
        private securityService: SecurityService,
        private breadcrumbService: BreadcrumbService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private customerService: CustomerService,
        private customerFeaturesService: CustomerFeaturesService
    ) {
        this.breadcrumbService.set('@home', 'Home');
        this.showNewPayment = String(this.router.url).indexOf('newPayment') <= 0;
        this.hasCompany = !!this.customerService.getCompanyName();
        this.paymentFluxService.removeCurrentPayment();
        this.isCustomerOnHold = this.customerFeaturesService.isCustomerOnHold();
    }

    ngOnInit(): void {
        const userType: string = this.securityService.getUserType();
        if (
            this.securityService.verifyComponentsSecurity(profileComponents.newPaymentButton) &&
            this.customerFeaturesService.isAllowedToCreatePaymentRequest()
        ) {
            this.facilitySecurity.push(userType);
        } else {
            this.homeService.setFacilities(true);
        }
        if (this.securityService.verifyComponentsSecurity(profileComponents.pendingApproval)) {
            this.pendingApprovalSecurity.push(userType);
        } else {
            this.homeService.setPendingApproval(true);
        }
        if (this.securityService.verifyComponentsSecurity(profileComponents.invoiceOpen)) {
            this.openInvoiceSecurity.push(userType);
        } else {
            this.homeService.setInvoices(true);
        }
        if (this.securityService.verifyComponentsSecurity(profileComponents.rejectedPayments)) {
            this.rejectedRequestSecurity.push(userType);
        } else {
            this.homeService.setRejected(true);
        }
        if (
            this.securityService.verifyComponentsSecurity(profileComponents.homeSPCredit) ||
            this.securityService.verifyComponentsSecurity(profileComponents.homeCreditHold)
        ) {
            this.walletSecurity.push(userType);
        } else {
            this.homeService.setWallet(true);
        }
        this.subscribeUserIsEmpty();
        this.showNewPayment = this.router.url ? !this.router.url.includes('newPayment') : false;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.changeDetectorRef.detach();
    }

    subscribeUserIsEmpty(): void {
        this.ngxSpinnerService.show();
        this.homeService
            .userIsEmpty()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (response) => {
                    this.isNewUser = response;
                    this.changeDetectorRef.detectChanges();
                    this.ngxSpinnerService.hide();
                },
                error: () => {
                    this.ngxSpinnerService.hide();
                },
            });
    }
}
