import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileException } from '@cargos/sprintpay-models';
import { FileService } from '@cargos/sprintpay-services';
import { Subject, take, takeUntil } from 'rxjs';
import { FileRequestService } from 'src/app/services/file-handler.service';
import Swal from 'sweetalert2';
import { FileHandlerService } from '../services/file.service';

@Component({
    standalone: true,
    imports: [NgFor, NgIf, MatIconModule, MatProgressSpinnerModule],
    selector: 'app-file-remover',
    templateUrl: './file-remover.component.html',
    styleUrls: ['./file-remover.component.scss'],
})
export class FileRemoverComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    public filesUploaded: string[];
    public removing = false;

    constructor(
        private fileService: FileService,
        private fileRequestService: FileRequestService,
        private fileHandlerService: FileHandlerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeFilesUploaded();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeFilesUploaded(): void {
        this.fileHandlerService
            .getPathFiles()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (filesUploaded) => {
                    this.changeDetectorRef.detectChanges();
                    this.filesUploaded = filesUploaded;
                },
            });
    }

    removeFiles(filePath: string, index: number): void {
        if (this.removing) {
            return;
        }

        this.removing = true;

        this.fileRequestService
            .removeFileSelected(filePath)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.removing = false;
                    this.filesUploaded.splice(index, 1);
                    this.fileHandlerService.setPathFiles(this.filesUploaded);
                },
                error: (error: FileException) => {
                    this.removing = false;

                    Swal.fire({
                        title: error.title,
                        text: error.description,
                        icon: 'error',
                    });
                },
            });
    }

    getFileName(filePath: string): string {
        return this.fileService.getFileName(filePath);
    }

    identify(index: number): number {
        return index;
    }
}
