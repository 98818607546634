import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take, throwError } from 'rxjs';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentToRequestEditService } from 'src/app/services/payment-to-request-edit.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Sections } from 'src/app/utils/sections';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../../environments/environment';
import { RestService } from '../../../../../services/rest.service';
import { CustomerService } from '../../../../../services/utils/user/customer-handler.service';
import { companyName } from '../../../../../utils/constants';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, OnDestroy {
    id: string;
    paymentDetail: any;
    paymentLogs: any;
    paymentDetailFinish: boolean;
    files: any;
    nameFiles: any = [];
    noInformation: boolean | undefined;
    noInformationMessage: string;
    paymentDetailsSection: any;
    paymentCustomerReference: any;
    readonly companyNames: any;
    private dynamicFieldsReplaced: any;
    private companyName: string;
    private _approvalStatus!: string;
    private readonly _cartUrl: string;

    constructor(
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _breadcrumbService: BreadcrumbService,
        private _activatedRoute: ActivatedRoute,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _matDialog: MatDialog,
        private _customerService: CustomerService,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this._breadcrumbService.set('@voided-payments', 'Voided payment(s)');
        this._breadcrumbService.set('@payment-detail', 'Payment detail');
        this.id = '';
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this.companyNames = companyName;
        this.companyName = this._customerService.getCompanyName();
        this.paymentDetailFinish = false;
        this._cartUrl = environment.uris.method.cart;
        this.getAllFieldsWithReplacement();
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: any): void => {
                this.id = params['id'];
                this._approvalStatus = params['approvalStatus'];
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
        this._getPaymentDetail();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method _getPaymentDetail()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentDetail(): void {
        const url: string = `${this._cartUrl}/payment-request/details/${this.id}?approvalStatus=${this._approvalStatus}`;
        const companyType: string = this._customerService.getCompanyName();

        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentDetail = this._paymentToRequestEditService.getPaymentRequestDataCart(
                    result,
                    '/admin/facilityPayments/voidedPayments/paymentDetail/' + this.id + '/' + result.approvalStatus,
                    companyType.toString()
                );
                this.paymentDetail.approvalStatus = result['approvalStatus'];
                this.paymentDetail.lastModifiedDate = result['lastModifiedDate'];
                this.paymentDetail.companyType = companyType.toString();
                this.paymentDetail.createdDate = new Date(result['createdDate']);
                this.paymentDetail.location = result['location']['name'];
                this.paymentDetail.hawb = result['hawb'];
                this.paymentDetail.approvalReason = result.approvalReason;
                this.paymentDetail.name = `${result.originalRequestor.firstName} ${result.originalRequestor.lastName}`;
                this.files = result.attachments ? result.attachments.split(',') : '';
                result.attachments
                    ? result.attachments.split(',').map((item: any): void => {
                          this.nameFiles.push(item.split('/')[5]);
                      })
                    : '';
                this.getPaymentCustomerReferenceSection();
                this.getPaymentDetailsSection();
                this._getPaymentLogs();
            })
            .catch((error: any): void => {
                this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                this.noInformation = true;
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _getPaymentLogs()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentLogs(): void {
        const url: string = `${this._cartUrl}/getLogHistoryByPaymentRequest/${
            this.id
        }?page=0&size=30&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentLogs = result.content.map((item: any) => {
                    return {
                        approval: `${item.approvalLevel}: ${item.firstName} ${item.lastName}`,
                        date: moment(item.date).format('MMM DD, YYYY, h:mm:ss a'),
                        event: item.event,
                        reason: item.reason,
                    };
                });
                this.noInformation = false;
                this._ngxSpinnerService.hide();
            })
            .catch((): void => {
                this._ngxSpinnerService.hide();
            })
            .finally((): void => {
                this.paymentDetailFinish = true;
            });
    }

    /**
     * @method openDialog()
     * @param (templateRef: any)
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'timeline',
            disableClose: true,
        });
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        if (this.companyName && Object.keys(this.companyNames).indexOf(this.companyName.toLowerCase()) === -1) {
            this.dynamicFieldsReplaced = [];
            this.dynamicFieldsService
                .getFieldsReplaced()
                .pipe(take(1))
                .subscribe({
                    next: (fieldsReplaced) => {
                        this.dynamicFieldsReplaced = fieldsReplaced;
                    },
                });
        }
    }

    getPaymentDetailsSection(): void {
        const companyType: string = this._customerService.getCompanyName();
        this.paymentDetailsSection = Sections.getDetailsOfPaymentDetailsSummarySection(this.paymentDetail, companyType);
    }

    getPaymentCustomerReferenceSection(): void {
        const customerReferenceLabel = this.dynamicFieldsReplaced?.find((item) => item.name === 'customerRef');
        const companyType: string = this._customerService.getCompanyName();
        const customer = this._customerService.getCustomer();
        this.paymentCustomerReference = Sections.getDetailsOfCustomerReferenceSummarySection(
            this.paymentDetail.reference,
            customer,
            customerReferenceLabel?.label || '',
            companyType
        );
    }
}
