<app-breadcrumb></app-breadcrumb>
<app-no-information *ngIf="noInformation === true" [error_message]="noInformationMessage"></app-no-information>
<!--START: FACILITY CONTACTS -->
<section id="facility_contacts" *ngIf="noInformation === false">
    <div id="facility_contacts__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <h6>Facility contacts</h6>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
    </div>
    <!--START: CALL AM TABLE CHILD COMPONENT -->
    <ng-container *ngIf="facilityContacts">
        <div id="facility_contacts__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <app-am-table
                        (filter)="search($event)"
                        (executeFunction)="payToFacility($event)"
                        (nextPage)="search($event)"
                        (eventOnSort)="sort($event)"
                        [am_filter]="amTable[0].filter"
                        [am_sorting]="amTable[0].sorting"
                        [am_pagination]="amTable[0].pagination"
                        [am_columns]="amTable[0].displayedColumns"
                        [am_columnsName]="amTable[0].displayedColumnsName"
                        [am_source]="amTable[0].dataSource"
                        [am_paginationSizes]="amTable[0].paginationSizes"
                        [am_totalRows]="amTable[0].totalElements"
                        [am_sortableColumns]="sortableColumns"
                        [am_paginationSessionKey]="'facilityContactsTable'"
                        [am_paginationSessionPaths]="['/admin/facilityContacts/paymentDetail/']"
                    ></app-am-table>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: CALL AM TABLE CHILD COMPONENT -->
</section>
<!--END: FACILITY CONTACTS -->
