import { Component, OnInit } from '@angular/core';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { SecurityService } from '../../../../services/utils/security.service';
import { profileComponents } from '../../../../utils/constants';

@Component({
    selector: 'app-empty-cart',
    templateUrl: './empty-cart.component.html',
})
export class EmptyCartComponent implements OnInit {
    newPaymentSecurity: Array<string>;

    constructor(
        private securityService: SecurityService,
        private customerFeatureService: CustomerFeaturesService
    ) {
        this.newPaymentSecurity = [''];
    }

    ngOnInit(): void {
        const userType: string = this.securityService.getUserType();

        if (
            this.securityService.verifyComponentsSecurity(profileComponents.newPaymentInEmptyCart) &&
            this.customerFeatureService.isAllowedToCreatePaymentRequest()
        ) {
            this.newPaymentSecurity.push(userType);
        }
    }
}
