<!--START: Standard Customer Reference -->
<div id="standard_flux" class="mt-4">
    <div id="standard_flux--container">
        <div class="mt-4 d-block">
            <h6 class="step_section mb-4">Customer reference</h6>
            <!--START: Standard Customer Reference Form -->
            <form class="row" [formGroup]="referenceForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative">
                        <!--START: Customer Reference -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <mat-form-field class="d-block" appearance="outline">
                                        <mat-label>{{labelCustomerRef}}</mat-label>
                                        <ng-container *ngIf="startingLevel && sourceSystem === 'CW1'">
                                            <input
                                                matInput
                                                type="text"
                                                id="customerRef"
                                                name="customerRef"
                                                formControlName="customerRef"
                                                autocomplete="off"
                                                placeholder="{{customerReferencePlaceholder}}"
                                                mask="{ mask: cw1_mask }"
                                                [errorStateMatcher]="matcher"
                                                [minLength]="dynamic_minlength"
                                                [maxLength]="dynamic_maxlength"
                                                (ngModelChange)="change(referenceForm)"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="!(startingLevel && sourceSystem === 'CW1')">
                                            <input
                                                matInput
                                                type="text"
                                                id="customerRef"
                                                name="customerRef"
                                                formControlName="customerRef"
                                                autocomplete="off"
                                                placeholder="{{customerReferencePlaceholder}}"
                                                (ngModelChange)="validateReferenceNumber(referenceForm); getGLCodesActives($event)"
                                                (focusin)="tooltipCustomerRefVisible = true"
                                                (focusout)="tooltipCustomerRefVisible = false"
                                                [errorStateMatcher]="matcher"
                                                [minLength]="dynamic_minlength"
                                                [maxLength]="dynamic_maxlength"
                                                (ngModelChange)="change(referenceForm)"
                                            />
                                        </ng-container>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('required') && !customerRefRequiredErrorMessage"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                        </mat-error>
                                        <mat-error *ngIf="referenceForm.controls['customerRef'].hasError('maxlength')">
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i> Maximum length exceeded
                                            (255 characters maximum)
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('required') && customerRefRequiredErrorMessage"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i
                                            >{{customerRefRequiredErrorMessage}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('minlength') && !isUserCevaDomain"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 8 characters
                                            are required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('minlength') && isUserCevaDomain"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Must contain 13 numeric
                                            characters
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && (startingLevel && sourceSystem === 'CW1')"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Must be contain S or C
                                            plus 10 digits
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('maxlength') && startingLevel && sourceSystem === 'CW1'"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Limit of 11 digits
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && knUser"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i
                                            ><strong>Enter as either:</strong>
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>10 number - 4 numeric
                                            (1040942865-4952) <i class="bi bi-exclamation-triangle-fill me-2"></i>2
                                            numeric - 4 numeric -3 numeric . 3 numeric (21-3830-108.011)
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>2 alpha + 8 numeric - 4
                                            numeric (US00074747-4978)
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && schenkerUser"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Enter as either 5 alpha
                                            + 9 numeric, or 10 numeric, or 7 alpha + 6 numeric, or \"SH-\" + 9-12
                                            numeric
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && emotransUser"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Enter either as 9 or 10
                                            numeric, or 4 numeric then a “-” followed by 6 numeric, or 1 alpha + 4
                                            numeric + 1 alpha + 8 numeric, or 1 alpha + 12 numeric, or 2 alpha + 8
                                            numeric so as an example 123456789, or 1234567890, or 1234-567890, or
                                            S1234L12345678, or E123456789001, or EM12345678
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && rhenusUser"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid format. Enter a
                                            minimum of 3 characters and maximum of 6 numeric characters allowed.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && customer.expeditors"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Maximum of 10
                                            alpha-numeric characters allowed
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('pattern') && customerRefPatternErrorMessage"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i
                                            >{{customerRefPatternErrorMessage}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('whiteSpace') && customer.expeditors"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Spaces are not allowed
                                        </mat-error>
                                        <mat-error *ngIf="customerRef.hasError('pattern') && dsvDomain">
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Must start with a C
                                            followed by 8-9 alphanumeric characters, or start with an S, followed by
                                            10-11 alphanumeric characters, or must start with a B followed by 10-11
                                            alphanumeric characters.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="referenceForm.controls['customerRef'].hasError('preventedHTMLStr')"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags and/or URLs
                                            are not allowed here
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!--END: Customer Reference  -->
                        <div
                            class="row"
                            [ngClass]="referenceForm.controls['customerRef'].hasError('pattern') ? 'mt-3' : ''"
                        >
                            <div class="col-12">
                                <ng-container *ngIf="tooltipCustomerRefVisible">
                                    <span class="cs__tooltip cs__tooltip--warning">{{tooltipCustomerRef}}</span>
                                </ng-container>
                            </div>
                        </div>
                        <!--START: GEODIS Local Client Name or (EMO trans) Customer to be Billed -->
                        <ng-container *ngIf="geodisUser || geodisDomain || emotransUser">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>
                                                {{geodisUser || geodisDomain ? 'GEODIS Local Client Name' : 'Customer to
                                                be Billed'}}
                                            </mat-label>
                                            <input
                                                matInput
                                                type="text"
                                                id="externalReferenceNum"
                                                name="externalReferenceNum"
                                                formControlName="externalReferenceNum"
                                                autocomplete="off"
                                                placeholder="{{geodisUser || geodisDomain ? 'Enter the GEODIS Local Client Name' : 'Enter the Customer to be Billed'}}"
                                                maxlength="{{geodisUser || geodisDomain || emotransUser ? 30 : 0}}"
                                                [errorStateMatcher]="matcher"
                                                (ngModelChange)="change(referenceForm)"
                                            />
                                            <mat-error
                                                *ngIf="referenceForm.controls['externalReferenceNum'].hasError('required')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                            </mat-error>
                                            <mat-error
                                                *ngIf="referenceForm.controls['externalReferenceNum'].hasError('maxlength')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Limit of 30
                                                characters
                                            </mat-error>
                                            <mat-error
                                                *ngIf="referenceForm.controls['externalReferenceNum'].hasError('preventedHTMLStr')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags and/or
                                                URLs are not allowed here
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!--END: GEODIS Local Client Name or (EMO trans) Customer to be Billed -->
                        <!--START: RHENUS PRODUCT -->
                        <ng-container *ngIf="rhenusUser">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>Product</mat-label>
                                            <mat-select
                                                id="sourceSystem"
                                                formControlName="externalReferenceNum"
                                                [errorStateMatcher]="matcher"
                                            >
                                                <mat-option
                                                    *ngFor="let option of productOptions; trackBy: trackBy"
                                                    [value]="option"
                                                >
                                                    {{option}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="referenceForm.controls['externalReferenceNum'].hasError('required')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                            </mat-error>
                                            <mat-error
                                                *ngIf="referenceForm.controls['externalReferenceNum'].hasError('preventedHTMLStr')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags and/or
                                                URLs are not allowed here
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!--END: RHENUS PRODUCT -->
                        <!--START: Notification email  -->
                        <ng-container *ngxPermissionsExcept="'GUEST'"
                            ><div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>{{labelNotificationEmail}}</mat-label>
                                            <input
                                                matInput
                                                type="email"
                                                [email]="true"
                                                id="email"
                                                name="email"
                                                formControlName="email"
                                                autocomplete="off"
                                                placeholder="{{notificationEmailPlaceholder}}"
                                                [errorStateMatcher]="matcher"
                                                (ngModelChange)="change(referenceForm)"
                                                [appDisableControl]="knUser || schenkerUser"
                                            />
                                            <mat-error *ngIf="referenceForm.controls['email'].hasError('required')">
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                            </mat-error>
                                            <mat-error *ngIf="referenceForm.controls['email'].hasError('pattern')">
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid email
                                            </mat-error>
                                            <mat-error *ngIf="referenceForm.controls['email'].hasError('email')">
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid email
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div></ng-container
                        >
                        <!--END: Notification email -->
                        <!--START: GL Codes  -->
                        <ng-container *ngIf="customer?.hasGlCodes">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>GL Code</mat-label>
                                            <mat-select
                                                id="station"
                                                formControlName="glCode"
                                                [errorStateMatcher]="matcher"
                                                (ngModelChange)="change(referenceForm)"
                                            >
                                                <mat-option
                                                    *ngFor="let item of glCodes; trackBy: trackBy"
                                                    [value]="item.name"
                                                >
                                                    {{item.name}} - {{item.description}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="referenceForm.controls['glCode'].hasError('required')">
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!--END:  GL Codes -->
                        <!--START: DYNAMIC FIELDS-->
                        <app-information-collector-by-payment
                            [section]="section"
                        ></app-information-collector-by-payment>
                        <!--END: DYNAMIC FIELDS-->
                        <!--START: Notes -->
                        <ng-container *ngIf="!hasExternalLookup">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>
                                                <div class="d-flex gap-2 align-items-center">
                                                    Notes (Optional)
                                                    <span
                                                        class="material-symbols-outlined"
                                                        matTooltip="These notes are limited to 255 characters and are shared with the facility"
                                                        [matTooltipPosition]="'above'"
                                                    >
                                                        info
                                                    </span>
                                                </div>
                                            </mat-label>
                                            <textarea
                                                matInput
                                                placeholder="Add notes"
                                                rows="3"
                                                maxlength="250"
                                                formControlName="notes"
                                                [errorStateMatcher]="matcher"
                                                (ngModelChange)="change(referenceForm)"
                                            ></textarea>
                                            <mat-error
                                                *ngIf="referenceForm.controls['notes'].hasError('preventedHTMLStr')"
                                            >
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags and/or
                                                URLs are not allowed here
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!--END:  Notes -->
                    </div>
                </div>
            </form>
            <!--END: Standard Customer Reference Form -->
        </div>
        <ng-container *ngIf="!hasExternalLookup">
            <!--START: STEPPER NEXT AND BACK BUTTON -->
            <div class="row">
                <ng-container *ngIf="!currentPayment?.id">
                    <div class="col-sm-12 col-md-6 mb-3 mb-lg-0">
                        <div class="d-flex justify-content-start w-100">
                            <button
                                mat-button
                                matStepperPrevious
                                type="button"
                                (click)="backStep()"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="d-flex justify-content-end w-100">
                            <button
                                mat-button
                                (mousedown)="checkout()"
                                [ngClass]="(referenceForm.invalid || !dynamicFormValid) ? 'btn__sprintpay--greenDisabled' : ''"
                                type="button"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentPayment?.id">
                    <div class="col-sm-12 col-md-3 offset-md-9">
                        <div class="d-flex justify-content-end w-100">
                            <button
                                mat-button
                                (mousedown)="checkout()"
                                [ngClass]="(referenceForm.invalid || !dynamicFormValid) ? 'btn__sprintpay--greenDisabled' : ''"
                                type="button"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--END: STEPPER NEXT AND BACK BUTTON -->
        </ng-container>
    </div>
</div>
<!--END: Standard Customer Reference -->
