import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicField, Validation } from '@cargos/sprintpay-models';
import { Subject, of, switchMap, take, takeUntil } from 'rxjs';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { FieldsDynamicFormService } from '../../services/fields.service';
import { DynamicErrorComponent } from '../dynamic-error/dynamic-error.component';
@Component({
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        DynamicErrorComponent,
    ],
    selector: 'app-dynamic-input',
    templateUrl: './dynamic-input.component.html',
    styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    private fieldWithValidationAPI: boolean = false;
    public formName: FormGroup;

    @Input() field: DynamicField = {};

    constructor(
        private formGroupDirective: FormGroupDirective,
        private fieldsDynamicFormService: FieldsDynamicFormService,
        private customerService: CustomerService
    ) {
        this.formName = formGroupDirective.control;
    }

    get fileInput(): AbstractControl<string> | null {
        return this.formName.get(this.field?.name || '');
    }

    ngAfterViewInit(): void {
        this.fieldWithValidationAPI = !!this.hasAutoValidatorAPI();
        this.fetchDataIfIsProvider();
        this.fetchAutoValidator();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    fetchDataIfIsProvider(): void {
        if (!this.field.providerId) {
            return;
        }

        if (this.field.providerId) {
            this.fileInput?.valueChanges
                .pipe(
                    switchMap((value) => {
                        return this.fieldsDynamicFormService.fetchDataIfIsProvider(this.field, value).pipe(take(1));
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe();
        }
    }

    fetchAutoValidator(): void {
        if (!this.fieldWithValidationAPI) {
            return;
        }
        const validationWithAPI = this.hasAutoValidatorAPI();

        this.fileInput?.valueChanges
            .pipe(
                switchMap((value) => {
                    if (value && validationWithAPI) {
                        let values: string[];
                        if (!validationWithAPI.apiValidator && typeof validationWithAPI?.value === 'string') {
                            values = validationWithAPI.value.split('?');
                        } else {
                            values = validationWithAPI.apiValidator?.split('?') || [''];
                        }
                        const url = values[0];
                        const params = this.getParam(validationWithAPI, values[1], value);
                        const nerwValue = `${url}${params}`;
                        return this.fieldsDynamicFormService.fetchAutoValidator(nerwValue).pipe(take(1));
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (isValid) => {
                    if (!isValid && validationWithAPI?.name) {
                        this.fileInput?.setErrors({ [validationWithAPI.name]: true });
                    }
                },
            });
    }

    getParam(validationWithAPI: Validation, data: string, value: string): string {
        let params = '';
        data.split('&').forEach((item, index) => {
            const aux = item.replace(/{|}/g, '');
            const newParam = this.getValueParam(aux, validationWithAPI.id!, value);
            if (index === 0) {
                params = `?${aux}=${newParam}`;
            } else {
                params += `&${aux}=${newParam}`;
            }
        });
        return params;
    }

    getValueParam(param: string, id: number, value: string): string | number | null | undefined {
        switch (param) {
            case 'company':
                return this.customerService.getCompanyName();
                break;
            case 'validationId':
                return id;
                break;
            case 'term':
                return value;
                break;
            case 'name':
                return value;
                break;
            default:
                return '';
                break;
        }
    }

    hasAutoValidatorAPI(): Validation | undefined {
        return this.field?.validations?.find(
            (validation) => validation.apiValidator || validation.name === 'apiValidatorData'
        );
    }
}
