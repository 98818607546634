import { Injectable } from '@angular/core';
import { FileDescriptionException, FileException, FileExceptionType } from '@cargos/sprintpay-models';
import { FileAttachmentService } from '@cargos/sprintpay-services';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FileRequestService {
    constructor(private fileAttachmentService: FileAttachmentService) {}

    /**
     * Function to upload files to BackEnd
     * @returns {Observable<string>} This function returns the file location
     * @param {FormData} file File uploaded
     */
    uploadFile(file: FormData): Observable<string> {
        return this.fileAttachmentService.uploadAttachment(file);
    }

    removeFileSelected(fileName: string): Observable<string> {
        return this.fileAttachmentService.removeAttachment(fileName).pipe(
            map(() => 'Complete'),
            catchError(() =>
                throwError(() =>
                    FileException.fromJson({
                        code: FileExceptionType.DELETE_ERROR,
                        description: FileDescriptionException[5],
                    })
                )
            )
        );
    }
}
