import { Injectable } from '@angular/core';
import { StatService } from '@cargos/sprintpay-services';
import { DownloadLabel } from 'src/app/standalone-components/download/models/download-settings';
import { ProfileTypes } from 'src/app/utils/profile-types';
import { CustomerService } from '../utils/user';

@Injectable({ providedIn: 'root' })
export class StatsService {
    constructor(
        private statService: StatService,
        private customerService: CustomerService
    ) {}

    /**
     * Logs download events for open invoices or statements based on profile type.
     * @param type - The type of document to download (Invoices or Statement).
     */
    openInvoices(type: DownloadLabel = ''): void {
        const profileType = this.customerService.getActiveProfileView();

        const eventMapping = {
            [ProfileTypes.FORWARDER]: {
                Invoices: 'open_invoices_download_forwarder',
                Statement: 'open_statements_download_forwarder',
            },
            [ProfileTypes.FORWARDER_ADMIN]: {
                Invoices: 'open_invoices_download_forwarder_admin',
            },
        };

        const descriptionMapping = {
            Invoices: 'portal invoice PDF download',
            Statement: 'portal statement XLS download',
        };

        if (eventMapping[profileType]?.[type]) {
            this.statService.logEvent(eventMapping[profileType][type], `${profileType} ${descriptionMapping[type]}`, {
                tag: 'open_invoice_download',
            });
        }
    }
}
