import { Component, OnInit } from '@angular/core';
import { Customer } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/services';
import { HomeService } from 'src/app/services/home.service';
import { environment } from '../../../../../../environments/environment';
import { PayToFacilityService } from '../../../../../services/pay-to-facility.service';
import { RestService } from '../../../../../services/rest.service';

@Component({
    selector: 'app-facilities',
    templateUrl: './facilities.component.html',
})
export class FacilitiesComponent implements OnInit {
    facilities: Array<any>;
    private readonly _facilitiesUrl: string;
    public customer: Customer | null;

    constructor(
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _homeService: HomeService,
        private _payToFacilityService: PayToFacilityService,
        private customerService: CustomerService
    ) {
        this.facilities = [];
        this._facilitiesUrl = environment.uris.method.facilities;
        this.customer = this.customerService.getCustomer();
    }

    ngOnInit(): void {
        this._getFrequentFacilities();
    }

    /**
     * @method payToFacility()
     * @param (facility: any)
     */

    payToFacility(facility: any): void {
        this._payToFacilityService.payToFacility(facility);
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method _getFrequentFacilities()
     * @description Get al the frequent facilities of the user
     */

    private _getFrequentFacilities(): void {
        const numberOfElements: number = 6;
        this._ngxSpinnerService.show();
        this._restService
            .get(`${this._facilitiesUrl}/getMostFrequentFacilities/${numberOfElements}`, {})
            .then((result: any): void => {
                this.facilities = result.slice(0, numberOfElements).map((item: any) => {
                    return Object.assign(item, { address: this._getAddress(item) });
                });
            })
            .finally((): void => {
                this._homeService.setFacilities(!this.facilities.length);
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _getAddress()
     * @param (facility: any)
     * @description
     */

    private _getAddress(facility: any): string {
        let address: string = facility.address ? facility.address : '';
        address += !facility.city ? '' : address ? ', ' + facility.city : facility.city;
        address += !facility.state ? '' : address ? ', ' + facility.state : facility.state;
        address += !facility.zip ? '' : address ? ', ' + facility.zip : facility.zip;
        address = address ? address : 'Address not available';
        return address;
    }
}
