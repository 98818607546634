<!--START: CARD SPRINTPAY CREDIT-->
<div class="card border-2 h-100 p-2 d-flex justify-content-center align-items-start">
    <ng-container *ngIf="!isLoading">
        <div class="row h-100">
            <div class="col-md-12 col-lg-8 my-0 my-lg-4">
                <div class="row">
                    <div class="col-12 ms-2 pe-3">
                        <img
                            class="mt-3 mb-3 mx-0 p-0 d-block h-auto"
                            src="assets/images/sprintpay-horizontal-logo.svg"
                            alt="SprintPay logo"
                        />
                    </div>
                </div>
                <div class="row">
                    <!--START: CARD SPRINTPAY CREDIT AVAILABLE-->
                    <div class="col-md-12 col-lg-6 px-4 information">
                        <span class="d-block fw-bold title text-muted">Credit Available</span>
                        <span class="d-block subtitle text-nowrap">{{sPCredit.availableCredit || 0 | currency}}</span>
                    </div>
                    <!--END: CARD SPRINTPAY CREDIT AVAILABLE-->
                    <!--START: CARD SPRINTPAY CREDIT LIMIT-->
                    <div class="col-md-12 col-lg-6 px-4 information">
                        <span class="d-block fw-bold title text-muted">Total credit</span>
                        <span class="d-block subtitle text-nowrap">{{sPCredit.creditLimit || 0 | currency}}</span>
                    </div>
                    <!--END: CARD SPRINTPAY CREDIT LIMIT-->
                </div>
            </div>
            <!--START: CIRCLE PROGRESS FOR NORMAL SCREEN-->
            <div class="col-md-0 col-lg-4 d-none justify-content-center d-lg-flex align-items-center">
                <div class="spinner-container">
                    <div
                        class="spinner-background position-absolute overflow-hidden d-flex align-items-center justify-content-center"
                    >
                        <div *ngIf="sPCreditPercentage; else noCredit">
                            <strong>{{sPCreditPercentage}}%</strong>
                        </div>
                        <ng-template #noCredit>
                            <i class="bi bi-exclamation-triangle exclamation-triangle-warm exclamation-icon-large"></i>
                        </ng-template>
                    </div>
                    <mat-progress-spinner
                        diameter="120"
                        strokeWidth="15"
                        mode="determinate"
                        [value]="sPCreditPercentage"
                    ></mat-progress-spinner>
                </div>
            </div>
            <!--END: CIRCLE PROGRESS FOR NORMAL SCREEN-->
        </div>
        <!--START: BAR PROGRESS FOR SMAALL SCREEN-->
        <div class="d-flex d-lg-none w-100 p-3 justify-content-center">
            <div class="progress position-relative">
                <div
                    class="progress-bar"
                    role="progressbar"
                    [ngStyle]="{'width': sPCreditPercentage+ '%'}"
                    [attr.aria-valuenow]="sPCreditPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                >
                    {{sPCreditPercentage}}%
                </div>
            </div>
        </div>
        <!--END: BAR PROGRESS FOR SMALL SCREEN-->
    </ng-container>

    <!--START: LOADING SPINNER-->
    <mat-spinner *ngIf="isLoading" class="m-4" [color]="'primary'" [diameter]="60" [strokeWidth]="6"> </mat-spinner>
    <!--END: LOADING SPINNER-->
</div>
<!--END: CARD SPRINTPAY CREDIT-->
