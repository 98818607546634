import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { environment } from 'src/environments/environment';
import { UserProperties } from './user';

export class EmotransUser implements UserProperties {
    constructor(
        public dynamicFields: DynamicField[] = [],
        public dynamicSections: DynamicSections[] = []
    ) {
        const paymentURL = environment.uris.method.payments;
        const facilityURL = environment.uris.method.facilities;
        this.dynamicSections = [
            {
                id: 1,
                label: 'Emotrans Customer Reference',
                orderNumber: 0,
                company: { id: 57, name: 'Emotrans' },
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Payment details',
                orderNumber: 1,
                company: { id: 57, name: 'Emotrans' },
                emailDomain: null,
            },
            {
                id: 3,
                label: 'Customer Reference',
                orderNumber: 2,
                company: { id: 57, name: 'Emotrans' },
                emailDomain: null,
            },
        ];
        this.dynamicFields = [
            {
                name: 'paymentAmount',
                label: 'Payment Amount',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                name: 'locationId',
                label: 'Location',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${paymentURL}/customer/approval-flow/information`,
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                name: 'type',
                label: 'Type',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${facilityURL}/facilities/getFacilityId/details/`,
                dependencyFieldName: 'getFacilityId',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
        ];
    }
}
