import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, forkJoin, from, map, of, switchMap, take, takeUntil } from 'rxjs';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { ColumnsFormat, DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { SessionService } from 'src/app/services/utils/session.service';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { Table } from '../../../../models/ui/table.model';
import { PaymentService } from '../../../../services/payment.service';
import { RestService } from '../../../../services/rest.service';
import { SummaryValidationService } from '../../../../services/summary-validation.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { PaymentFluxService } from '../../../../services/utils/payment-flux.service';
import { SecurityService } from '../../../../services/utils/security.service';
import { CustomerService } from '../../../../services/utils/user/customer-handler.service';
import { paginatorSize, profileComponents } from '../../../../utils/constants';
import { Sections } from '../../../../utils/sections';

@Component({
    selector: 'app-pending-approval',
    templateUrl: './pending-approval.component.html',
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
    noInformation: boolean | undefined;
    noInformationMessage: string;
    amTable!: Table[];
    pendingPayments: any;
    userPermissionsSubmit: Array<string>;
    userPermissionsCart: Array<string>;
    sortableColumns: string[];
    cannotAddRequestOfTableToCart: boolean;

    readonly companyName: string;
    readonly startingLevel: boolean;
    private _totalElements: number;
    private _dynamicColumnsName: string[];
    private _dynamicColumns: string[];
    private _dynamicFields: any;
    private _recordsSelected: [];
    private readonly _cartUrl: string;
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;
    private dynamicFileFields: { id: number; name: string }[];
    private unsubscribe$: Subject<void>;

    constructor(
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService,
        private _router: Router,
        private _errorHandlerService: ErrorHandlerService,
        private _breadcrumbService: BreadcrumbService,
        private _securityService: SecurityService,
        private _restService: RestService,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _summaryValidationService: SummaryValidationService,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService,
        private cartBillService: CartBillService,
        private initialConfigService: InitialConfigService
    ) {
        this._breadcrumbService.set('@pending-approval', 'Pending approval(s)');
        this._breadcrumbService.set('@payment-list', 'Payment list');
        this.dynamicFileFields = [];
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = {};
        this._paymentFluxService.removeCurrentPayment();
        this._paymentFluxService.removeDynamicFields();
        this._paymentFluxService.removeDynamicValues();
        this.startingLevel = !!this._sessionService.getElement('startingLevel');
        this.companyName = this._customerService.getCompanyName();
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this._cartUrl = environment.uris.method.cart;
        this.userPermissionsSubmit = [''];
        this.userPermissionsCart = [''];
        this._recordsSelected = [];
        this._totalElements = 0;
        this._typeSort = '';
        this._fieldToSort = '';
        this.sortableColumns = [];
        this._isSorting = false;
        this.cannotAddRequestOfTableToCart = false;
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.getAllFields();
        const userType: string = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.sendRequestofTableToApprover)) {
            this.userPermissionsSubmit.push(userType);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.addRequestofTableToCart)) {
            this.userPermissionsCart.push(userType);
        }
        this.getPendingApprovals();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method getPendingApprovals()
     * @param (item?: any)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getPendingApprovals(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const shortUrl: string = this._cartUrl + '/payment-request/pending-approvals';

        let longUrl: string = `${shortUrl}/?page=${currentSize}&size=${pageSize}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }
        longUrl = `${longUrl}&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getCompleteData(url: string, type?: string): void {
        this._restService
            .get(url, {})
            .then((result: any): void => {
                let companyNameColumns: any = {};
                let companyNameColumnsName: any = {};
                const dynamicCompany: boolean = this._customerService.isDynamicCompany();
                Object.keys(Sections.pendingApprovalSection).forEach((item: any, index: any): void => {
                    if (
                        item === `${this.companyName}_ColumnsPendingApproval` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsPendingApproval')
                    ) {
                        companyNameColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumns) {
                            this._dynamicColumns.map((item: any): void => {
                                companyNameColumns.splice(7, 0, item);
                            });
                        }
                    } else if (
                        item === `${this.companyName}_ColumnsNamePendingApproval` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsNamePendingApproval')
                    ) {
                        companyNameColumnsName = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumnsName) {
                            this._dynamicColumnsName.map((item: any): void => {
                                companyNameColumnsName.splice(7, 0, item);
                            });
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (
                                    companyNameColumns.indexOf(companyNameColumns[i]) !==
                                    companyNameColumns.lastIndexOf(companyNameColumns[i])
                                ) {
                                    companyNameColumnsName[companyNameColumns.indexOf(companyNameColumns[i])] =
                                        companyNameColumnsName[companyNameColumns.lastIndexOf(companyNameColumns[i])];
                                }
                            }
                        }
                    } else if (
                        item === `${this.companyName}_getCompletedPaymentsPendingApproval` ||
                        (!!dynamicCompany && item === 'Dynamic_getCompletedPaymentsPendingApproval')
                    ) {
                        this.pendingPayments =
                            result.content.length > 0
                                ? result.content.map((elements: any) => {
                                      return Object.values(Sections.pendingApprovalSection)[index](elements);
                                  })
                                : [];
                        if (!!dynamicCompany && this._dynamicColumns) {
                            let encounteredIndices: any = {};
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (encounteredIndices[companyNameColumns[i]]) {
                                    companyNameColumns.splice(i, 1);
                                    companyNameColumnsName.splice(i, 1);
                                } else encounteredIndices[companyNameColumns[i]] = 1;
                            }
                            this.pendingPayments?.map((item: any): void => {
                                this._dynamicColumns.map((column: any): void => {
                                    column = column === 'facilityName' ? '' : column;
                                    column = column === 'awb' ? '' : column;
                                    column = column === 'hawb' ? '' : column;
                                    column = column === 'location' ? '' : column;
                                    column = column === 'customerRef' ? '' : column;
                                    column = column === 'amount' ? '' : column;
                                    column = column === 'approvalLevel' ? '' : column;
                                    column = column === 'nameFiles' ? '' : column;
                                    if (
                                        item.dynamicValues.filter(
                                            (values: any): boolean => values.dynamicField.name === column
                                        ).length === 0
                                    ) {
                                        item[column] = 'N/A';
                                    } else {
                                        item[column] = item.dynamicValues
                                            .filter((values: any): boolean => values.dynamicField.name === column)
                                            .map((item: any) => {
                                                const fileField: { id: number; name: string }[] =
                                                    this.dynamicFieldsService.verifyIfDynamicFieldisFile(
                                                        item.dynamicField.id,
                                                        this.dynamicFileFields
                                                    );
                                                if (fileField.length) {
                                                    const filePath = item.value.split('/');
                                                    item.value = filePath[filePath.length - 1];
                                                }
                                                return item.value !== '' &&
                                                    item.value !== null &&
                                                    item.value !== undefined
                                                    ? item.value
                                                    : 'N/A';
                                            });
                                    }
                                });
                            });
                        }
                    } else if (
                        item === `${this.companyName}_ColumnsSortablePendingApproval` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsSortablePendingApproval')
                    ) {
                        this.sortableColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalSection)[index])
                        );
                    }
                });
                if (
                    !this._securityService.verifyComponentsSecurity(profileComponents.addRequestofTableToCart) &&
                    !this._securityService.verifyComponentsSecurity(profileComponents.sendRequestofTableToApprover)
                ) {
                    companyNameColumns.splice(0, 1);
                    companyNameColumnsName.splice(0, 1);
                }

                this.noInformation = type === 'search' ? false : !(this.pendingPayments.length > 0);
                this._getPendingApprovalsTableRender(
                    this.pendingPayments,
                    companyNameColumns,
                    companyNameColumnsName,
                    result.totalElements
                );

                if (this._isSorting) {
                    this._isSorting = false;
                }
            })
            .catch((error: any): void => {
                if (this.pendingPayments !== undefined) {
                    this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                }
                if (this._isSorting) {
                    this._isSorting = false;
                } else {
                    this.noInformation = true;
                }
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method search()
     * @param (filter: any)
     * @description
     */

    search(filter: any): void {
        if (filter.value) {
            const pageSize: number =
                filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : paginatorSize[0];
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._cartUrl}/searchPaymentRequests/?searchTerms=${
                filter.value
            }&page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}&approvalStatuses=STARTED,APPROVED`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getPendingApprovals(filter);
        }
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getPendingApprovals();
    }

    /**
     * @method _getPendingApprovals()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getPendingApprovalsTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method _reviewSelectedRows()
     * @description
     */

    private _reviewSelectedRows(): any {
        if (this._recordsSelected.length > 0) {
            return this._recordsSelected.map((item: any) => {
                return item.id;
            });
        } else {
            Swal.fire({
                title: 'Oops....',
                text: 'Please select one or more request to submit',
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Close',
                allowOutsideClick: false,
            });
        }
    }

    /**
     * @method submitToApprover()
     * @description
     */

    submitToApprover(): void {
        const ids: any = this._reviewSelectedRows();
        if (ids) {
            this._ngxSpinnerService.show();
            this._restService
                .post(`${this._cartUrl}/approveMultiplePaymentRequests?id=${ids.join(',')}`, {})
                .then((): void => {
                    setTimeout((): void => {
                        this._ngxSpinnerService.hide();
                        if (this._totalElements == this._recordsSelected.length) {
                            this._router.navigate(['admin/home']);
                        } else {
                            window.location.reload();
                        }
                    }, 2000);
                })
                .catch((error: any): void => {
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                });
        }
    }

    /**
     * @method addToCart()
     * @description
     */

    addToCart(): void {
        this._summaryValidationService
            .addToCartValidate('paymentRequest')
            .pipe(
                take(1),
                switchMap((result) => {
                    if (result.continue) {
                        return of(result);
                    }

                    return from(
                        Swal.fire({
                            html: `<h5>Hey, psst psst...</h5><p>${result.message}</p>`,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                            reverseButtons: true,
                        })
                    ).pipe(
                        switchMap((response) => {
                            if (response.isConfirmed) {
                                return this.cartBillService.deleteAndGetCart().pipe(
                                    take(1),
                                    map(() => {
                                        this._postPendingApproval();
                                        return result;
                                    })
                                );
                            }

                            return of(result);
                        })
                    );
                })
            )
            .subscribe({
                next: (result): void => {
                    if (result.continue) {
                        this._postPendingApproval();
                    }
                },
            });
    }

    /**
     * @method _postPendingApproval()
     * @description Adds payment requests into the cart
     */

    private _postPendingApproval(): void {
        const ids = this._reviewSelectedRows();
        if (ids) {
            this._ngxSpinnerService.show();
            this._restService
                .post(`${this._cartUrl}/approveMultiplePaymentRequests?id=${ids.join(',')}`, {})
                .then((): void => {
                    this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1)).subscribe();
                    setTimeout((): void => {
                        this._ngxSpinnerService.hide();
                        this._router.navigate(['admin/cart']);
                    }, 2000);
                })
                .catch((error: any): void => {
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                });
        }
    }

    /**
     * @method itemsSelected()
     * @param (items: any)
     * @description
     */

    itemsSelected(items: any): void {
        let canNotAdd: boolean = false;
        this._recordsSelected = items;
        items.forEach((item: any) => {
            if (!item.approvalLevels) {
                canNotAdd = true;
                return;
            }
        });
        this.cannotAddRequestOfTableToCart = canNotAdd;
        items
            .map((item: any) => {
                return item.id;
            })
            .join(',');
    }

    /**
     * @method getAllFields()
     * @description
     */

    getAllFields(): void {
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = [];

        forkJoin([
            this.dynamicFieldsService.getDynamicColumns().pipe(take(1)),
            this.dynamicFieldsService.getDynamicFields$().pipe(take(1)),
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: ([dynamicColumns, fields]) => {
                    this._dynamicFields = fields;
                    this._dynamicColumns =
                        dynamicColumns.map((item: ColumnsFormat) => {
                            return item.column || '';
                        }) || [];
                    this._dynamicColumnsName =
                        dynamicColumns.map((item: ColumnsFormat) => {
                            return item.columnName || '';
                        }) || [];
                    this.dynamicFileFields = this.dynamicFieldsService.getFileFieldsDynamic();
                },
            });
    }
}
