import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';

export class User {
    constructor(
        public dynamicSections: DynamicSections[] = [
            {
                id: 1,
                label: 'Payment Details',
                orderNumber: 1,
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Customer Reference',
                orderNumber: 2,
                emailDomain: null,
            },
        ],
        public dynamicFields: DynamicField[] = []
    ) {}

    static fromJson({ dynamicFields, dynamicSections, config }: UserProperties): User {
        const newDynamicFields = this.changeDynamicFieldName(dynamicFields, config);
        return new User(dynamicSections, newDynamicFields);
    }

    static changeDynamicFieldName(dynamicFields: DynamicField[] | undefined, config: DynamicField[] | undefined) {
        if (!config?.length) {
            return dynamicFields;
        }

        return dynamicFields?.map((field) => {
            const newDynamicFields = config.find((fieldElement) => {
                if (fieldElement.name === field.name) {
                    return fieldElement;
                }
                return field;
            });
            return { ...field, ...newDynamicFields };
        });
    }
}

export interface UserProperties {
    dynamicFields?: DynamicField[];
    dynamicSections?: DynamicSections[];
    config?: DynamicField[];
}
