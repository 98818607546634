import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { PaymentToRequestEditService } from '../../../services/payment-to-request-edit.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../services/utils/user/customer-handler.service';

@Component({
    selector: 'app-dhl-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './dhl-customer-reference.component.html',
})
export class DhlCustomerReferenceComponent {
    customer: any;
    companyName: string;
    id: string;
    isStartingLevel: boolean;

    @Input() paymentDetail: any;

    constructor(
        private _customerService: CustomerService,
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _router: Router,
        private _paymentFluxService: PaymentFluxService
    ) {
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this._paymentFluxService.getCurrentPayment() !== undefined
                  ? this._paymentFluxService.getCurrentPayment()?.id
                  : '';
        this.customer = this._customerService.getCustomer();
        this.companyName = this.customer.approvalLevels ? this.customer.approvalLevels.company.name : null;
        this.isStartingLevel = this._customerService.getStartingLevel();
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest() {
        this._paymentToRequestEditService.getPaymentRequestDataCart(this.paymentDetail, this._router.url);
    }
}
