import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take, throwError } from 'rxjs';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Sections } from 'src/app/utils/sections';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../../environments/environment';
import { PaymentToRequestEditService } from '../../../../../services/payment-to-request-edit.service';
import { RestService } from '../../../../../services/rest.service';
import { CustomerService } from '../../../../../services/utils/user/customer-handler.service';
import { companyName } from '../../../../../utils/constants';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, OnDestroy {
    id: string;
    paymentDetails: any[] = [];
    paymentLogs: any;
    noInformation: boolean | undefined;
    noInformationMessage: string;
    paymentDetailFinish: boolean;
    paymentDetailsSection: any;
    paymentCustomerReference: any;
    customerInformation: any;
    private dynamicFieldsReplaced: any;
    private companyName: string;
    readonly companyNames: any;
    private readonly _cartUrl: string;
    private readonly _paymentsURL: string;
    public files: { names?: string[]; urls: string[] }[] = [];

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _activatedRoute: ActivatedRoute,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _matDialog: MatDialog,
        private _customerService: CustomerService,
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this._breadcrumbService.set('@payment-history', 'Payment history');
        this._breadcrumbService.set('@payment-detail', 'Payment detail');
        this.id = '';
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this.paymentDetailFinish = false;
        this._cartUrl = environment.uris.method.cart;
        this._paymentsURL = environment.uris.method.payments;
        this.companyNames = companyName;
        this.companyName = this._customerService.getCompanyName();
        this.getAllFieldsWithReplacement();
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: any): void => {
                this.id = params['id'];
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
        this._getPaymentDetail();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method _getPaymentDetail()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentDetail(): void {
        const url: string = `${this._paymentsURL}/payment/detail/${this.id}`;
        const companyType: string = this._customerService.getCompanyName();
        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                result.forEach((payment: any) => {
                    const paymentData = this._paymentToRequestEditService.getPaymentRequestDataCart(
                        payment,
                        '/admin/facilityPayments/paymentHistory/paymentDetail/' + this.id,
                        companyType.toString()
                    );

                    (paymentData.companyType = companyType.toString()), (paymentData.awb = payment['awb']);
                    paymentData.id = payment['id'];
                    paymentData.paidTo = payment['paidTo'];
                    paymentData.amount = Number(payment['amount']);
                    paymentData.customerReference = payment['customerReference'];
                    paymentData.notificationEmail = payment['notificationEmail'];
                    paymentData.notes = payment['notes'];
                    paymentData.externalData = payment['externalData'];
                    paymentData.paymentType = payment['paymentType'];
                    paymentData.approvalFlowLevel = payment.approvalFlowLevel
                        ? payment['approvalFlowLevel']['approvalFlow']['location']['name']
                        : '';
                    paymentData.costCenter = payment.externalData?.['costCenter'];
                    paymentData.voucher = payment.externalData?.['voucher'];
                    paymentData.externalDataType = payment.externalData?.['type'];
                    paymentData.payType = payment['paymentType'];
                    paymentData.sourceSystem = payment.externalData?.['sourceSystem'];
                    paymentData.product = payment.externalData?.['product'];
                    paymentData.station = payment.externalData?.['station'];
                    paymentData.location = payment['location'];
                    paymentData.externalReferenceNum = payment['externalReferenceNum'];
                    paymentData.facility = payment['facility'];
                    paymentData.hawb = payment['hawb'];
                    paymentData.name = payment['name'];
                    paymentData.createdDate = payment['createdDate'];
                    paymentData.vendorInvoiceNumber = payment['vendorInvoiceNumber'];
                    paymentData.customer = payment['customer'];
                    this.paymentDetails.push(paymentData);
                    const attachments = payment?.attachments ? payment.attachments.split(',') : '';
                    let attachmentsNames: string[] = [];
                    payment?.attachments
                        ? payment.attachments.split(',').map((item: any): void => {
                              attachmentsNames.push(item.split('/')[5]);
                          })
                        : '';
                    this.files.push({ names: attachmentsNames, urls: attachments });
                });

                this._getPaymentLogs(this.paymentDetails[0].id);
                this.noInformation = false;
                this.getPaymentDetailsSection();
                this.getPaymentCustomerReferenceSection();
                this.getCustomerInformationSection();
            })
            .catch((error: any): void => {
                this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                this.noInformation = true;
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _getPaymentLogs()
     * @description Get all the logs by payment
     */
    private _getPaymentLogs(id: string): void {
        if (this.companyName !== '' && this.companyName) {
            const url: string = `${this._cartUrl}/getLogHistoryByPayment/${id}?page=0&size=30&date=${new Date().valueOf()}`;
            this._ngxSpinnerService.show();
            this._restService
                .get(url, {})
                .then((result: any): void => {
                    this.paymentLogs = result.content.map((item: any) => {
                        return {
                            approval: `${item.approvalLevel}: ${item.firstName} ${item.lastName}`,
                            date: moment(item.date).format('MMM DD, YYYY, h:mm:ss a'),
                            event: item.event,
                            reason: item.reason,
                        };
                    });
                    this._ngxSpinnerService.hide();
                })
                .catch((): void => {
                    this._ngxSpinnerService.hide();
                })
                .finally((): void => {
                    this.paymentDetailFinish = true;
                });
        } else {
            this._ngxSpinnerService.hide();
            this.paymentDetailFinish = true;
        }
    }

    /**
     * @method openDialog()
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'timeline',
            disableClose: true,
        });
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        if (this.companyName && Object.keys(this.companyNames).indexOf(this.companyName.toLowerCase()) === -1) {
            this.dynamicFieldsReplaced = [];
            this.dynamicFieldsService
                .getFieldsReplaced()
                .pipe(take(1))
                .subscribe({
                    next: (fieldsReplaced) => {
                        this.dynamicFieldsReplaced = fieldsReplaced;
                        this.getPaymentCustomerReferenceSection();
                    },
                });
        }
    }

    getPaymentDetailsSection(): void {
        this.paymentDetailsSection =
            this.paymentDetails.length > 0
                ? this.paymentDetails.map((element: any) => {
                      return Sections.getDetailsOfPaymentDetailsHistorySection(element, this.companyName);
                  })
                : [];
    }

    getPaymentCustomerReferenceSection(): void {
        const customerReferenceLabel = this.dynamicFieldsReplaced?.find((item) => item.name === 'customerRef');
        const customer = this._customerService.getCustomer();
        this.paymentCustomerReference =
            this.paymentDetails.length > 0
                ? this.paymentDetails.map((element: any) => {
                      return Sections.getDetailsOfCustomerReferenceHistorySection(
                          element,
                          customer,
                          customerReferenceLabel?.label || '',
                          this.companyName
                      );
                  })
                : [];
    }

    getCustomerInformationSection(): void {
        const payment = this.paymentDetails?.find((element: any) => element.notificationEmail);
        this.customerInformation = Sections.getCustomerInformationHistorySection(payment);
    }
}
