import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';
import { sprintPaySource } from 'src/app/utils/constants';

@Component({
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    selector: 'app-redirect-sso-email',
    templateUrl: './redirect-sso-email.component.html',
    styleUrls: ['./redirect-sso-email.component.scss'],
})
export class RedirectSsoEmailComponent implements OnInit, OnDestroy {
    public timer: NodeJS.Timeout | undefined;
    public countdownValue: number = 5;
    private loginSSOTarget = '_self';

    constructor(private _domSanitizer: DomSanitizer) {}

    @Input() redirectUrl: string = '';
    @Output() redirectCompleted: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        this.countdown();
    }

    ngOnDestroy(): void {
        this.cancelCountdown();
    }

    countdown(): void {
        this.timer = setInterval(() => {
            this.countdownValue--;
            if (this.countdownValue === 0) {
                this.redirectCompleted.emit();
                this.redirectNow();
            }
        }, 1000);
    }

    cancelCountdown(): void {
        if (this.timer) {
            this.redirectCompleted.emit();
            clearInterval(this.timer);
        }
    }

    redirect(): void {
        const url: string | null = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl(`${this.redirectUrl}?source=${sprintPaySource}` || '')
        );

        window.open(url || '', this.loginSSOTarget);
    }

    redirectNow(): void {
        this.cancelCountdown();
        this.redirect();
    }
}
