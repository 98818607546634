import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FileHandlerService {
    private _filesPath: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    get instant_files(): string[] {
        return this._filesPath.value;
    }

    setPathFiles(files: string[]): void {
        this._filesPath.next(files);
    }

    getPathFiles(): Observable<string[]> {
        return this._filesPath.asObservable();
    }

    getNameFiles(filesPath: string): string[] {
        if (!filesPath) {
            return [];
        }
        return filesPath.split(',').map((files) => {
            const arrayPath = files.split('/');
            return arrayPath[arrayPath.length - 1];
        });
    }
}
