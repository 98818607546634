import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicField } from '@cargos/sprintpay-models';
import { DynamicCheckboxesComponent } from './dynamic-checkboxes/dynamic-checkboxes.component';
import { DynamicFileComponent } from './dynamic-file/dynamic-file.component';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { DynamicRadioComponent } from './dynamic-radio/dynamic-radio.component';
import { DynamicSelectComponent } from './dynamic-select/dynamic-select.component';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-field-input',
    templateUrl: './dynamic-field.component.html',
    styleUrls: ['./dynamic-field.component.scss'],
})
export class DynamicFieldComponent implements AfterViewInit {
    public formName: FormGroup;
    public supportedDynamicComponents = [
        {
            name: 'input',
            component: DynamicInputComponent,
        },
        {
            name: 'number',
            component: DynamicInputComponent,
        },
        {
            name: 'dropdown',
            component: DynamicSelectComponent,
        },
        {
            name: 'radioButton',
            component: DynamicRadioComponent,
        },
        {
            name: 'date',
            component: DynamicInputComponent,
        },
        {
            name: 'checkbox',
            component: DynamicCheckboxesComponent,
        },
        {
            name: 'file',
            component: DynamicFileComponent,
        },
    ];

    @ViewChild('dynamicInputContainer', { read: ViewContainerRef })
    dynamicInputContainer!: ViewContainerRef;
    @Input() field: DynamicField | null = null;

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.registerDynamicField();
        }, 0);
    }

    registerDynamicField(): void {
        this.dynamicInputContainer.clear();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const componentInstance: any = this.getComponentByType(this.field?.fieldType || '');
        const dynamicComponent = this.dynamicInputContainer.createComponent(componentInstance);
        dynamicComponent.setInput('field', this.field);
    }

    getComponentByType(type: string) {
        const componentDynamic = this.supportedDynamicComponents.find((c) => c.name === type);

        return componentDynamic?.component || DynamicInputComponent;
    }
}
