import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { RefundStatusHistory } from 'src/app/models/refunds/refund-status-history';
import { PaymentRefund, UpdatePaymentRefund } from 'src/app/models/refunds/refunds';
import { Filters } from 'src/app/models/ui/table.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';
import { CustomerService } from '../utils/user/customer-handler.service';

@Injectable()
export class RefundAPIService {
    private _paymentsURL: string;
    private cancelPendingRequests$ = new Subject<void>();
    private profileType: string;

    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private customerService: CustomerService
    ) {
        this._paymentsURL = environment.uris.method.payments;
        this.profileType = this.customerService.getActiveProfileView();
    }

    getPaymentRefund(id: number): Observable<PaymentRefund> {
        const url = `${this._paymentsURL}/payment/refund/${id}?activeProfileView=${this.profileType}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);
        return this.httpClient
            .get(url, { headers })
            .pipe(map((paymentRefund: unknown) => paymentRefund as PaymentRefund));
    }

    updatePaymentRefund(body: UpdatePaymentRefund, profileType: string): Observable<PaymentRefund> {
        const url = `${this._paymentsURL}/payment/refund/updatePaymentRefund?activeProfileView=${profileType}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient
            .post(url, body, { headers })
            .pipe(map((paymentRefund: unknown) => paymentRefund as PaymentRefund));
    }

    getRefundRequestsPayments(url: string): Observable<any> {
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);
        return this.httpClient.get(url, { headers });
    }

    getforwarderPaymentsRefunds(filters: Filters = {}): Observable<any> {
        const activeProfileView = this.customerService.getActiveProfileView();
        const url =
            `${this._paymentsURL}/payment/refund` +
            (filters.searchQuery ? '/search' : '') +
            `?activeProfileView=${activeProfileView}`;
        let params: HttpParams = new HttpParams();
        let headers = new HttpHeaders();
        this.cancelPendingRequests$.next();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this.httpClient.get(url, { params, headers }).pipe(takeUntil(this.cancelPendingRequests$));
    }

    /**
     * Download Refund invoices for forwarders-admin
     * @param filters
     * @returns
     */
    downloadRefundInvoices(filters: Filters): Observable<string> {
        // const url = `${this._paymentsURL}/payment/refund/download?activeProfileView=${this.profileType}`;

        const url = `${this._paymentsURL}/forwarders-admin/payment-refunds/download`;
        let params: HttpParams = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            if (value !== null && value !== '' && value !== undefined) {
                params = params.append(key, value);
            }
        });

        return this.httpClient.get(url, { headers, params, responseType: 'text' });
    }

    getPaymentRefundHistory(id: number): Observable<RefundStatusHistory> {
        const url = `${this._paymentsURL}/payment/refund/status-history/${id}?activeProfileView=${this.profileType}`;
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);
        return this.httpClient.get(url, { headers });
    }

    downloadRefundInvoicesByIds(
        ids: string,
        downloadFormat: string = 'XLSX',
        isFacility: boolean = false
    ): Observable<Blob> {
        const url = `${this._paymentsURL}/payment/refund/download?paymentRefundsIds=${ids}&downloadFormat=${downloadFormat}&filterByFacility=${isFacility}`;
        let params: HttpParams = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, {}, { headers, params, responseType: 'blob' });
    }

    sendPaymentRefund(data: PaymentRefund, activeProfileView: string): Observable<string> {
        const url = `${this._paymentsURL}/payment/refund/?activeProfileView=${activeProfileView}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, data, { headers }).pipe(map(() => 'COMPLETE'));
    }
}
