export const appVersion = '1.0.1';

export const sentryConfig = {
    appVersion,
    enableSentry: false,
    appSentryDsn: 'https://95685704388544047aeec27177f0aa4a@o4507493303582720.ingest.us.sentry.io/4507931296792576',
};

export const statsig = {
    sdkKey: 'client-gT9U4gWIK5sHAIe3HBpfA2wLlEPOzpM1yUqsQLOIqFR',
};

export const commonEnv = {
    ...sentryConfig,
    ...statsig,
};
