import { Injectable } from '@angular/core';
import { Customer, DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { PaymentReference } from '@cargos/sprintpay-models/dist/src/customer/customer';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { User } from '../models/customer';
import { CustomerService } from './utils/user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class HandlerdFeaturesService {
    private user = new BehaviorSubject<User | null>(null);

    constructor(private customerService: CustomerService) {}

    getPaymentReferenceWithinApprovalLevels(): PaymentReference[] {
        const customer: Customer | null = this.customerService.getCustomer();
        return (
            customer?.approvalLevels?.company?.paymentReference?.filter((reference) => reference.name === 'Type') || []
        );
    }

    getPaymentAmount(): string[] {
        const paymentRerefence = this.getPaymentReferenceWithinApprovalLevels();
        return (
            paymentRerefence[0]?.paymentReferenceLookups?.map(
                (paymentReferenceLookup) => paymentReferenceLookup?.type || ''
            ) || []
        );
    }

    isLocationAssigned(): boolean {
        const customer: Customer | null = this.customerService.getCustomer();
        return !!customer?.approvalLevels?.company?.firstLocation;
    }

    setUser(user: User): void {
        this.user.next(user);
    }

    private getUser$(): Observable<User | null> {
        return this.user.asObservable();
    }

    get instant_customer_fields(): DynamicField[] {
        return this.user?.value?.dynamicFields || [];
    }

    getCustomerFields(): Observable<DynamicField[]> {
        return this.getUser$().pipe(
            map((user) => {
                return (
                    user?.dynamicFields?.filter((field) => {
                        const isUserDomain = this.customerService.isCompanyByDomain();

                        if (isUserDomain) {
                            return !!field.emailDomain;
                        }

                        return true;
                    }) || []
                );
            })
        );
    }

    getCustomerSections(): Observable<DynamicSections[]> {
        return this.getUser$().pipe(
            map((user) => {
                return user?.dynamicSections || [];
            }),

            map((dynamicSections) => {
                const company = this.customerService.getCompanyName();
                return dynamicSections.map((element) => {
                    if (element.orderNumber === 0 && company) {
                        element.label = element?.label?.replace('Company', company);
                    }
                    return element;
                });
            })
        );
    }

    getFirstLocationId(): number | null {
        const customer = this.customerService.getCustomer();
        return customer?.approvalLevels?.company?.firstLocation?.id || null;
    }

    hasGlCodes(): boolean {
        return !!this.customerService.getCustomer()?.hasGlCodes;
    }
}
