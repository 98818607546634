<!--START: Account type individual -->
<section id="account">
    <div class="backButton">
        <button class="button-container mat-subtitle-2" (click)="goBack()" mat-button [color]="'primary'">
            <mat-icon class="md-icon-24 m-0">arrow_back_ios</mat-icon>
            &nbsp; BACK
        </button>
    </div>
    <mat-card
        class="account__container flex-column min-vh-100 justify-content-center align-items-center px-3 px-sm-5 py-5 col-lg-8 col-12"
    >
        <div class="container">
            <div class="row">
                <!--START: Sprintpay or facility logo -->
                <div class="col-12">
                    <app-form-header
                        [facilityLogo]="facilityLogo"
                        [facility]="facility"
                        title="Join SprintPay"
                    ></app-form-header>
                </div>
                <!--END: Sprintpay or facility logo -->
                <!--START: Stepper -->
                <div class="col-12 col-lg-9 mx-auto">
                    <mat-stepper
                        [linear]="true"
                        labelPosition="bottom"
                        #stepper
                        [orientation]="(stepperOrientation | async)!"
                    >
                        <!--START: Step one -->
                        <mat-step
                            [editable]="availableEdition"
                            errorMessage="Personal information is needed"
                            [stepControl]="personalForm"
                        >
                            <ng-template matStepLabel>Personal Information</ng-template>
                            <app-step-one
                                #stepOne
                                class="mt-4 d-block"
                                [stepper]="stepper"
                                [formGroup]="$any(personalForm)"
                                [facility]="facility"
                                [isMFANeeded]="isMfaNeeded"
                            ></app-step-one>
                        </mat-step>
                        <!--END: Step one -->
                        <!--START: Step two -->
                        <mat-step
                            [editable]="availableEdition"
                            errorMessage="Account information is needed"
                            [stepControl]="accountForm"
                        >
                            <ng-template matStepLabel>Account Information</ng-template>
                            <app-step-two
                                class="mt-4 d-block"
                                [accountForm]="accountForm"
                                [stepper]="stepper"
                                [isGuestUpgradeFlow]="isValidGuestUpgrade"
                            ></app-step-two>
                        </mat-step>
                        <!--END: Step two -->
                        <!--START: Step three -->
                        <mat-step [editable]="availableEdition" errorMessage="Security information is needed">
                            <ng-template matStepLabel>Security</ng-template>
                            <app-step-three
                                [notCompleted]="availableEdition"
                                class="mt-4 d-block"
                                (eventOnSubmit)="onSubmit($event)"
                            ></app-step-three>
                        </mat-step>
                        <!--END: Step three -->
                    </mat-stepper>
                </div>
                <!--END: Stepper -->
                <!--START: Existing account button -->
                <div class="col-12">
                    <div class="text-center mb-4 mt-3 fw-normal">
                        <span class="existing__account">Do you already have an account?</span><span>&nbsp;&nbsp;</span>
                        <button class="btn__sprintpay--link" (click)="redirectToLogin()">Sign in</button>
                    </div>
                </div>
                <!--END: Existing account button -->
            </div>
        </div>
    </mat-card>
</section>
<ng-template #twoFactorModal>
    <app-two-factor (accountCreated)="modalAccountVerified($event)"></app-two-factor>
</ng-template>
<!--END: Account type individual -->
<!--START: NGX SPINNER -->
<app-loader></app-loader>
<!--END: NGX SPINNER -->
