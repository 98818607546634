<form [formGroup]="formName" class="d-flex w-100 flex-column">
    @if (field?.name && field?.options) {
    <label [for]="field?.name" class="mb-3">{{field?.label}}</label>
    <mat-radio-group [formControlName]="field?.name || ''" [matTooltip]="field?.tooltipMessage || ''">
        @for (option of field?.options; track $index) {
        <mat-radio-button
            [value]="option.value"
            color="primary"
            [name]="field?.name || ''"
            class="me-3"
            [disabled]="field?.isDisabled"
        >
            {{option.value}}
        </mat-radio-button>
        }
    </mat-radio-group>
    }
</form>
