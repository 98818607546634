import { Injectable, effect } from '@angular/core';
import {
    Card,
    Echeck,
    PaymentMethod,
    PaymentMethods,
    PaymentMethodsEvents,
    PaymentMethodsType,
} from '@cargos/sprintpay-models';
import { LoggerErrorService } from '@cargos/sprintpay-services';
import dayjs from 'dayjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import {
    BehaviorSubject,
    Observable,
    catchError,
    combineLatest,
    distinctUntilChanged,
    map,
    mergeMap,
    of,
    startWith,
    switchMap,
    take,
    throwError,
} from 'rxjs';
import Swal from 'sweetalert2';
import { NotificationsType, TypeNotificationType } from '../models/notifications/notifications';
import { PaymentMethodsLatest } from '../models/payments/payment-methods';
import { BannerService } from '../standalone-components/banner/services/banner.service';
import { PaymentMethodSelected } from '../utils/cart-types';
import { profileTypes } from '../utils/constants';
import { CustomerFeaturesService } from './features/features.service';
import { PaymentMethodsService } from './payment-methods.service';
import { PaymentMethodsAPIService } from './requests';
import { SummaryService } from './summary/summary.service';
import { SwalService } from './swal.service';
import { UrlService } from './url.service';
import { CartBillService } from './utils/cart/cart-service';
import { SecurityService } from './utils/security.service';
import { StorageService } from './utils/storage.service';
import { CustomerService } from './utils/user/customer-handler.service';
import { UserSessionService } from './utils/user/user-session.service';

interface EventPaymentMethod {
    item: Card | Echeck | undefined;
    method: PaymentMethodsType | null;
}

@Injectable({
    providedIn: 'root',
})
export class InitialConfigService {
    private settingDefaultPaymentMethod = new BehaviorSubject<boolean>(false);

    constructor(
        private userSessionService: UserSessionService,
        private customerService: CustomerService,
        private securityService: SecurityService,
        private customerFeaturesService: CustomerFeaturesService,
        private bannerService: BannerService,
        private ngxRolesService: NgxRolesService,
        private ngxPermissionsService: NgxPermissionsService,
        private storageService: StorageService,
        private paymentMethodsService: PaymentMethodsService,
        private urlService: UrlService,
        private cartBillService: CartBillService,
        private paymentMethodsAPIService: PaymentMethodsAPIService,
        private summaryService: SummaryService,
        private loggerErrorService: LoggerErrorService,
        private swalService: SwalService
    ) {
        effect(() => {
            this.displaySwal();
        });
    }

    setRolesAndPermissions(): Observable<string> {
        return this.customerService.getActiveProfileView$().pipe(
            map((profile: string) => {
                this.handleRolesAndPermissions(profile);

                return 'Complete';
            })
        );
    }

    getCustomerNotifications(): Observable<string> {
        return this.userSessionService.isAuthenticatedAndIsAValidToken$().pipe(
            take(1),
            switchMap((isAuthenticated) => {
                if (isAuthenticated) {
                    return this.customerFeaturesService.getCustomerNotifications().pipe(
                        switchMap((notifications) => {
                            return this.customerService.getActiveProfileView$().pipe(
                                map((activeProfileView) => {
                                    const alerts = this.getAlertsAllowedToBeDisplayed(notifications);
                                    const banners = notifications.filter((notification) => {
                                        return (
                                            notification.type === TypeNotificationType.banner &&
                                            this.isAllowedToBeDisplayInTheBanner(notification, activeProfileView)
                                        );
                                    });
                                    this.bannerService.setNotifications(banners || []);

                                    if (alerts?.length) {
                                        const listAlerts = this.getListItemsForModal(alerts);

                                        this.swalService.addSwal({
                                            type: 'notification',
                                            title: 'Alert!',
                                            html: listAlerts,
                                            icon: 'info',
                                            showConfirmButton: true,
                                            confirmButtonText: 'Continue',
                                            confirmButtonColor: '#14bb9c',
                                            allowOutsideClick: false,
                                        });
                                    }

                                    return 'Complete';
                                })
                            );
                        })
                    );
                }

                return of('Complete');
            })
        );
    }

    getPaymentMethodSelectedAndGetCart$(): Observable<string> {
        return combineLatest([
            this.customerFeaturesService.isPaymentMethodValid$(),
            this.cartBillService.getNonReservationFee$(),
        ]).pipe(
            switchMap(([isPaymentMethodValid, additionalFee]) => {
                if (!isPaymentMethodValid) {
                    return this.cartBillService.getCartRequestAndSetCart().pipe(
                        take(1),
                        map(() => 'Complete')
                    );
                }

                return this.summaryService.getPaymentMethodsSelectedName$().pipe(
                    switchMap((paymentMethodsSelected) => {
                        const { paymentMethodSelected, paymentMethodSelectedAboveThreshold } = paymentMethodsSelected;

                        return this.cartBillService
                            .getCartRequestAndSetCart(
                                paymentMethodSelected,
                                paymentMethodSelectedAboveThreshold,
                                additionalFee
                            )
                            .pipe(
                                take(1),
                                map(() => 'Complete')
                            );
                    })
                );
            })
        );
    }

    // TODO: Consider refactoring this logic into a dedicated notification service for better separation of concerns
    private isAllowedToBeDisplayInTheBanner(notification: NotificationsType, activeProfileView: string): boolean {
        if (!notification.message.toLowerCase().includes('alliance ground')) {
            return true;
        }

        return activeProfileView === profileTypes.FORWARDER;
    }

    private getAlertsAllowedToBeDisplayed(notifications: NotificationsType[]): NotificationsType[] {
        const commonAlerts = notifications.filter(
            (notification) => notification.type === TypeNotificationType.alert && notification.name !== 'VIN'
        );

        const vinAlert = notifications.filter(
            (notification) => notification.type === TypeNotificationType.alert && notification.name === 'VIN'
        );

        if (vinAlert?.length && this.isAllowedToDisplayVinMessage()) {
            const currentCount = this.getCountVinMessageDisplayed() + 1;
            this.storageService.saveElement('displayMessage', currentCount.toString());

            return [...commonAlerts, ...vinAlert];
        }

        return commonAlerts;
    }

    private isAllowedToDisplayVinMessage(): boolean {
        const count = this.getCountVinMessageDisplayed();

        return Number(count) < 2;
    }

    private getCountVinMessageDisplayed(): number {
        const count = this.storageService.getElement('displayMessage') || 0;

        return Number(count);
    }

    /**
     * @method handleRolesAndPermissions()
     * @description Handles the logic for the security based on user and roles
     */
    private handleRolesAndPermissions(profile: string): void {
        const type =
            profile === profileTypes.FORWARDER || profile === ''
                ? this.securityService.getUserType()
                : this.securityService.getProfileType();
        const customer = this.customerService.getCustomer();
        const permissionsComponents: Array<string> = this.assignPermissionsComponentsToRole(customer, profile);
        this.ngxRolesService.flushRoles();
        this.ngxPermissionsService.flushPermissions();
        this.ngxPermissionsService.addPermission(type);
        const customerType = this.customerService.getCustomerType();
        if (customerType) {
            this.ngxPermissionsService.loadPermissions([customerType]);
        }
        this.ngxRolesService.addRole(type, permissionsComponents);
        this.ngxPermissionsService.addPermission(permissionsComponents);
    }

    /**
     * @method assignPermissionsToRole()
     * @description Assigns the appropriate permissions to the role based on the customer and profile.
     */
    private assignPermissionsComponentsToRole(customer: any, profile: string): string[] {
        let components: Array<string>;
        if (profile === profileTypes.FORWARDER || profile === '') {
            components = this.securityService.getComponentsByUser();
            if (customer && !customer.isGuest) {
                components.push('home');
                components.push('refunds-requests');
                components.push('facility-contacts');
                components.push('settings');
            }
            if (customer && customer.isMegafileEnabled) {
                components.push('upload-request-file');
            } else {
                components = components.filter((item: any): boolean => item !== 'upload-request-file');
            }
        } else {
            components = this.securityService.getPermissionsBySuperUser();
            // START: adding super admin components statically
            components.push('payments');
            // ENDING: adding super admin components statically
        }
        if (this.customerService.isAgiAeroDomain()) {
            components.push('auto-pay-subscription');
        }
        if (customer && customer.isUserManagementEnabled) {
            components.push('users');
        } else if (customer && customer.paymentsNotInvoicedEnabled) {
            components.push('payment-not-invoiced');
        } else {
            components = components.filter((item: any) => item !== 'payment-not-invoiced');
        }

        if (customer?.isAutomatedDebitEnabled && !customer?.customerAccount?.isAutomatedDebitEnabled) {
            components.push('bank-debits');
            components.push('bank-info');
            components.push('debit-history');
        }

        if (customer?.customerAccount?.isAutomatedDebitEnabled) {
            components.push('bank-debits-admin');
            components.push('bank-info');
            components.push('debit-history');
        }

        return components;
    }

    private getListItemsForModal(alerts: NotificationsType[]): string {
        let listItem: string = `<div style='justify-content:center; display:flex;'>`;

        alerts.length === 1 ? (listItem += `<ul class='px-3 py-0' style='list-style: none;'>`) : (listItem += `<ul>`);

        alerts.forEach((item: NotificationsType): void => {
            listItem += `<li style='font-weight: 400;'>` + item.message + `</li>`;
        });

        listItem += `</ul></div>`;

        return listItem;
    }

    // TODO: Move this method into the credit card component, at the moment there are multiple components handling the credit cards - low
    // remplace all components with the credit card component and then move the method.
    /**
     * @method getEventsPaymentMethods()
     * @description Listens for payment method events and updates the payment method accordingly.
     *              This method subscribes to events when a new payment method (Credit Card or Echeck) is added,
     *              and sets it as the default payment method if specified.
     * @returns {Observable<string>} An observable that completes with the string 'Complete'.
     */
    getEventsPaymentMethods(): Observable<string> {
        return this.paymentMethodsService.onEventPaymentMethod$().pipe(
            mergeMap((paymentMethodAction: PaymentMethodsEvents | null) => {
                if (
                    paymentMethodAction === PaymentMethodsEvents.creditCardAdded ||
                    paymentMethodAction === PaymentMethodsEvents.creditCardRemoved ||
                    paymentMethodAction === PaymentMethodsEvents.echeckAdded ||
                    paymentMethodAction === PaymentMethodsEvents.echeckRemoved
                ) {
                    return this.updatePaymentMethods().pipe(
                        switchMap(() => {
                            const previusUrl = this.urlService.getPreviousUrl();
                            if (previusUrl.includes('/admin/cart') && paymentMethodAction != null) {
                                return this.getLastPaymentMethod(paymentMethodAction).pipe(
                                    map((lastPaymentMethod: EventPaymentMethod) => {
                                        if (lastPaymentMethod?.item && lastPaymentMethod?.method) {
                                            const isCartAboveThreshold =
                                                this.paymentMethodsService.instant_event_above_threshold;

                                            if (!isCartAboveThreshold) {
                                                this.summaryService.setPaymentMethodSelected({
                                                    paymentAccount: lastPaymentMethod.item,
                                                    method: lastPaymentMethod.method,
                                                    token: lastPaymentMethod.item.paymentToken?.token || undefined,
                                                });
                                            } else {
                                                this.summaryService.setPaymentMethodSelectedAboveThreshold({
                                                    paymentAccount: lastPaymentMethod.item,
                                                    method: lastPaymentMethod.method,
                                                    token: lastPaymentMethod.item.paymentToken?.token || undefined,
                                                });
                                            }
                                        }

                                        this.paymentMethodsService.newEventPaymentMethod(PaymentMethodsEvents.unknown);
                                        return 'Complete';
                                    })
                                );
                            }

                            return of('Complete');
                        })
                    );
                }

                return of('Complete');
            })
        );
    }

    private updatePaymentMethods(): Observable<string> {
        return this.paymentMethodsService.getPaymentMethodsRequest$().pipe(
            take(1),
            map(() => 'Complete')
        );
    }

    private getLastPaymentMethod(event: PaymentMethodsEvents): Observable<EventPaymentMethod> {
        if (event === PaymentMethodsEvents.creditCardAdded) {
            return this.paymentMethodsService.getPaymentMethodsByType$(PaymentMethods.CREDIT_CARD).pipe(
                take(1),
                map((paymentMethod: PaymentMethod | undefined) => {
                    const cards = paymentMethod?.items || [];
                    const method: PaymentMethodsType = PaymentMethods.CREDIT_CARD;
                    const item: Card = cards[0];
                    return { item, method };
                })
            );
        }
        if (event === PaymentMethodsEvents.echeckAdded) {
            return this.paymentMethodsService.getPaymentMethodsByType$(PaymentMethods.ECHECK).pipe(
                take(1),
                map((paymentMethod: PaymentMethod | undefined) => {
                    const echeks = paymentMethod?.items || [];
                    const method: PaymentMethodsType = PaymentMethods.ECHECK;
                    const lengthEcheck = echeks.length;
                    const item: Echeck = echeks[lengthEcheck - 1];
                    return { item, method };
                })
            );
        }

        return of({ item: undefined, method: null });
    }

    getPaymentMethodsAndSetByDefaultIfNeeded(): Observable<string> {
        this.setSettingDefaultPaymentMethod(true);
        return this.paymentMethodsService.getPaymentMethodsRequest$().pipe(
            take(1),
            switchMap(() => {
                const isAuthenticated = this.userSessionService.isAuthenticated();

                if (!isAuthenticated) {
                    this.setSettingDefaultPaymentMethod(false);
                    this.summaryService.setPaymentMethodSelected(null);

                    return of('Complete');
                }

                return this.getPaymentMethodByDefault().pipe(
                    map((paymentMethodByDefault: PaymentMethodSelected | null) => {
                        this.setSettingDefaultPaymentMethod(false);
                        if (paymentMethodByDefault && this.cartBillService.isCartAboveThreshold()) {
                            if (
                                this.customerFeaturesService.isPaymentMethodsSelectedOnCartAboveValid(
                                    paymentMethodByDefault
                                )
                            ) {
                                this.summaryService.setPaymentMethodSelected(paymentMethodByDefault);
                            }

                            return 'Complete';
                        }

                        if (paymentMethodByDefault) {
                            this.summaryService.setPaymentMethodSelected(paymentMethodByDefault);
                        }

                        return 'Complete';
                    })
                );
            }),
            catchError((error) => {
                this.setSettingDefaultPaymentMethod(false);
                return throwError(() => error);
            })
        );
    }

    /*
     * If there is no selected payment method, get the available payment methods. If there is only one, select it.
     * If there are two payment methods and there are invoices in the cart, select the one that is not CargoSprint Credit.
     * If there is a frequent payment method, select it.
     * If there is not enough credit or there are no credit cards or eChecks, select the default payment method in paymentMethodForNewPayments/paymentMethodForOpenInvoices
     */
    private getPaymentMethodByDefault(): Observable<PaymentMethodSelected | null> {
        return this.paymentMethodsService.getPaymentMethods$().pipe(
            take(1),
            map((paymentMethods: PaymentMethod[]) => {
                return paymentMethods.filter(
                    (method) => method.defaultPaymentMethod || method.defaultPaymentMethod === undefined
                );
            }),
            switchMap((paymentMethods: PaymentMethod[]) => {
                if (paymentMethods.length === 1) {
                    return this.paymentMethodsService.getPaymentMethodValidated(paymentMethods[0].name, paymentMethods);
                }
                if (
                    paymentMethods.length === 2 &&
                    this.cartBillService.isThereInvoicesInCart() &&
                    !!paymentMethods.find((paymentMethod) => paymentMethod.name === PaymentMethods.CARGO_CREDIT)
                ) {
                    return this.paymentMethodsService.getPaymentMethodValidated(
                        paymentMethods.filter(
                            (paymentMethod: PaymentMethod) => paymentMethod.name !== PaymentMethods.CARGO_CREDIT
                        )[0]?.name,
                        paymentMethods
                    );
                }

                return this.paymentMethodsAPIService.getPaymentMethodLatest().pipe(
                    take(1),
                    switchMap((paymentMethodsLatest: PaymentMethodsLatest[]) => {
                        const frequentPaymenMethod = this.paymentMethodsService.getFrequentPaymentMethodAvailable(
                            paymentMethodsLatest,
                            paymentMethods
                        );

                        if (frequentPaymenMethod) {
                            return this.paymentMethodsService.getPaymentMethodValidated(
                                frequentPaymenMethod.paymentMethod.componentName,
                                paymentMethods
                            );
                        }

                        const paymentMethodForNewPayments: PaymentMethodsType =
                            this.customerService.getPaymentMethodForNewPayments();
                        const paymentMethodForOpenInvoices: PaymentMethodsType =
                            this.customerService.getPaymentMethodForOpenInvoices();
                        const isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();

                        if (paymentMethodForNewPayments && !isThereInvoicesInCart) {
                            return this.paymentMethodsService.getPaymentMethodValidated(
                                paymentMethodForNewPayments,
                                paymentMethods
                            );
                        }
                        if (paymentMethodForOpenInvoices && isThereInvoicesInCart) {
                            return this.paymentMethodsService.getPaymentMethodValidated(
                                paymentMethodForOpenInvoices,
                                paymentMethods
                            );
                        }

                        return of(null);
                    }),
                    catchError(() => of(null))
                );
            })
        );
    }

    isSettingDefaultPaymentMethod$(): Observable<boolean> {
        return this.settingDefaultPaymentMethod.asObservable();
    }

    private setSettingDefaultPaymentMethod(value: boolean): void {
        this.settingDefaultPaymentMethod.next(value);
    }

    getPaymentMethodsOnCartAboveThreshold(): Observable<string> {
        return this.cartBillService.isCartAboveThreshold$().pipe(
            startWith(false),
            distinctUntilChanged(),
            switchMap((isCartAboveThreshold: boolean) => {
                if (isCartAboveThreshold) {
                    this.loggerErrorService.addBreadcrumb({
                        type: 'info',
                        level: 'info',
                        message: 'Cart has XL transactions',
                        timestamp: dayjs().unix(),
                    });

                    return this.getPaymentMethodsAndSetByDefaultIfNeeded().pipe(take(1));
                }

                return of('Complete');
            })
        );
    }

    private displaySwal(): void {
        if (this.swalService.swals().length > 0 && !Swal.isVisible()) {
            const swalContent = this.swalService.swals()[0];
            if (swalContent.type === 'notification') {
                Swal.fire({
                    title: swalContent.title,
                    html: swalContent.html,
                    icon: swalContent.icon,
                    showConfirmButton: swalContent.showConfirmButton,
                    confirmButtonText: swalContent.confirmButtonText,
                    confirmButtonColor: swalContent.confirmButtonColor,
                    allowOutsideClick: swalContent.allowOutsideClick,
                }).then(() => {
                    setTimeout(() => {
                        this.swalService.removeSwal(swalContent.id || 0);
                    }, 500);
                });
            }
        }
    }
}
