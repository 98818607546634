import * as moment from 'moment';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { CustomerService } from '../../services/utils/user/customer-handler.service';
export class SuperUser extends CustomerService {
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');
    private static readonly paymentReferencePipe = new FormatPaymentReferencePipe();
    // static readonly superUser = permissionsName.superUser; TODO: Super user permissionName should be defined later
    /**
     * SUPER PAYMENTS FACILITY FOR SUPER PORTAL
     */
    static readonly superPaymentsFACILITY_ColumnsHistory = [
        'select',
        'linkId',
        'createdDate',
        'customerName',
        'awb',
        'airportCode',
        'amount',
        'paymentType',
        'notificationEmail',
    ];
    static readonly superPaymentsFACILITY_ColumnsNameHistory = [
        'select',
        'Payment reference #',
        'Created Date',
        'Customer Name',
        'MAWB#',
        'Airport Code',
        'Amount Paid',
        'Payment type',
        'Notification email',
    ];
    static readonly superPaymentsFACILITY_getSuperPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerName:
            item.customer && item.customer.firstName ? `${item.customer.firstName} ${item.customer.lastName}` : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentType: item.paymentType ? item.paymentType : 'N/A',
        notificationEmail: item.notificationEmail
            ? item.notificationEmail
            : item.customer && item.customer.notificationEmail
              ? item.customer.notificationEmail
              : 'N/A',
        airportCode: item.facility.airportCode ? item.facility.airportCode : 'N/A',
        hasDownloadableContent: true,
        linkId: SuperUser.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.id),
        link: `./payment-detail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
        vendorInvoiceNumber: item.vendorInvoiceNumber,
        isRefundEligible: item.isRefundEligible,
        refundUrl: item.refundUrl,
    });
    static readonly payment_getPaymentDetails = (payment: any) => [
        { label: 'Second facility reference #', value: payment?.hawb ? payment?.hawb : 'N/A', show: true },
        { label: 'Amount', value: payment?.amount ? '$' + Number(payment?.amount).toFixed(2) : 'N/A', show: true },
        { label: 'MAWB', value: payment?.awb ? payment?.awb : 'N/A', show: true },
        { label: 'Payment type', value: payment?.paymentType ? payment?.paymentType : 'N/A', show: true },
        { label: 'Paid to', value: payment?.paidTo ? payment?.paidTo : 'N/A', show: true },
        { label: 'Customer reference', value: payment?.customerReference ?? 'N/A', show: true },
        {
            label: 'Branch',
            value: payment?.customerAccount?.accountName ? payment?.customerAccount?.accountName : 'N/A',
            show: true,
        },
        { label: 'Cargo facility', value: payment?.facility?.name ? payment?.facility?.name : 'N/A', show: true },
    ];
    static readonly superPaymentsFACILITY_ColumnsSortableSuperPayments = [
        'createdDate',
        'customerName',
        'awb',
        'amountPaid',
        'paymentType',
        'notificationEmail',
    ];

    /**
     * ACH PAYMENTS FOR SUPER PORTAL
     */

    static readonly superACH_ColumnsHistory = ['id', 'achName', 'createdDate', 'rate'];
    static readonly superACH_ColumnsNameHistory = ['ACH Number', 'ACH Name', 'Created Date', 'Amount Paid'];
    static readonly superACH_getACHPaymentsHistory = (item: any) => ({
        id: item.achNumber ? item.achNumber : 'N/A',
        link: `./paymentDetail/${item.achNumber}`,
        achNumber: item.achNumber ? item.achNumber : 'N/A',
        achName: item.achConfiguration && item.achConfiguration.name ? item.achConfiguration.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        rate: item.rate ? '$' + Number.parseFloat(item.rate).toFixed(2) : 'N/A',
    });
    static readonly superACH_ColumnsSortableACHPayments = ['id', 'createdDate', 'rate'];

    /**
     * ACH PAYMENTS DETAILS FOR SUPER PORTAL
     */

    static readonly superACHDetails_ColumnsHistory = ['linkId', 'awb', 'itemType', 'facilityCity', 'amount'];
    static readonly superACHDetails_ColumnsNameHistory = [
        'Payment reference #',
        'AWB / BL#',
        'Pay item type',
        'Facility city',
        'Amount',
    ];
    static readonly superACHDetails_getACHPaymentsHistory = (item: any) => ({
        referenceNumber: item?.id,
        id: item?.id,
        awb: item.awb ? item.awb : 'N/A',
        itemType: item.payment_TYPE ? item.payment_TYPE : 'N/A',
        facilityCity: item.paid_TO ? item.paid_TO : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        link: `${item.vendorInvoiceNumber ? item.vendor_INVOICE_NUMBER : item.id}`,
        linkId: SuperUser.paymentReferencePipe.transform(item?.vendor_INVOICE_NUMBER, item.id),
    });

    /**
     * REFUND REQUEST PAYMENTS FOR SUPER PORTAL
     */

    static readonly superRefundRequest_ColumnsHistory = [
        'select',
        'id',
        'DATE_REQUESTED',
        'PAYMENT',
        'mawb',
        'CUSTOMER_REFERENCE',
        'REQUESTED_AMOUNT',
        'STATUS',
    ];
    static readonly superRefundRequest_ColumnsNameHistory = [
        'select',
        'Refund Number',
        'Created date',
        'Payment reference #',
        'MAWB',
        'Customer Reference number',
        'Requested amount',
        'Status',
    ];
    static readonly superRefundRequest_getRefundRequestPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        link: `./paymentDetail/${item.id}`,
        CUSTOMER_REFERENCE: item.customerReference ?? 'N/A',
        PAYMENT: SuperUser.paymentReferencePipe.transform(item.payment?.vendorInvoiceNumber, item.payment.id),
        mawb: item?.payment?.awb || 'N/A',
        DATE_REQUESTED: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        refundReason: item.refundReason ? item.refundReason : 'N/A',
        REQUESTED_AMOUNT: item.requestedAmount ? '$' + Number.parseFloat(item.requestedAmount).toFixed(2) : 'N/A',
        STATUS: item.status ? item.status : 'N/A',
        hasDownloadableContent: true,
    });
    static readonly superRefundRequest_ColumnsSortableRefundRequestPayments = [
        'id',
        'PAYMENT',
        'REQUESTED_AMOUNT',
        'DATE_REQUESTED',
        'STATUS',
        'CUSTOMER_REFERENCE',
    ];

    /*
     * SUPER PAYMENTS ACCOUNTING FOR SUPER PORTAL
     */

    static readonly superPaymentsACCOUNTING_ColumnsHistory = [
        'select',
        'id',
        'createdDate',
        'customerName',
        'awb',
        'hawb',
        'amountPaid',
        'agentName',
        'paymentType',
        'notificationEmail',
    ];
    static readonly superPaymentsACCOUNTING_ColumnsNameHistory = [
        'select',
        'Payment reference #',
        'Created Date',
        'Customer Name',
        'MAWB#',
        'HAWB#',
        'Amount Paid',
        'Agent name',
        'Payment type',
        'Notification email',
    ];
    static readonly superPaymentsACCOUNTING_getSuperPaymentsHistory = (item: any) => ({
        id: SuperUser.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.id),
        link: `./`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerName:
            item.customer && item.customer.firstName ? `${item.customer.firstName} ${item.customer.lastName}` : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        amountPaid: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        agentName: item.agentName ? item.agentName : 'N/A',
        paymentType: item.paymentType ? item.paymentType : 'N/A',
        notificationEmail: item.customer && item.customer.notificationEmail ? item.customer.notificationEmail : 'N/A',
    });

    static getCustomerInformationHistorySection(item: any) {
        return [
            {
                label: 'Customer Name',
                value: item.customer ? item.customer.name : 'N/A',
                show: true,
            },
            {
                label: 'Notification email',
                value: item?.notificationEmail ? item?.notificationEmail : 'N/A',
                show: true,
            },
            {
                label: 'Created Date',
                value: item?.createdDate ? moment(item?.createdDate).format('MMM DD, YYYY') : 'N/A',
                show: true,
            },
        ];
    }
}
