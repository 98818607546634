import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import {
    CompletedPaymentOnPay,
    Customer,
    PaymentMethods,
    PaymentMethodsType,
    SaleStatus,
} from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take } from 'rxjs';
import { PaymentsAPIService } from 'src/app/services/requests/payments-api.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { FileHandlerService } from 'src/app/services/utils/file-handler.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { SessionService } from '../../../../services/utils/session.service';
import { CustomerService } from '../../../../services/utils/user/customer-handler.service';
import { CartPayResponse } from '../services/models/types';
import { PaymentConfirmationService } from '../services/payment-confirmation.service';

@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
    styleUrls: ['./payment-confirmation.component.scss'],
})
export class PaymentConfirmationComponent implements OnInit {
    public isThereInvoicesInCart: boolean = false;
    public customer: Customer | null = null;
    public readonly paymentMethod: string;
    public paymentResponse: CartPayResponse[] = [];
    public successfulResponse: CartPayResponse[] = [];
    public errorResponse: CartPayResponse[] = [];
    public countPaymentSuccessful: number = 0;
    public countPaymentErrors: number = 0;
    public totalPayments: number = 0;
    public readonly paymentMethods = PaymentMethods;
    public isCargoCreditPaymentMethod: boolean = false;
    public isTherePaymentMethodDifferentCargoCredit: boolean = false;
    public multiplesPaymentMethods: boolean = false;
    public paymentReceiptText: string = 'Download Receipt';
    public isPaymentsNotInvoicedEnabled?: boolean;
    public differentPaymentMethods?: boolean;
    private readonly cartUrl: string;
    private readonly invoiceUrl: string;

    @Output() return = new EventEmitter<boolean>();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private customerService: CustomerService,
        private restService: RestService,
        private sessionService: SessionService,
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer,
        private paymentsAPIService: PaymentsAPIService,
        private fileHandlerService: FileHandlerService,
        private errorHandlerService: ErrorHandlerService,
        private ngxSpinnerService: NgxSpinnerService,
        private paymentConfirmationService: PaymentConfirmationService
    ) {
        this.cartUrl = environment.uris.method.cart;
        this.invoiceUrl = environment.uris.method.invoices;
        this.customer = this.customerService.getCustomer();
        this.isPaymentsNotInvoicedEnabled = this.customer?.isPaymentsNotInvoicedEnabled;
        const paymentMethodApplied = this.sessionService.getElement('paymentMethodApplied');
        this.paymentMethod = paymentMethodApplied?.paymentType;
    }

    ngOnInit(): void {
        this.subscribePaymentResponse();
    }

    /**
     * @method downloadExcel()
     * @description
     */
    goToCart() {
        this.return.emit(false);
    }

    downloadExcel(): void {
        const transactionId = this.getTransactionId(this.successfulResponse);
        const url: string = `${this.invoiceUrl}/downloadInvoicesByConfirmationId?confirmationId=${transactionId}&downloadFormat=XLSX`;

        this.ngxSpinnerService.show();
        this.restService
            .getFiles(url, {})
            .then((result: any): void => {
                const $link = this.renderer.createElement('a');
                const file: Blob = new Blob([result], { type: result.type });
                const url: any = this.domSanitizer.sanitize(
                    SecurityContext.URL,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                this.renderer.setAttribute($link, 'href', url);
                this.renderer.setAttribute($link, 'download', 'Statement-' + transactionId + '.xlsx');
                $link.click();
                window.URL.revokeObjectURL(url);
            })
            .finally(() => {
                this.ngxSpinnerService.hide();
            });
    }

    /**
     * @method getPaymentReceipt()
     * @description
     */

    getPaymentReceipt(paymentExcluded?: PaymentMethodsType): void {
        const url: string = this.cartUrl + '/receipts/invoices';
        const body = this.getPaymentReceiptsPayload(paymentExcluded);

        this.ngxSpinnerService.show();
        this.restService
            .getFileByPost(url, body)
            .then((result: any): void => {
                const $link = this.renderer.createElement('a');
                const file: Blob = new Blob([result], { type: result.type });
                const url: any = this.domSanitizer.sanitize(
                    SecurityContext.URL,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );

                const extension = this.restService.getExtension(result.type);
                this.renderer.setAttribute($link, 'href', url);
                this.renderer.setAttribute($link, 'download', this.getFileName() + extension);
                $link.click();
                window.URL.revokeObjectURL(url);
            })
            .finally(() => {
                this.ngxSpinnerService.hide();
            });
    }

    getFileName(): string {
        if (this.differentPaymentMethods) {
            if (this.isCargoCreditPaymentMethod && this.isTherePaymentMethodDifferentCargoCredit) {
                return 'Invoices and receipts ';
            }
            if (!this.isCargoCreditPaymentMethod && this.isTherePaymentMethodDifferentCargoCredit) {
                return 'Receipts';
            }
        }

        const transactionId = this.getTransactionId(this.successfulResponse);

        return 'Receipt-' + transactionId;
    }

    getPaymentReceiptsPayload(paymentExcluded?: PaymentMethodsType): {
        confirmationId: string;
        invoices: number[];
    }[] {
        return this.successfulResponse
            .map((successfulResponse: CartPayResponse) => {
                if (paymentExcluded === successfulResponse.paymentMethod) {
                    return null;
                }
                return {
                    confirmationId: successfulResponse.transactionId || '',
                    invoices: this.getCompletedIds(successfulResponse.completedPayments || []),
                };
            })
            .filter((payload): payload is { confirmationId: string; invoices: number[] } => !!payload);
    }

    /**
     * @method getPaymentConfirmation()
     * @description
     */

    getPaymentConfirmation(): void {
        const paymentsIds = this.getPaymentsIdsMultiplePayments(this.successfulResponse);

        this.ngxSpinnerService.show();
        this.paymentsAPIService
            .downloadPaymentConfirmation(paymentsIds.join(', '))
            .pipe(
                take(1),
                finalize(() => this.ngxSpinnerService.hide())
            )
            .subscribe({
                next: (file) => {
                    const extension = this.restService.getExtension(file.type);
                    const fileName =
                        paymentsIds.length > 1
                            ? 'Payments Confirmation' + extension
                            : 'Payment Confirmation - ' + paymentsIds[0] + extension;

                    this.fileHandlerService.handleDownloadFile(file, fileName);
                },
                error: async (error: HttpErrorResponse) => {
                    let text = await new Response(error.error).text();
                    text = this.errorHandlerService.errorMsg(JSON.parse(text));

                    Swal.fire({
                        title: 'Oops...',
                        text,
                        icon: 'error',
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method downloadInvoices()
     * @description
     */

    downloadInvoices(): void {
        this.ngxSpinnerService.show();

        const invoicesIds = this.successfulResponse
            .map((successfulResponse: CartPayResponse) => {
                if (successfulResponse.paymentMethod === this.paymentMethods.CARGO_CREDIT) {
                    return this.getCompletedIds(successfulResponse.completedPayments || []);
                }
                return [];
            })
            .flat();

        const url: string = `${this.invoiceUrl}/downloadInvoices?invoicesIds=${invoicesIds.join(
            ','
        )}&downloadFormat=PDF`;

        this.restService
            .getFile(url, {})
            .pipe(
                take(1),
                finalize(() => this.ngxSpinnerService.hide())
            )
            .subscribe({
                next: (result: any) => {
                    const $link: any = this.renderer.createElement('a');
                    const file: Blob = new Blob([result], { type: result.type });
                    const url: any = this.domSanitizer.sanitize(
                        SecurityContext.URL,
                        this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                    );
                    const extension = this.restService.getExtension(result.type);
                    this.renderer.setAttribute($link, 'href', url);
                    this.renderer.setAttribute(
                        $link,
                        'download',
                        invoicesIds.length > 1 ? 'Invoices' + extension : 'INV' + invoicesIds[0] + extension
                    );
                    $link.click();
                    window.URL.revokeObjectURL(url);
                },
            });
    }

    subscribePaymentResponse(): void {
        this.paymentConfirmationService
            .getPaymentResponse()
            .pipe(take(1))
            .subscribe({
                next: (paymentResponse: CartPayResponse[] | null) => {
                    this.paymentResponse = paymentResponse || [];
                    if (paymentResponse?.length) {
                        paymentResponse.forEach((paymentElement: CartPayResponse) => {
                            this.totalPayments = this.totalPayments + (paymentElement?.totalPayments || 0);
                            if (paymentElement.isThereInvoicesInCart) {
                                this.isThereInvoicesInCart = true;
                            }
                            if (paymentElement.saleStatus === SaleStatus.SALE_SUCCESS) {
                                this.successfulResponse.push(paymentElement);
                                this.countPaymentSuccessful =
                                    this.countPaymentSuccessful + (paymentElement?.totalPayments || 0);
                            } else {
                                this.errorResponse.push(paymentElement);
                                this.countPaymentErrors =
                                    this.countPaymentErrors + (paymentElement?.totalPayments || 0);
                            }
                        });

                        this.differentPaymentMethods = this.areDifferentPaymentMethods(this.successfulResponse);
                        this.isCargoCreditPaymentMethod = this.validIfIsCargoCreditPaymentMethod();
                        this.isTherePaymentMethodDifferentCargoCredit =
                            this.isTherePaymentMethodDifferentThanCargoCredit();

                        if (this.isCargoCreditPaymentMethod && this.isTherePaymentMethodDifferentCargoCredit) {
                            this.paymentReceiptText = 'DOWNLOAD RECEIPT AND INVOICE(S)';
                        }
                    }
                },
            });
    }

    validIfIsCargoCreditPaymentMethod(): boolean {
        return this.successfulResponse?.some((paymentElement: CartPayResponse) => {
            return paymentElement.paymentMethod === this.paymentMethods.CARGO_CREDIT;
        });
    }

    isTherePaymentMethodDifferentThanCargoCredit(): boolean {
        return this.successfulResponse?.some((paymentElement: CartPayResponse) => {
            return paymentElement.paymentMethod !== this.paymentMethods.CARGO_CREDIT;
        });
    }

    areDifferentPaymentMethods(responses: CartPayResponse[]): boolean {
        if (responses.length < 2) return false;

        const firstPaymentMethod = responses[0].paymentMethod;

        return responses.some((response) => response.paymentMethod !== firstPaymentMethod);
    }

    getTransactionId(payments: CartPayResponse[]): string {
        return payments.map((paymentElement: CartPayResponse) => paymentElement.transactionId)[0] || '';
    }

    getRequestTransactionsId(payments: CartPayResponse[]): string[] {
        return payments
            .map((paymentElement: CartPayResponse) => paymentElement.transactionId || '')
            .filter((id) => !!id);
    }

    getPaymentsIds(payment: CartPayResponse): number[] {
        return payment.completedPayments?.map((cp) => Number(cp.id)).filter((id) => !!id) || [];
    }

    getPaymentsIdsMultiplePayments(payments: CartPayResponse[]): number[] {
        return payments
            .map((paymentElement: CartPayResponse) => this.getPaymentsIds(paymentElement))
            .flat()
            .filter((id) => !!id);
    }

    getCompletedIds(completedPayments: CompletedPaymentOnPay[]): number[] {
        return completedPayments?.map((cp) => Number(cp.invoice?.id)).filter((id) => !!id) || [];
    }
}
