import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { Locations } from 'src/app/models/utils/locations.model';
import { FacilityService } from 'src/app/services/facility.service';
import { RestService } from 'src/app/services/rest.service';
import { KN } from 'src/app/utils/companies/kn';
import { Domains, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepper-types';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../../../../../services/utils/user/customer-handler.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-kn-reference',
    templateUrl: './kn-reference.component.html',
})
export class KnReferenceComponent implements OnInit {
    knReferenceForm!: FormGroup;
    paymentAmountOptions: any[];
    typeOptions!: string[];
    urlNextStep: string;
    onEdition: boolean;
    locationAssigned: any;
    locations: Locations[];
    disabledInput: boolean = false;
    matcher: ErrorMatcher = new ErrorMatcher();
    private customer: any;
    private readonly currentPayment: Payment | null;
    private readonly companyName: string;
    private readonly paymentsURL: String;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _router: Router,
        private _stepperService: StepperService,
        private _restService: RestService,
        private _facilityService: FacilityService
    ) {
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        if (!this.currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this.customer = this._customerService.getCustomer();
        this.paymentsURL = environment.uris.method.payments;
        const approvalLevels: any = this.customer?.approvalLevels?.company?.paymentReference?.map((item: any) => {
            if (item.name === 'Type') {
                return item;
            }
        });
        this.paymentAmountOptions = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
        const facility = this.currentPayment?.facility;
        const facilityId = facility?.id || '';
        this._facilityService
            .getPaymentTypes(facilityId)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isCustomerEmailInDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : JSON.parse(JSON.stringify(KN.paymentTypes));
                    }
                    if (this.currentPayment?.knCustomerReference?.type) {
                        this.verifyIfPaymentTypeExists(this.currentPayment.knCustomerReference.type);
                    }
                },
            });
        this.onEdition = this.currentPayment?.redirectRoute ? true : false;
        this.companyName = this._customerService.getCompanyName();
        this.urlNextStep = this.currentPayment?.redirectRoute
            ? this.currentPayment.redirectRoute
            : this.companyName
              ? '../paymentDetails'
              : '../customerReference';
        this.locationAssigned =
            this.customer.approvalLevels && this.customer.approvalLevels.company
                ? this.customer.approvalLevels.company.firstLocation
                : null;
        this.locations = [];
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this.locationAssigned) {
            this.knReferenceForm.get('locationId')!.setValue(this.locationAssigned.id);
            this.knReferenceForm.get('locationName')!.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        if (this.currentPayment?.knCustomerReference) {
            this.knReferenceForm.patchValue(this.currentPayment.knCustomerReference);
            this.type ? this.type.setValidators([Validators.required]) : '';
        }
    }

    verifyIfPaymentTypeExists(currentPaymentType: string): void {
        if (!this.typeOptions.includes(currentPaymentType)) {
            this.typeOptions.push(currentPaymentType);
            this.disabledInput = true;
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.knReferenceForm = this._formBuilder.group({
            paymentAmount: new FormControl<any | null>(null, [Validators.required]),
            type: new FormControl<any | null>(null, [Validators.required]),
            locationId: new FormControl<string | null>(null, [Validators.required]),
            locationName: new FormControl<string | null>(null, [Validators.required]),
        });
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.knReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.knReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.knReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method getKNValues()
     * @description
     */

    getKNValues(): void {
        if (this.knReferenceForm.invalid) {
            this.knReferenceForm.markAllAsTouched();
        } else {
            if (
                this.currentPayment?.id &&
                this.currentPayment?.knCustomerReference?.paymentAmount !==
                    this.knReferenceForm.get('paymentAmount')?.value
            ) {
                this.currentPayment?.externalData
                    ? (this.currentPayment.externalData.type = this.knReferenceForm.get('paymentAmount')!.value)
                    : '';
                this._paymentFluxService.setData('knCustomerReference', this.knReferenceForm.getRawValue());
                Swal.fire({
                    title: 'Important !',
                    icon: 'info',
                    text: 'You have changed some key information! Please review your request before continuing.',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#14bb9c',
                    allowOutsideClick: false,
                }).then((): void => {
                    this._stepperService[StepperSteps.STEP_ZERO].next(true);
                    this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
                });
            } else {
                this._stepperService[StepperSteps.STEP_ZERO].next(true);
                if (this.onEdition) {
                    this._stepperService[StepperSteps.STEP_FIRST].next(true);
                    this._stepperService[StepperSteps.STEP_SECOND].next(true);
                }
                this._paymentFluxService.setData('knCustomerReference', this.knReferenceForm.getRawValue());
                this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
            }
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this.paymentsURL + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.knReferenceForm.get('locationName')!.setValue(item.name);
        }
    }
}
