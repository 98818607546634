import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicField } from '@cargos/sprintpay-models';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';

@Component({
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatCheckboxModule,
        DisableControlDirective,
    ],
    selector: 'app-dynamic-checkboxes',
    templateUrl: './dynamic-checkboxes.component.html',
    styleUrls: ['./dynamic-checkboxes.component.scss'],
})
export class DynamicCheckboxesComponent implements OnInit {
    public formName: FormGroup;
    public values: string[] = [];

    @Input() field: DynamicField = {};

    constructor(
        private formgroupDirective: FormGroupDirective,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.formName = formgroupDirective.control;
    }

    ngOnInit(): void {
        if (this.formName.get(this.field?.name!)?.value) {
            const value = this.formName.get(this.field?.name!)?.value;
            this.values = value.split(',');
        }
    }

    selectOption(value: string, checked: boolean): void {
        if (checked) {
            const existValue = !!this.values.find((item) => value === item);
            if (!existValue) {
                this.values.push(value);
            }
        } else {
            const index = this.values.indexOf(value);
            index > -1 ? this.values.splice(index, 1) : '';
        }
        this.formName.get(this.field?.name!)?.setValue(this.values.join(','));
        this.changeDetectorRef.detectChanges();
    }
}
