import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, switchMap, throwError } from 'rxjs';
import { Facility } from 'src/app/models/payments/facility.model';
import { FacilityService } from 'src/app/services/facility.service';
import { FacilityAPIService } from 'src/app/services/requests/facility-api.service';
import { AuthService } from 'src/app/services/utils/auth.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { Domains, paymentTypes, paymentTypesDSV } from 'src/app/utils/constants';
import Swal, { SweetAlertResult } from 'sweetalert2';

interface FluxPayment {
    facility: Facility;
    details: Record<string, unknown>;
}

@Injectable()
export class FacilitySearchService {
    private _showDefaultSearch: BehaviorSubject<boolean>;

    constructor(
        private _customerService: CustomerService,
        private _breakpointObserver: BreakpointObserver,
        private _authService: AuthService,
        private _facilityAPIService: FacilityAPIService,
        private _facilityService: FacilityService
    ) {
        this._showDefaultSearch = new BehaviorSubject<boolean>(false);
    }

    /**
     * @method getShowDefaultSearch()
     * @description Getter to show default or QRcode search
     */

    getShowDefaultSearch(): Observable<boolean> {
        return this._showDefaultSearch.asObservable().pipe(
            switchMap((value) => {
                if (!value) {
                    return this._breakpointObserver.observe(Breakpoints.HandsetPortrait).pipe(
                        switchMap((breakpointState) => {
                            if (breakpointState.matches && this._customerService.isGuest()) {
                                return of(false);
                            }

                            return of(true);
                        })
                    );
                }

                return of(true);
            })
        );
    }

    /**
     * @method setShowDefaultSearch()
     * @param (showDefaultSearch: boolean)
     * @description Set showDefaultSearch value
     */

    setShowDefaultSearch(showDefaultSearch: boolean): void {
        this._showDefaultSearch.next(showDefaultSearch);
    }

    /**
     * @method decodePaymentFromToken()
     * @param (token: string)
     * @description Uncompress token and load facility details to prepare payment data
     */

    decodePaymentFromToken(token: string): Observable<FluxPayment> {
        return this._authService.uncompressToken(token, '?isQR=true').pipe(
            switchMap(({ result }) => {
                if (!result) {
                    return throwError(() => {
                        return 'Wrong QR code';
                    });
                }

                const scannedResult = Object.fromEntries(new URLSearchParams(result).entries());
                const { facilityId, ...paymentRequestData } = scannedResult;

                if (!facilityId) {
                    return throwError(() => {
                        return 'Wrong QR code';
                    });
                }

                return this._facilityAPIService.getFacilityDetailsRequest(facilityId).pipe(
                    map((facility) => {
                        const currentPayment = this._preparePaymentData(facility, paymentRequestData);
                        return currentPayment;
                    })
                );
            })
        );
    }

    /**
     * @method _preparePaymentData()
     * @param (facility: Facility)
     * @param (paymentRequestData: Record<string, string>)
     * @description Prepare payment object
     */

    private _preparePaymentData(facility: Facility, paymentRequestData: Record<string, string>): FluxPayment {
        const paymentTypesArray = facility.paymentTypes?.length
            ? facility.paymentTypes
            : this._customerService.isCustomerEmailInDomain(Domains.DSV)
              ? JSON.parse(JSON.stringify(paymentTypesDSV))
              : JSON.parse(JSON.stringify(paymentTypes));

        return {
            facility,
            details: {
                paymentType: paymentTypesArray.includes(paymentRequestData['paymentType'])
                    ? paymentRequestData['paymentType']
                    : '',
                amount: facility.handlingFee,
            },
        };
    }

    /**
     * @method showExternalFacilityRedirectPopup()
     * @param (facilityName: string)
     * @description Show popup with prompt to redirect to external facility website
     */

    showExternalFacilityRedirectPopup(facilityName: string): Promise<SweetAlertResult> {
        return Swal.fire({
            html: `
                <h5 class="mt-3" style='text-align: center; color: #032747;'>
                    Please wait...Hey, psst psst...
                </h5>
                <div style='padding: 1rem 1rem 0 1rem; text-align: center;'>
                    In order to complete a payment to <strong> ${facilityName} </strong> you'll be redirected to its site
                </div>
            `,
            icon: 'info',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Redirect',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
            reverseButtons: true,
        });
    }

    showNotesForClientsPopup(facility: Facility): Promise<SweetAlertResult> {
        const formattedNotes = (facility.notesForClients as unknown as string[]).map((note) => {
            if (facility.isWFS && facility.airportCode?.toLowerCase() === 'lax') {
                note = this._facilityService.convertUrl(note);
            }

            return `<li style="font-weight: 400">${note}</li>`;
        });

        return Swal.fire({
            html: `
                <h5 class="mt-3" style='text-align: center; color: #032747;'>
                    Important Notice
                </h5>
                <ul style="list-style: none; text-align:left">
                    ${formattedNotes.join('')}
                </ul>
            `,
            icon: 'info',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
            reverseButtons: true,
        });
    }
}
