import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { FileAttachmentService } from '@cargos/sprintpay-services';
import { BehaviorSubject, take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { SharedModule } from 'src/app/shared/shared.module';
import Swal from 'sweetalert2';
import { ErrorHandlerService } from '../../../../../../../services/utils/error-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../../services/utils/session.service';
import { blackListFiles } from '../../../../../../../utils/constants';
import { PaymentDetailService } from '../../payment-detail.service';

@Component({
    selector: 'app-upload-file-flux',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
    templateUrl: './upload-file.component.html',
})
export class UploadFileComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
    paymentForm!: FormGroup;
    nameFilesArr: string[];
    attachFileLabel: string;
    isFileLoading: BehaviorSubject<boolean>;
    dynamicField: any;
    private nameFailedFilesArr: string[];
    private loadingFile: boolean;
    private currentPayment: Payment | null;
    private pathFileArr: string[];
    private blackListFile: string[];
    private readonly startingLevel: boolean;

    @Input() formValidState: any;
    @Output() returnUploadFile: EventEmitter<any> = new EventEmitter<any>();

    @Input() set isDinamycField(isDinamycField: any) {
        this.dynamicField = isDinamycField;
        if (isDinamycField) {
            this._setDynamicValidations(isDinamycField);
        }
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private errorHandlerService: ErrorHandlerService,
        private paymentDetailService: PaymentDetailService,
        private paymentFluxService: PaymentFluxService,
        private fileAttachmentService: FileAttachmentService
    ) {
        this.loadingFile = false;
        this.nameFilesArr = [];
        this.pathFileArr = [];
        this.nameFailedFilesArr = [];
        this.dynamicField = '';
        this.attachFileLabel = '';
        this.attachFileLabel = 'Attach files (Optional)';
        this.blackListFile = blackListFiles;
        this.currentPayment = this.paymentFluxService.getCurrentPayment();
        this.startingLevel = !!this.sessionService.getElement('startingLevel');
        this.isFileLoading = new BehaviorSubject<boolean>(false);
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._startingLevelConfiguration();
        if (this.currentPayment?.details?.nameFiles != null) {
            let path = (this.pathFileArr = this.currentPayment.details.nameFiles
                ? this.currentPayment.details.nameFiles.split(',')
                : []);
            this.paymentForm.get('nameFiles')!.setValue(path.join(','));
            path.forEach((element: any) => {
                let aux = element.split('/');
                this.nameFilesArr.push(aux[aux.length - 1]);
            });
            this.returnUploadFile.emit(this.paymentForm.value);
        }
    }

    ngOnDestroy(): void {
        this.isFileLoading.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.nameFiles.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this.formBuilder.group({
            nameFiles: new FormControl<string | null>(null, []),
        });
        this.paymentForm = this.paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */

    get nameFiles(): FormGroup {
        return this.paymentForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method __startingLevelConfiguration()
     * @description Set the form requirements to be a valid submission
     */

    private _startingLevelConfiguration(): void {
        const validator: any = this.paymentForm.get('nameFiles')?.hasValidator(Validators.required);
        if (this.startingLevel) {
            if (!!validator) {
                this.attachFileLabel = 'Please attach the required document(s)';
            }
        }
    }

    /**
     * @method dropHandler()
     * @param (event: any)
     * @description drop files into area
     */

    dropHandler(event: any) {
        event.preventDefault();
        if (event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer);
        }
        this._removeDragData(event);
    }

    /**
     * @method _removeDragData()
     * @param (event: any)
     * @description removed the drag files
     */

    private _removeDragData(event: any): void {
        event.dataTransfer.items ? event.dataTransfer.items.clear() : event.dataTransfer.clearData();
    }

    /**
     * @method handleFileInput()
     * @param (event: any)
     * @description Save and handle the files
     */

    handleFileInput(event: any): void {
        event = event['files'];
        let nameTempFilesArr: any[] = [];
        this.nameFailedFilesArr = [];
        if (event.length > 3 || event.length + this.nameFilesArr.length > 3) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You may upload at most 3 files .doc, .docx, .pdf or image (jpeg, jpg, png), of max 1MB each.',
            });
        } else {
            for (let i = 0; i < event.length; i++) {
                if (!this._verifyFileExtensions(event.item(i).name)) {
                    nameTempFilesArr.push(event.item(i).name.replace(/ /g, '_'));
                    if (event[i].size / 1024 >= 2000) {
                        Swal.fire({
                            html: `${this.errorHandlerService.errorTemplate({
                                errors: ['Please select a file with less than 1 MB'],
                            })}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                        this.nameFailedFilesArr.push(nameTempFilesArr.shift());
                        continue;
                    }
                    let formData = new FormData();
                    formData.append('file', event.item(i));
                    this.loadingFile = true;
                    this.isFileLoading.next(true);
                    this.fileAttachmentService
                        .uploadAttachment(formData)
                        .pipe(take(1))
                        .subscribe({
                            next: (locationPath: string) => {
                                if (locationPath) {
                                    this.pathFileArr.push(locationPath);
                                    this.paymentForm.get('nameFiles')!.setValue(this.pathFileArr.join(','));
                                    this.nameFiles.markAsTouched();
                                    let arrayPath = locationPath.split('/');
                                    this.nameFilesArr.push(arrayPath[arrayPath.length - 1]);
                                    nameTempFilesArr.shift();
                                    this.returnUploadFile.emit(this.paymentForm.value);
                                }
                                this.loadingFile = false;
                                this.isFileLoading.next(false);
                            },
                            error: (error: any) => {
                                if (error?.error && typeof error.error == 'string') {
                                    error.error = JSON.parse(error.error);
                                }
                                if (error?.error?.errors && error?.error?.errors?.length >= 1) {
                                    nameTempFilesArr.shift();
                                    Swal.fire({
                                        html: `${this.errorHandlerService.errorTemplate(error.error)}`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                        allowOutsideClick: false,
                                    });
                                } else {
                                    Swal.fire({
                                        html: `${this.errorHandlerService.errorMsg(error?.error)}`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                        allowOutsideClick: false,
                                    });
                                    this.nameFailedFilesArr.push(nameTempFilesArr.shift());
                                }
                                this.loadingFile = false;
                                this.isFileLoading.next(false);
                            },
                        });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You file contains different extensions.',
                    });
                }
            }
        }
    }

    /**
     * @method _verifyFileExtensions()
     * @param (fileName: any)
     * @description Verify the file extensions
     */

    private _verifyFileExtensions(fileName: any): boolean {
        let extensionContainsBlackListFile: boolean = false;
        fileName.split('.').map((item: string): void => {
            if (this.blackListFile.indexOf(item.toLowerCase()) !== -1) {
                extensionContainsBlackListFile = true;
            }
        });
        return extensionContainsBlackListFile;
    }

    /**
     * @method dragOverHandler()
     * @param (event: any)
     * @description drag files
     */

    dragOverHandler(event: any): void {
        event.preventDefault();
    }

    /**
     * @method getFormControls()
     * @description
     */

    getFormControls() {
        if (this.loadingFile) {
            Object.keys(this.paymentForm.controls).forEach((key) => {
                const controlErrors: ValidationErrors | null | undefined = this.paymentForm.get(key)?.errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach((): void => {
                        this.loadingFile = false;
                    });
                }
            });
        }
        return this.paymentForm.controls;
    }

    /**
     * @method removeFile()
     * @param (index: number)
     * @description removed the drag files
     */

    removeFile(index: number): void {
        if (this.pathFileArr[index]) {
            this.fileAttachmentService
                .removeAttachment(this.pathFileArr[index])
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.nameFilesArr.splice(index, 1);
                        this.pathFileArr.splice(index, 1);
                        if (this.nameFilesArr.length < 1) {
                            this.nameFilesArr = [];
                            this.pathFileArr = [];
                            this.paymentForm.get('nameFiles')!.setValue(null);
                            this.paymentForm.value.nameFiles = null;
                            if (this.fileInput?.nativeElement) {
                                this.fileInput.nativeElement.value = '';
                            }
                        } else {
                            this.paymentForm.get('nameFiles')!.setValue(this.pathFileArr.join(','));
                        }
                        this.returnUploadFile.emit(this.paymentForm.value);
                    },
                    error: (err: any) => {
                        Swal.fire({
                            html: `${this.errorHandlerService.errorMsg(err.error)}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }

    /**
     * @method setDynamicValidations()
     * @param (field: any)
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    private _setDynamicValidations(field: any): void {
        this.paymentForm?.get(field.name)?.setValidators(null);
        this.paymentForm?.get(field.name)?.setErrors(null);
        if (field.validations.required) {
            if (field.validations.required.value === true) {
                this.paymentForm?.get(field.name)?.addValidators(Validators.required);
            }
        }
        field.validations.minlength
            ? this.paymentForm?.get(field.name)?.addValidators(Validators.minLength(field.validations.minlength.value))
            : null;
        field.validations.maxlength
            ? this.paymentForm?.get(field.name)?.addValidators(Validators.maxLength(field.validations.maxlength.value))
            : null;
        field.validations.pattern
            ? this.paymentForm?.get(field.name)?.addValidators(Validators.pattern(field.validations.pattern.value))
            : null;
        this.paymentForm?.get(field.name)?.updateValueAndValidity();
    }
}
