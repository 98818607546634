<form [formGroup]="formName">
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{field.label}}</mat-label>
        <ng-container *ngIf="field.name">
            <mat-select
                #dynamicSelect
                [formControlName]="field.name"
                [matTooltip]="field.tooltipMessage || ''"
                [appDisableControl]="field.isDisabled || false"
            >
                <mat-option *ngFor="let option of options" color="primary" [value]="option?.value">
                    {{option.displayValue}}
                </mat-option>
            </mat-select>
        </ng-container>

        <mat-error>
            <app-dynamic-error [field]="field"></app-dynamic-error>
        </mat-error>
        <mat-icon *ngIf="fetchingData" matIconSuffix>
            <mat-spinner diameter="24"></mat-spinner>
        </mat-icon>
    </mat-form-field>
</form>
