import { Component, OnDestroy, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs';
import { Actions } from 'src/app/models/utils/actions.model';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { DownloadSettings } from 'src/app/standalone-components/download/models/download-settings';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { Table } from '../../../../models/ui/table.model';
import { RestService } from '../../../../services/rest.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { CustomerService } from '../../../../services/utils/user/customer-handler.service';
import { paginatorSize } from '../../../../utils/constants';
import { Sections } from '../../../../utils/sections';

@Component({
    selector: 'app-paid',
    templateUrl: './paid.component.html',
})
export class PaidComponent implements OnInit, OnDestroy {
    noInformation: boolean | undefined;
    noInformationMessage: string;
    invoicesCompleted: any;
    amTable!: Table[];
    recordsSelected: [];
    actions: Actions[];
    sortableColumns: string[];
    readonly downloadSettings: DownloadSettings;
    readonly companyName: string;
    readonly customer: any;
    readonly startingLevel: boolean;
    private readonly _invoicesUrl: string;
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;
    private _isDynamicCompany: boolean;
    private _dynamicFields: any;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _restService: RestService,
        private _customerService: CustomerService,
        private _matDialog: MatDialog,
        private _errorHandlerService: ErrorHandlerService,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this._breadcrumbService.set('@paid', 'Paid');
        this._invoicesUrl = environment.uris.method.invoices;
        this.noInformation = undefined;
        this.customer = this._customerService.getCustomer();
        this.companyName = this._customerService.getCompanyName();
        this.startingLevel = this._customerService.getStartingLevel();
        this._isDynamicCompany = this._customerService.isDynamicCompany();
        this.noInformationMessage = '';
        this.recordsSelected = [];
        this.actions = [];
        this.downloadSettings = {
            paymentName: 'paid invoices',
            kind: [
                {
                    label: 'Invoices',
                    type: 'pdf',
                },
                {
                    label: 'Statement',
                    type: 'xlsx',
                    default: true,
                },
            ],
        };
        this._typeSort = '';
        this._fieldToSort = '';
        this.sortableColumns = [];
        this._isSorting = false;
    }

    ngOnInit(): void {
        this.getPaidInvoices();
        this._setActions();
        this.getAllFieldsWithReplacement();
    }

    ngOnDestroy(): void {
        this.closeDialog();
    }

    /**
     * @method _setActions()
     * @description We set the actions that will be trigger when the user clicks the vertical triple dot on the last item row from the table
     */

    private _setActions(): void {
        this.actions = [
            {
                title: 'Download paid invoice',
                icon: 'download',
                function: {
                    name: 'getPaidedInvoiceFile',
                    prototype: this,
                },
                condition: 'true',
            },
        ];
    }

    /**
     * @method _getPaidInvoicesTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getPaidInvoicesTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method getPaidInvoices()
     * @param (item?: any)
     * @param (type?:any)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getPaidInvoices(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const shortUrl: string = `${this._invoicesUrl}/getInvoicesPaid`;

        let longUrl: string = `${shortUrl}/?page=${currentSize}&size=${pageSize}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }
        longUrl = `${longUrl}&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method search()
     * @param (filter: any)
     * @description Search over all the items on the table
     */

    search(filter: any): void {
        if (filter.value) {
            const pageSize: number =
                filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : paginatorSize[0];
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._invoicesUrl}/searchInvoicesPaid/?searchTerms=${
                filter.value
            }&page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getPaidInvoices(filter);
        }
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getPaidInvoices();
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Call the APU and return the data
     */

    private _getCompleteData(url: string, type?: string): void {
        this._restService
            .get(url, {})
            .then((result: any): void => {
                let companyNameColumns: string[] = [];
                let companyNameColumnsName: string[] = [];
                const companyType: string = this.companyName || this.startingLevel ? this.companyName : 'ordinary';
                const dynamicCompany: boolean = this._customerService.isDynamicCompany();
                Object.keys(Sections.paidInvoicesSection).forEach((item: any, index: any): void => {
                    if (
                        item === `${companyType}_ColumnsPaidInvoices` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsPaidInvoices')
                    ) {
                        companyNameColumns = Object.values(Sections.paidInvoicesSection)[index];
                    } else if (
                        item === `${companyType}_ColumnsNamePaidInvoices` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsNamePaidInvoices')
                    ) {
                        companyNameColumnsName = Object.values(Sections.paidInvoicesSection)[index];
                    } else if (
                        item === `${companyType}_getCompletedPaymentsPaidInvoices` ||
                        (!!dynamicCompany && item === 'Dynamic_getCompletedPaymentsPaidInvoices')
                    ) {
                        if (result && result.content.length !== 0) {
                            this.invoicesCompleted = result.content.map((elements: any) => {
                                return Object.values(Sections.paidInvoicesSection)[index](elements);
                            });
                        } else if (result && result.content.length === 0 && this.invoicesCompleted !== undefined) {
                            this.invoicesCompleted = [];
                        }
                    } else if (
                        item === `${companyType}_ColumnsSortablePaidInvoices` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsSortablePaidInvoices')
                    ) {
                        this.sortableColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.paidInvoicesSection)[index])
                        );
                    }
                });

                if (this._isDynamicCompany && this._customerService.getCompanyId()) {
                    companyNameColumnsName = this.dynamicFieldsService.getHeaderdinamicAndReplaceable(
                        companyNameColumns,
                        companyNameColumnsName,
                        this._dynamicFields
                    );
                }

                this.noInformation = type === 'search' ? false : !(this.invoicesCompleted.length > 0);
                this._getPaidInvoicesTableRender(
                    this.invoicesCompleted,
                    companyNameColumns,
                    companyNameColumnsName,
                    result.totalElements
                );

                if (this._isSorting) {
                    this._isSorting = false;
                }
            })
            .catch((error: any): void => {
                if (this.invoicesCompleted !== undefined) {
                    this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                }
                if (this._isSorting) {
                    this._isSorting = false;
                } else {
                    this.noInformation = true;
                }
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getPaidedInvoiceFile()
     * @param (id: any)
     * @description Get the paid invoices PDF by ID
     */

    getPaidedInvoiceFile(id: any): void {
        this._ngxSpinnerService.show();

        this._restService
            .getFile(`${this._invoicesUrl}/downloadInvoices?downloadFormat=PDF&invoicesIds=${id}`, {})
            .subscribe({
                next: (fileResponse: any): void => {
                    const link: HTMLAnchorElement = document.createElement('a');
                    const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
                    const url: any = this._domSanitizer.sanitize(
                        SecurityContext.URL,
                        this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                    );
                    const extension = this._restService.getExtension(fileResponse.type);
                    link.href = url;
                    link.download = `INV${id}` + extension;
                    link.click();
                    window.URL.revokeObjectURL(url);

                    this._ngxSpinnerService.hide();
                },
                error: (err: any): void => {
                    let message: string = '';
                    if (err.status === 404 && (err.statusText === 'Not Found' || err.statusText === 'OK')) {
                        message = this._errorHandlerService.errorMsg(
                            JSON.parse(new TextDecoder('utf-8').decode(err.error as ArrayBuffer))
                        );
                    } else {
                        message = this._errorHandlerService.errorMsg(err.error);
                    }
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${message}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method getPaidedInvoicePDF()
     * @param (items: any)
     * @description Get the open invoices PDF
     */

    getPaidedInvoicePDF(items: any): void {
        this._ngxSpinnerService.show();
        this._restService
            .getFiles(this._invoicesUrl + '/downloadInvoices?downloadFormat=PDF&invoiceType=PAID' + items, {})
            .then((result: any): void => {
                const now: Date = new Date();
                const fileName: string = `paidInvoices-${now.getDate()}-${now.getMonth()}-${now.getFullYear()}`;
                const $link = this._renderer.createElement('a');
                const file: Blob = new Blob([result], { type: 'application/zip' });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                this._renderer.setAttribute($link, 'href', url);
                this._renderer.setAttribute($link, 'download', fileName);
                $link.click();
            })
            .catch((err: any) => {
                let message: string = '';
                if (err.status === 404 && (err.statusText === 'Not Found' || err.statusText === 'OK')) {
                    message = this._errorHandlerService.errorMsg(
                        JSON.parse(new TextDecoder('utf-8').decode(err.error as ArrayBuffer))
                    );
                } else {
                    message = this._errorHandlerService.errorMsg(err.error);
                }
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${message}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            })
            .finally((): void => {
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getPaidedInvoiceCSV()
     * @param (items: any)
     * @description Get the open invoices CSV
     */

    getPaidedInvoiceCSV(items: any): void {
        this._ngxSpinnerService.show();
        this._restService
            .getFiles(this._invoicesUrl + '/downloadInvoices?downloadFormat=XLSX&invoiceType=PAID' + items, {})
            .then((result: any): void => {
                const now: Date = new Date();
                const fileName: string = `paidInvoices-${now.getDate()}-${now.getMonth()}-${now.getFullYear()}`;
                const $link = this._renderer.createElement('a');
                const file: Blob = new Blob([result], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                this._renderer.setAttribute($link, 'href', url);
                this._renderer.setAttribute($link, 'download', fileName);
                $link.click();
            })
            .catch((err: any): void => {
                let message: string = '';
                if (err.status === 404 && (err.statusText === 'Not Found' || err.statusText === 'OK')) {
                    message = this._errorHandlerService.errorMsg(
                        JSON.parse(new TextDecoder('utf-8').decode(err.error as ArrayBuffer))
                    );
                } else {
                    message = this._errorHandlerService.errorMsg(err.error);
                }
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${message}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            })
            .finally((): void => {
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method download()
     * @param (event: any)
     * @description Get the open invoices based on start and end date and downloads the records
     */

    downloadRecords(event: any): void {
        let ids: string = '';
        let dates: string = '';
        if (event) {
            ids = this.getIds();
            dates = event.from && event.to ? `&startDate=${event.from}&endDate=${event.to}` : '';
            if (event.type == 'pdf') {
                this.getPaidedInvoicePDF(dates ? dates : ids);
            } else {
                this.getPaidedInvoiceCSV(dates ? dates : ids);
            }
        }
    }

    /**
     * @method itemsSelected()
     * @param (items: any)
     * @description Return the id of the selected items
     */

    itemsSelected(items: any): void {
        this.recordsSelected = items;
        if (items) {
            items
                .map((item: any) => {
                    return item.id;
                })
                .join(',');
        }
    }

    /**
     * @method getIds()
     * @description
     */

    getIds(): string {
        let items: string = '';
        if (this.recordsSelected && this.recordsSelected.length > 0) {
            items = '&invoicesIds=';
            items += this.recordsSelected
                .map((item: any) => {
                    return item.id;
                })
                .join(',');
        }
        return items;
    }

    /**
     * @method openDialog()
     * @param (templateRef: any)
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'download',
            disableClose: true,
        });
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        this._dynamicFields = [];
        this.dynamicFieldsService
            .getFieldsReplaced()
            .pipe(take(1))
            .subscribe({
                next: (fieldsReplaced) => {
                    this._dynamicFields = fieldsReplaced;
                },
            });
    }
}
