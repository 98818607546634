<!--START: PAYMENT DETAIL -->
<div id="standard_flux" class="mt-4">
    <div id="standard_flux--container">
        <div class="mt-4 d-block">
            <h6 *ngIf="!hasExternalLookup" class="step_section mb-4">Payment detail</h6>
            <!--START: FORM -->
            <form class="row" [formGroup]="paymentForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <!--START: PAYMENT TYPE -->
                    <app-payment-type
                        *ngIf=" !startingLevel && !hasExternalLookup"
                        (returnPaymentType)="setPaymentType($event)"
                        [formValidState]="formValidStateParent"
                    ></app-payment-type>
                    <!--END: PAYMENT TYPE -->
                    <!--START: AWB -->
                    <app-awb
                        *ngIf="facilityData && !hasExternalLookup"
                        (returnAWB)="setAWB($event)"
                        (loading)="updateLoading($event)"
                        [formValidState]="formValidStateParent"
                    ></app-awb>
                    <!--END: AWB -->
                    <!--START: AMOUNT TO PAY -->
                    <app-amount-to-pay
                        *ngIf="!hasExternalLookup"
                        (returnAmountToPay)="setAmount($event)"
                        [formValidState]="formValidStateParent"
                    ></app-amount-to-pay>
                    <!--END: AMOUNT TO PAY -->
                    <!--START: ADD ONE OR MORE HAWB -->
                    <app-add-more-containers
                        *ngIf="!hasExternalLookup"
                        (returnDataContainer)="setHAWB($event)"
                        [isDinamycField]="hawbIsReplacement|async"
                        [formValidState]="formValidStateParent"
                    ></app-add-more-containers>
                    <!--END: ADD ONE OR MORE HAWB -->
                    <!--START: VIN EDOC NEXTGEN -->
                    <app-vin-edoc-nextgen
                        *ngIf="companyName === companyNameConst.geodis || geodisDomain"
                        (returnVinEdocNextgen)="setVinEdocNextgen($event)"
                        [formValidState]="formValidStateParent"
                    ></app-vin-edoc-nextgen>
                    <!--END: VIN EDOC NEXTGEN -->
                    <!--START: DYNAMIC FIELDS-->
                    <app-information-collector-by-payment [section]="section"></app-information-collector-by-payment>
                    <!--END: DYNAMIC FIELDS-->
                    <!--START: UPLOAD FILE -->
                    <ng-container *ngxPermissionsExcept="'GUEST'">
                        <app-upload-file-flux
                            [isDinamycField]="nameFilesIsReplacement|async"
                            (returnUploadFile)="setUploadFile($event)"
                            [formValidState]="formValidStateParent"
                        ></app-upload-file-flux>
                    </ng-container>
                    <!--END: UPLOAD FILE -->
                </div>
            </form>
            <!--END: FORM -->
        </div>
        <ng-container *ngIf="!hasExternalLookup">
            <!--START: STEPPER NEXT AND BACK BUTTON -->
            <div class="row">
                <ng-container *ngIf="onEdition">
                    <div class="col-sm-12 col-md-3 offset-md-9">
                        <div class="d-flex justify-content-end w-100">
                            <button
                                mat-button
                                type="button"
                                (mousedown)="checkout()"
                                [ngClass]="(paymentForm.invalid || !dynamicFormValid) ? 'btn__sprintpay--greenDisabled' : ''"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!onEdition">
                    <div class="col-sm-12 col-md-6 mb-3 mb-lg-0">
                        <div class="d-flex justify-content-start w-100">
                            <button
                                matStepperPrevious
                                mat-button
                                type="button"
                                (click)="backStep()"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="d-flex justify-content-end w-100">
                            <button
                                mat-button
                                type="button"
                                (mousedown)="checkout()"
                                [ngClass]="(paymentForm.invalid || !dynamicFormValid) ? 'btn__sprintpay--greenDisabled' : ''"
                                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                            >
                                <div style="display: flex; align-items: center; justify-content: center">
                                    <mat-icon *ngIf="loading"
                                        ><mat-spinner diameter="20" color="warn"> </mat-spinner
                                    ></mat-icon>
                                    <span>Next</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--END: STEPPER NEXT AND BACK BUTTON -->
        </ng-container>
    </div>
</div>
<!--END: PAYMENT DETAIL -->
