import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { startSpan } from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

startSpan(
    {
        name: 'platform-browser-dynamic',
        op: 'ui.angular.bootstrap',
    },
    async () => {
        await platformBrowserDynamic().bootstrapModule(AppModule);
    }
);
