import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerErrorService } from '@cargos/sprintpay-services';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
    constructor(private loggerErrorService: LoggerErrorService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const httpHeaders = request.clone({
            headers: request.headers,
        });
        this.loggerErrorService.addBreadcrumb({
            type: 'info',
            level: 'info',
            message: 'Failed request',
            data: { payload: request.body, params: request.params },
        });
        return next.handle(httpHeaders);
    }
}
