import { Injectable } from '@angular/core';
import { Customer } from '@cargos/sprintpay-models';
import { permissionsName } from '../../utils/constants';
import { CustomerService } from './user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    constructor(private _customerService: CustomerService) {}

    /**
     * @method getUserType();
     * @description return the user type
     */

    getUserType(): string {
        const customer = this._customerService.getCustomer();
        if (customer && customer.isGuest) {
            return permissionsName.guest;
        }
        let userType: string = permissionsName.ordinary;
        if (customer && customer.userType !== permissionsName.ordinary) {
            if (customer.approvalLevels && customer.approvalLevels.company) {
                userType = customer.approvalLevels.company.name + '_' + customer.userType;
            }
        }
        return userType;
    }

    /**
     * @method getComponentsByUser()
     * @description returns the components that are available for the user
     */

    getComponentsByUser(): string[] {
        let strNames: string[] = [];
        let strComponents: string[] = [];
        let strFeatures: string[] = [];
        const customer = this._customerService.getCustomer();
        if (customer && customer.isGuest) {
            return ['new-payment', 'cart'];
        }
        const customerProfile = this._customerService.getCustomerProfile();
        if (customer && customerProfile?.components) {
            strComponents.push(
                customerProfile.components.map((item: any) => {
                    return item.name;
                })
            );
            if (customer && customer.featureFlags?.length) {
                customer.featureFlags.map((item: string) => {
                    strFeatures.push(item);
                }) || [];
            }
            strNames = strNames.concat(...strFeatures, ...strComponents);
        }
        return strNames;
    }

    /**
     * @method verifyComponentsSecurity
     * @param componentID
     * @returns boolean is the component is available for the user
     */

    verifyComponentsSecurity(componentID: string): boolean {
        const components: string[] = this.getComponentsByUser();
        return !!(components && components.indexOf(componentID) !== -1);
    }

    /**
     * @method getProfileType();
     * @description return the profile type for the super admin
     */

    getProfileType(): string {
        const customer: Customer | null = this._customerService.getCustomer();
        const profileType: string = customer?.profileType || '';

        return profileType;
    }

    /**
     * @method getPermissionsBySuperUser()
     * @description returns the components that are available for the user
     */

    getPermissionsBySuperUser(): string[] {
        let strNames: string[] = [];
        const strPermissions: string[] = [];
        const superPermissions = this._customerService.getSuperPermissions();

        if (superPermissions) {
            strPermissions.push(
                superPermissions.map((item: any) => {
                    return item.name;
                })
            );

            strNames = strNames.concat(...strPermissions);
        }

        return strNames;
    }

    /**
     * @method verifyComponentsSecurity
     * @param permissionID
     * @description
     */

    verifyPermissionsSecurity(permissionID: string): boolean {
        const permissions: string[] = this.getPermissionsBySuperUser();
        return !!(permissions && permissions.indexOf(permissionID) !== -1);
    }
}
