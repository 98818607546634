import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import {
    DynamicFieldDTO,
    DynamicFieldValidationDTO,
    ValidationList,
} from 'src/app/models/ui/dynamic-field-common.model';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { RestService } from 'src/app/services/rest.service';
import { DynamicEngine } from 'src/app/utils/dynamic-engine';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { PaymentService } from '../../services/payment.service';
import { PaymentFluxService } from '../../services/utils/payment-flux.service';
import { CustomerService } from '../../services/utils/user/customer-handler.service';
import { DynamicContainerComponent } from '../dynamic-fields/dynamic-container/dynamic-container.component';

@Component({
    selector: 'app-dynamic-form',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, DynamicContainerComponent],
    templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnDestroy {
    @Output() paymentFormValid: EventEmitter<any> = new EventEmitter<any>();
    @Output() dynamicFields: EventEmitter<any> = new EventEmitter<any>();
    @Input() section!: string;
    public paymentForm!: FormGroup;
    public formNamePaymentDetails: string;
    public fieldsPaymentDetails: DynamicFieldDTO[];
    public matcher: ErrorMatcher;
    public company: any;
    urlNextStep: string;
    private paymentDetailsEngine: DynamicEngine;
    private readonly currentPayment: Payment | null;
    private readonly approvalLevels: any;
    private unsubscribe$: Subject<void>;

    constructor(
        private _paymentService: PaymentService,
        private _customerService: CustomerService,
        private _restService: RestService,
        private _paymentFluxService: PaymentFluxService,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this.unsubscribe$ = new Subject<void>();
        const customer = this._customerService.getCustomer();
        this.company = customer?.approvalLevels?.company || '';
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.urlNextStep =
            this.currentPayment && this.currentPayment.redirectRoute
                ? this.currentPayment.redirectRoute
                : this.company.name
                  ? '/admin/facilityPayments/newPayment/flux/paymentDetails'
                  : '/admin/facilityPayments/newPayment/flux/customerReference';
        this.matcher = new ErrorMatcher();
        this.fieldsPaymentDetails = [];
        this.formNamePaymentDetails = 'paymentForm';
        this.paymentDetailsEngine = new DynamicEngine(this._restService, this._paymentFluxService);
        this.approvalLevels = customer?.approvalLevels
            ? customer.approvalLevels.company?.paymentReference?.map((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
    }

    ngOnInit(): void {
        this.getFormFields();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method onFormValid()
     * @param (status: string)
     * @param (value: { [key: string]: string })
     * @description
     */

    onFormValid(status: string, value: { [key: string]: string }): void {
        let dynamic: { [key: number]: string } = {};
        Object.keys(value).forEach((key: string): void => {
            let field: DynamicFieldDTO | undefined = this.fieldsPaymentDetails.find(
                (item: DynamicFieldDTO): boolean => item.name === key
            );
            field?.id && (dynamic[field.id] = value[key]);
        });
        this.paymentFormValid.emit({ status, value, dynamic });
    }

    /**
     * @method getFormFields()
     * @description
     */

    getFormFields(): void {
        if (this.company !== '') {
            this._paymentService
                .getFormFields(this.section)
                .then((result: DynamicFieldDTO[]): void => {
                    this.dynamicFields.emit(result.length);
                    result.map((field: DynamicFieldDTO) => {
                        const validations = field.validations
                            ?.filter(
                                (validation: DynamicFieldValidationDTO): boolean => validation.type === 'VALIDATION'
                            )
                            .reduce((acc: ValidationList, item: DynamicFieldValidationDTO) => {
                                acc[item.name!] = {
                                    value: item.value === 'true' ? true : item.value || '',
                                    errorMessage: item.message || '',
                                };
                                return acc;
                            }, {});
                        const attributes = field.validations
                            ?.filter(
                                (validation: DynamicFieldValidationDTO): boolean => validation.type === 'ATTRIBUTE'
                            )
                            .reduce((acc: ValidationList, item: DynamicFieldValidationDTO) => {
                                acc[item.name!] = {
                                    value: item.value === 'true' ? true : item.value || '',
                                    errorMessage: item.message || '',
                                };
                                return acc;
                            }, {});
                        const code = field.validations
                            ?.filter((validation: DynamicFieldValidationDTO): boolean => validation.type === 'CODE')
                            .reduce((acc: ValidationList, item: DynamicFieldValidationDTO) => {
                                acc[item.name!] = {
                                    value: item.value === 'true' ? true : item.value || '',
                                    errorMessage: item.message || '',
                                };
                                return acc;
                            }, {});
                        field['type'] = field.fieldType === 'file' ? field.fieldType : 'text';
                        field['disabled'] = field.isDisabled;
                        field['inputValidations'] = validations;
                        field['attribute'] = attributes;
                        field['code'] = code;
                        return field;
                    });
                    result.forEach((item: DynamicFieldDTO): void => {
                        if (item.hasOwnProperty('isReplacement') && item.isReplacement === true) {
                            result = result.filter((field: DynamicFieldDTO): boolean => field.name !== item.name);
                        }
                    });
                    this.dynamicFields.emit(result.length);
                    this.fieldsPaymentDetails = result;
                    this._paymentFluxService.setField(this.section, this.fieldsPaymentDetails);
                    this.paymentForm = this.paymentDetailsEngine.setFormBuilder(
                        this.formNamePaymentDetails,
                        this.fieldsPaymentDetails
                    );
                    this.onFormValid(
                        this.paymentForm.status,
                        this.paymentForm.status === 'VALID' ? this.paymentForm.value.paymentForm : ''
                    );
                    this.paymentForm.statusChanges.subscribe((status: string): void => {
                        const formValue = status === 'VALID' ? this.paymentForm.value.paymentForm : '';
                        this.onFormValid(status, formValue);
                    });
                })
                .catch((error: Error): void => {
                    this.dynamicFields.emit(0);
                    throw error;
                });
        } else {
            this.dynamicFields.emit(0);
        }
    }
}
