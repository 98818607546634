import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { FacilityService } from 'src/app/services/facility.service';
import { Domains, paymentTypes, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../../../../../services/utils/user/customer-handler.service';
import { SummaryDetailService } from '../../summary-detail.service';

@Component({
    selector: 'app-payment-type',
    standalone: true,
    imports: [CommonModule, MatSelectModule, MatInputModule, FormsModule, MatFormFieldModule, ReactiveFormsModule],
    templateUrl: './payment-type.component.html',
})
export class PaymentTypeComponent implements OnInit, OnChanges {
    paymentForm!: FormGroup;
    paymentTypes!: string[];
    disabledInput: boolean = false;
    matcher: ErrorMatcher = new ErrorMatcher();
    private readonly currentPayment: Payment | null;

    @Input() formValidState: any;
    @Output() returnPaymentType: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _facilityService: FacilityService,
        private _summaryDetailService: SummaryDetailService
    ) {
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.getPaymentTypes();
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this.currentPayment?.details && this.currentPayment.details.paymentType) {
            this.paymentForm.get('paymentType')?.setValue(this.currentPayment?.details?.paymentType);
            this.returnPaymentType.emit(this.paymentForm.controls['paymentType'].value);
        }
    }

    getPaymentTypes(): void {
        const facility = this.currentPayment?.facility || null;
        const facilityId = facility?.id || '';
        this._facilityService
            .getPaymentTypes(facilityId)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.paymentTypes = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.paymentTypes = this._customerService.isCustomerEmailInDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : JSON.parse(JSON.stringify(paymentTypes));
                    }
                    if (this.currentPayment?.details?.paymentType) {
                        this.verifyIfPaymentTypeExists(this.currentPayment.details.paymentType);
                    }
                },
            });
    }

    verifyIfPaymentTypeExists(currentPaymentType: string): void {
        if (!this.paymentTypes.includes(currentPaymentType)) {
            this.paymentTypes.push(currentPaymentType);
            this.disabledInput = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.paymentType.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            paymentType: ['', Validators.required],
        });
    }

    /**
     * @method getPaymentType()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentType(): FormGroup {
        return this.paymentForm.get('paymentType') as FormGroup;
    }

    /**
     * @method setPaymentType()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setPaymentType(event: any) {
        this.paymentType!.setValue(event);
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method change()
     * @param (event: any)
     * @description
     */

    change(event: any): void {
        this._summaryDetailService.setPaymentType(event.source.value);
        this.returnPaymentType.emit(event.source.value);
    }
}
