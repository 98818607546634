<!--START: ACTIVATE -->
<section id="activate" *ngIf="authorizationToken !== undefined">
    <div class="activate__container d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-8 offset-md-2">
                    <!--START: ACTIVATE AUTHORIZATION TOKEN -->
                    <ng-container id="success" *ngIf="!!authorizationToken && (activationType === 'signup' || isSSO)">
                        <!--START: IMAGES -->
                        <div class="col-12 col-md-6 mx-auto">
                            <figure>
                                <img
                                    class="d-block mx-auto my-0 w-50"
                                    alt="SprintPay logo powered by CargoSprint"
                                    src="assets/images/activate/sp-logo-v2.svg"
                                />
                            </figure>
                            <figure class="mb-5">
                                <img
                                    class="d-block mx-auto my-0 w-100"
                                    alt="Individual illustration"
                                    src="assets/images/activate/individual-ilustration.svg"
                                />
                            </figure>
                        </div>
                        <!--END: IMAGES -->
                        <!--START: HEADRERS -->
                        <div class="activate__headers d-flex flex-column gap-4 pt-3">
                            <h4 class="text-center fs-1 mb-0 lh-1">Get started</h4>
                            <div class="">
                                <h6 class="text-center mb-0">All done! You're ready to make payment requests</h6>
                                <p class="text-center mb-0">Redirect to home...<br /></p>
                            </div>
                            <p id="timer" class="text-center mb-0 fw-bold">{{timer}}</p>
                        </div>
                        <!--EDN: HEADRERS -->
                    </ng-container>
                    <!--END: ACTIVATE AUTHORIZATION TOKEN -->
                    <!--START: ACTIVATE NO AUTHORIZATION TOKEN -->
                    <ng-container id="error" *ngIf="!authorizationToken && (activationType === 'signup' || isSSO)">
                        <!--START: IMAGES -->
                        <figure>
                            <img
                                class="d-block mx-auto my-0"
                                alt="SprintPay logo powered by CargoSprint"
                                src="assets/images/activate/sp-logo-v2.svg"
                            />
                        </figure>
                        <figure>
                            <img class="d-block mx-auto my-0" alt="Clock" src="assets/images/activate/clock.svg" />
                        </figure>
                        <!--END: IMAGES -->
                        <!--START: HEADRERS -->
                        <div class="activate__headers">
                            <h4 class="text-center">We couldn't complete your sign up :(</h4>
                            <p class="text-center fw-bolder mb-4">
                                The link we sent to your email to sign up expired so you'll have to reiniate the
                                process.
                            </p>
                        </div>
                        <!--EDN: HEADRERS -->
                        <!--START: BODY -->
                        <div class="row">
                            <div class="col-12">
                                <button
                                    mat-button
                                    type="button"
                                    class="d-block mx-auto border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green"
                                    [routerLink]="['/signup/sprintpay/account-type-individual']"
                                >
                                    Sign up
                                </button>
                            </div>
                        </div>
                        <!--END: BODY -->
                    </ng-container>
                    <!--END: ACTIVATE NO AUTHORIZATION TOKEN -->
                </div>
            </div>
        </div>
    </div>
</section>
<!--END: ACTIVATE -->
