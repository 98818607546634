import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { LoggerErrorService } from '@cargos/sprintpay-services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, combineLatest, finalize, switchMap, take, takeUntil, tap } from 'rxjs';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import Swal from 'sweetalert2';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';

@Component({
    selector: 'app-cart-items',
    templateUrl: './cart-items.component.html',
    styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent implements OnInit, OnDestroy {
    public deletingCart = false;
    public lengthCart: number = 0;
    public paymentRequest: PaymentCart[] = [];
    public labelItemsInCart: string;
    public isThereInvoicesInCart: boolean;
    public expandedPanel: boolean = false;
    private isGuest: boolean = false;
    private unsubscribe$: Subject<void>;

    @Input() aboveThreshold: boolean = false;

    constructor(
        private cartBillService: CartBillService,
        private ngxSpinnerService: NgxSpinnerService,
        private errorHandlerService: ErrorHandlerService,
        private initialConfigService: InitialConfigService,
        private customerService: CustomerService,
        private loggerErrorService: LoggerErrorService
    ) {
        this.unsubscribe$ = new Subject<void>();
        this.isGuest = this.customerService.isGuest();
        this.setInitialPanelConfig();
    }

    ngOnInit(): void {
        this.subscribeCart();

        this.isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();
        this.labelItemsInCart = this.isThereInvoicesInCart ? 'Invoices' : 'transactions';
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setInitialPanelConfig(): void {
        combineLatest([this.cartBillService.isCartAboveThreshold$(), this.cartBillService.isCartBelowThreshold$()])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([isCartAboveThreshold, isCartBelowThreshold]: [boolean, boolean]) => {
                if (
                    this.isGuest ||
                    (isCartAboveThreshold && !isCartBelowThreshold) ||
                    (!isCartAboveThreshold && isCartBelowThreshold)
                ) {
                    this.expandedPanel = true;
                }
            });
    }

    deleteCart(): void {
        const ids = this.getIds();
        if (!ids) {
            return;
        }
        this.deletingCart = true;
        this.ngxSpinnerService.show();
        if (this.paymentRequest && this.paymentRequest[0].id) {
            this.cartBillService
                .deletePaymentRequests(ids)
                .pipe(
                    take(1),
                    tap(() => {
                        if (this.aboveThreshold) {
                            this.loggerErrorService.captureException(
                                new Error('Multiple XL Transactions deleted from cart'),
                                {
                                    tags: { multipleXLTransactionsDeletedFromCart: true },
                                    level: 'info',
                                    extra: {
                                        totalCart: this.cartBillService.getTotal(),
                                        awbs: this.cartBillService.getAWBsInCart(),
                                    },
                                }
                            );
                        }
                    }),
                    switchMap(() => {
                        // ! Test this feature, to validate if the cart is updated afer the delete - Nancy
                        return this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1));
                    }),
                    finalize(() => {
                        this.deletingCart = false;
                        this.ngxSpinnerService.hide();
                    })
                )
                .subscribe({
                    error: (error: any) => {
                        this.deletingCart = false;
                        Swal.fire({
                            html: `${this.errorHandlerService.errorMsg(error.error)}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }

    getIds(): number[] {
        return this.paymentRequest?.map((paymentCart) => paymentCart.id || 0) || [];
    }

    subscribeCart(): void {
        this.cartBillService
            .getCartPaymentRequests$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentRequests) => {
                    this.paymentRequest = paymentRequests;
                    this.lengthCart = paymentRequests.length;
                },
            });
    }
}
