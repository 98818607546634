import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentMethod, PaymentMethods, PaymentMethodsType } from '@cargos/sprintpay-models';
import { Observable, Subject, map, of, switchMap, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { PaymentMethodComplete } from 'src/app/services/summary/models/types';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { UserSessionService } from 'src/app/services/utils/user/user-session.service';
import { LastFourDigitsComponent } from 'src/app/standalone-components/last-four-digits/last-four-digits.component';
import { CardTypeComponent } from 'src/app/standalone-components/logo-credit-card/card-type.component';
import { LogoPaymentMethodComponent } from 'src/app/standalone-components/logo-payment-method/logo-payment-method.component';

interface PaymentMethodSelected {
    paymentMethodImage: string;
    paymentMethodValue: string;
    isCredit: boolean;
    paymentSelectedName?: PaymentMethodsType;
}

@Component({
    standalone: true,
    selector: 'app-payment-method-selected',
    templateUrl: './payment-method-selected.component.html',
    styleUrls: ['./payment-method-selected.component.scss'],
    imports: [CommonModule, LastFourDigitsComponent, LogoPaymentMethodComponent, CardTypeComponent],
})
export class PaymentMethodSelectedComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    public paymentSelectedName?: PaymentMethodsType;
    public paymentMethodsEnum = PaymentMethods;
    public paymentMethodImage = '';
    public paymentMethodValue = '';
    public isCredit: boolean = false;
    public isAuthenticated: boolean = false;
    public isEnoughCredit: boolean = false;
    public isThereInvoicesInCart: boolean = false;

    @Input() showValue = true;
    @Input() width = '80px';
    @Input() aboveThreshold: boolean = false;

    constructor(
        private summaryService: SummaryService,
        private userSessionService: UserSessionService,
        private customerFeaturesService: CustomerFeaturesService,
        private cartBillService: CartBillService
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribePaymentMethodSelected();
        this.subscribeIsAllowedToPayWithCargoCredit();
        this.isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribePaymentMethodSelected(): void {
        this.getPaymentMethodSelected()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: ({ paymentMethodImage, paymentMethodValue, isCredit, paymentSelectedName }) => {
                    this.paymentMethodImage = paymentMethodImage;
                    this.paymentMethodValue = paymentMethodValue;
                    this.isCredit = isCredit;
                    this.paymentSelectedName = paymentSelectedName;
                },
            });
    }

    getPaymentMethodSelected(): Observable<PaymentMethodSelected> {
        return this.userSessionService.isAuthenticated$().pipe(
            switchMap((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
                if (isAuthenticated) {
                    return this.getAuthPaymentMethod();
                }

                return this.getGuestPaymentMethod$();
            })
        );
    }

    getAuthPaymentMethod(): Observable<PaymentMethodSelected> {
        return this.summaryService.getPaymentMethodSelectedComplete$(this.aboveThreshold).pipe(
            map((paymentSelected: PaymentMethodComplete | null) => {
                const paymentSelectedName = paymentSelected?.name;
                let paymentMethodImage: string = '';
                let paymentMethodValue: string = '';
                let isCredit: boolean = false;

                if (paymentSelected?.paymentMethodComplete instanceof PaymentMethod) {
                    if (
                        paymentSelected?.paymentMethodComplete instanceof PaymentMethod &&
                        paymentSelected?.name === PaymentMethods.PAYPAL
                    ) {
                        paymentMethodImage = PaymentMethods.PAYPAL;
                        isCredit = false;
                        paymentMethodValue = 'Paypal';
                    } else {
                        paymentMethodImage = (paymentSelected?.name as string) || '';
                        paymentMethodValue =
                            paymentSelected?.paymentMethodComplete.balance?.avaliableCredit?.toString() || '0';
                        isCredit = true;
                    }
                } else if (paymentSelected?.name === PaymentMethods.CREDIT_CARD) {
                    paymentMethodImage = paymentSelected?.paymentMethodComplete?.accountingDetails.cardType as string;
                    paymentMethodValue = paymentSelected?.paymentMethodComplete?.accountingDetails.lastFourDigits || '';
                    isCredit = false;
                } else if (paymentSelected?.name === PaymentMethods.ECHECK) {
                    paymentMethodImage = paymentSelected?.name as string;
                    paymentMethodValue =
                        paymentSelected?.paymentMethodComplete?.accountingDetails.accountLastFourDigits || '';
                    isCredit = false;
                }

                return {
                    paymentMethodImage,
                    paymentMethodValue,
                    isCredit,
                    paymentSelectedName,
                };
            })
        );
    }

    getGuestPaymentMethod$(): Observable<PaymentMethodSelected> {
        return this.summaryService.getPaymentMethodSelected$().pipe(
            switchMap((paymentMethodSelected) => {
                if (paymentMethodSelected?.method === PaymentMethods.CREDIT_CARD) {
                    return this.summaryService.getGuestPaymentInformation$().pipe(
                        map((guestPaymentInformation) => {
                            const paymentSelectedName: PaymentMethodsType | undefined =
                                paymentMethodSelected?.method || undefined;
                            const paymentMethodImage: string =
                                guestPaymentInformation?.creditCard?.details.cardType || '';
                            const paymentMethodValue: string =
                                guestPaymentInformation?.creditCard?.details.lastFour || '';
                            const isCredit = false;

                            return {
                                paymentMethodImage,
                                paymentMethodValue,
                                isCredit,
                                paymentSelectedName,
                            } as PaymentMethodSelected;
                        })
                    );
                }

                const paymentSelectedName: PaymentMethodsType | undefined = paymentMethodSelected?.method || undefined;
                const paymentMethodImage: string = PaymentMethods.PAYPAL;

                return of({
                    paymentMethodImage,
                    paymentMethodValue: 'Paypal',
                    isCredit: false,
                    paymentSelectedName,
                } as PaymentMethodSelected);
            })
        );
    }

    subscribeIsAllowedToPayWithCargoCredit(): void {
        this.customerFeaturesService
            .isAllowedToPayWithCargoCredit$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isAllowedToPayWithCargoCredit) => {
                this.isEnoughCredit = !!isAllowedToPayWithCargoCredit.isEnoughCredit;
            });
    }
}
