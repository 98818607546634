import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/overlay';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, finalize, map, of, switchMap, take, takeUntil } from 'rxjs';
import { UserData } from 'src/app/models/auth/signup.model';
import { errorDetails } from 'src/app/models/http/error.model';
import { ResponseMFA } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { CustomerAPIService } from 'src/app/services/requests';
import { SignUpAPIService } from 'src/app/services/requests/signup-api.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { regex, signupErrors } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { FormSignupService } from '../services/form-signup.service';
import { AccountFormGroupType } from './models/account-form';
import { GuestInformationResponse } from './models/guest';
import { PersonalFormGroupType } from './models/personal-form';
import { UserSignUpFormValues } from './models/signup';
import { StepOneComponent } from './step-one/step-one.component';

@Component({
    selector: 'app-form-signup',
    templateUrl: './form-signup.component.html',
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class FormSignupComponent implements OnInit, AfterViewInit {
    public signUpForm!: FormGroup;
    public personalForm!: PersonalFormGroupType;
    public accountForm!: AccountFormGroupType;
    public facilityLogo: string = '';
    public facility: string;
    public emailValid = false;
    public readonly stepperOrientation: Observable<StepperOrientation>;
    public availableEdition: boolean = true;
    public isGuestUpgradeFlow: boolean = false;
    public isForgotPasswordFlow: boolean = false;
    private bootstrapLgScreenSize: number;
    private readonly authentication: string;
    private unsubscribe$: Subject<void>;
    private activationUuid: string = '';
    private guestInformation: GuestInformationResponse | null = null;

    @ViewChild('stepper') stepper!: MatStepper;
    @ViewChild('twoFactorModal') twoFactorModal?: ComponentType<unknown>;
    @ViewChild('stepOne') stepOneComponent?: StepOneComponent;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private formBuilder: NonNullableFormBuilder,
        private ngxSpinnerService: NgxSpinnerService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private errorHandlerService: ErrorHandlerService,
        private formSignupService: FormSignupService,
        private signUpAPIService: SignUpAPIService,
        private matDialog: MatDialog,
        private location: Location,
        private summaryService: SummaryService,
        private customerAPIService: CustomerAPIService
    ) {
        this.authentication = environment.uris.method.authentication;
        this.facility = '';
        this.bootstrapLgScreenSize = 992;
        this.unsubscribe$ = new Subject<void>();
        this.stepperOrientation = breakpointObserver.observe(`(min-width: ${this.bootstrapLgScreenSize}px)`).pipe(
            map(({ matches }) => (matches ? 'horizontal' : 'vertical')),
            takeUntil(this.unsubscribe$)
        );
    }

    ngOnInit(): void {
        this.setFromBuilder();
        this.setFacility();
    }

    ngAfterViewInit(): void {
        this.loadGuestInformation();
    }

    setFacility(): void {
        this.activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: Params) => {
                this.facility = params['facility'].toLowerCase();
                if (this.facility === 'schenker') {
                    window.location.replace(environment.projects.sprintPayv7 + `/schenker/account-type`);
                    return;
                }
                const facility: any = {
                    'dhl-aviation': '/assets/images/facilities/dhl-logo.svg',
                    schenker: '/assets/images/facilities/schenker-logo.svg',
                    polar: '/assets/images/facilities/polar-logo.svg',
                    sprintpay: './assets/images/sprintpay-horizontal-logo.svg',
                    alliance: '/assets/images/facilities/alliance-logo-light.png',
                };
                this.facilityLogo = facility[params ? params['facility'] : 'sprintpay'];
            },
        });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private setFromBuilder(): void {
        this.personalForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(3)]],
            lastName: ['', [Validators.required, Validators.minLength(3)]],
            countryCode: [''],
            phone: [''],
            email: ['', [Validators.required, Validators.pattern(regex.email)]],
            emailDomain: [''],
        });

        this.accountForm = this.formBuilder.group({
            accountType: ['', []],
            addressForm: this.formBuilder.group({
                street: ['', [Validators.required]],
                street2: ['', []],
                city: ['', [Validators.required]],
                state: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
                country: ['', [Validators.required]],
                zipCode: ['', [Validators.required]],
            }),
            billingAddressForm: this.formBuilder.group({
                street: ['', []],
                street2: ['', []],
                city: ['', []],
                state: ['', []],
                country: ['', []],
                zipCode: ['', []],
            }),
            billingAddress: [false, []],
            companyForm: this.formBuilder.group({
                companyName: ['', []],
                companyId: ['', []],
                taxID: ['', []],
                branchLocation: ['', []],
            }),
        });
    }

    getUserData(data: UserSignUpFormValues, emailDomainExists: boolean): UserData {
        const guestUpgradeData = {
            ...(this.isValidGuestUpgrade ? { id: this.guestInformation?.id } : {}),
            ...(this.isValidGuestUpgrade && data?.companyForm?.branchLocation
                ? { branchId: Number(data?.companyForm?.branchLocation) }
                : {}),
        };

        const newData = {
            email: data.email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            countryCode: data.countryCode,
            phone: data.phone,
            notificationEmail: data.email,
            ...(this.isValidGuestUpgrade ? guestUpgradeData : {}),
        };

        if (data?.companyForm?.companyName) {
            if (emailDomainExists) {
                if (data?.companyForm?.branchLocation) {
                    if (this.isValidGuestUpgrade) {
                        return { ...newData };
                    }

                    return {
                        ...newData,
                        customerAccount: {
                            id: Number(data?.companyForm?.companyId),
                            branch: {
                                id: Number(data?.companyForm?.branchLocation),
                            },
                        },
                    };
                }

                if (this.isValidGuestUpgrade) {
                    return { ...newData, branchId: Number(data?.companyForm.companyId) };
                }

                return {
                    ...newData,
                    customerAccount: {
                        id: Number(data?.companyForm.companyId),
                    },
                };
            }

            return {
                ...newData,
                ...(data?.companyForm?.taxID ? { taxPayerIdentificationNumber: data?.companyForm?.taxID } : {}),
                ...(this.isValidGuestUpgrade
                    ? {
                          companyName: data?.companyForm?.companyName,
                      }
                    : {
                          customerAccount: {
                              accountName: data?.companyForm?.companyName,
                          },
                      }),
                shippingAddress: {
                    street: data.addressForm.street,
                    street2: data.addressForm.street2,
                    city: data.addressForm.city,
                    state: data.addressForm.state,
                    zipCode: data.addressForm.zipCode,
                    country: data.addressForm.country,
                },
                billingAddress: {
                    street: data.billingAddressForm.street,
                    street2: data.billingAddressForm.street2,
                    city: data.billingAddressForm.city,
                    state: data.billingAddressForm.state,
                    country: data.billingAddressForm.country,
                    zipCode: data.billingAddressForm.zipCode,
                },
            };
        }
        return {
            ...newData,
            shippingAddress: {
                street: data.addressForm.street,
                street2: data.addressForm.street2,
                city: data.addressForm.city,
                state: data.addressForm.state,
                zipCode: data.addressForm.zipCode,
                country: data.addressForm.country,
            },
            billingAddress: {
                street: data.billingAddressForm.street,
                street2: data.billingAddressForm.street2,
                city: data.billingAddressForm.city,
                state: data.billingAddressForm.state,
                country: data.billingAddressForm.country,
                zipCode: data.billingAddressForm.zipCode,
            },
        };
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    public onSubmit(event: any): void {
        const verification = event;
        if (
            this.accountForm.valid &&
            this.activationUuid === '' &&
            this.personalForm.valid &&
            verification.type &&
            verification.value
        ) {
            this.ngxSpinnerService.show();
            const personalInformation = this.formSignupService.getPersonalInformation();
            const accountInformation = this.formSignupService.getAccountInformation();
            const securityInformation = this.formSignupService.getSecurityInformation();

            const data = { ...personalInformation, ...accountInformation, ...securityInformation };
            const email = personalInformation.email;
            this.formSignupService
                .getEmailDomainExist()
                .pipe(
                    take(1),
                    map((emailDomainExists) => this.getUserData(data, emailDomainExists)),
                    switchMap((user) => {
                        if (this.isValidGuestUpgrade) {
                            return this.signUpAPIService.upgradeGuest(user);
                        }

                        return this.signUpAPIService.signUp(user);
                    }),
                    finalize(() => this.ngxSpinnerService.hide())
                )
                .subscribe({
                    next: (response: string) => {
                        this.availableEdition = false;
                        const objectResponse = JSON.parse(response);
                        if (this.isMfaNeeded) {
                            this.activationUuid = objectResponse.activationUuid;
                            this.openDialog(verification.type, verification.value, objectResponse.activationUuid);
                        } else {
                            this.modalAccountVerified({ isVerified: true });
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        if (typeof error?.error === 'string') {
                            const errorParse = JSON.parse(error.error);
                            if (typeof errorParse !== 'string' && errorParse.error && errorParse.error.data) {
                                if (errorParse.error?.data?.isActive) {
                                    Swal.fire({
                                        title: errorParse.error?.title,
                                        text: errorParse.error?.body,
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Forgot password?',
                                        cancelButtonText: 'Sign in',
                                        showCloseButton: true,
                                        confirmButtonColor: '#14bb9c',
                                        allowOutsideClick: false,
                                    }).then((result) => {
                                        if (result.value) {
                                            this.router.navigate(['/password/forgot-password']);
                                        }
                                        if (result.isDismissed) {
                                            this.redirectToLogin();
                                        }
                                    });
                                }
                                if (!errorParse.error?.data.isActive) {
                                    Swal.fire({
                                        title: errorParse.error?.title,
                                        text: errorParse.error?.body,
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonText: 'Validate account',
                                        showCloseButton: true,
                                        confirmButtonColor: '#14bb9c',
                                        allowOutsideClick: false,
                                    }).then((result) => {
                                        if (result.value) {
                                            this.resendEmail(errorParse.error.data.activationKey, email);
                                        }
                                    });
                                }
                            } else {
                                const errorDetails = JSON.parse(error?.error)?.details;
                                this.modalError(error?.error, errorDetails);
                            }
                        } else {
                            this.modalError(error?.error);
                        }
                    },
                });
        }
        if (this.activationUuid !== '' && verification.type && verification.value) {
            this.openDialog(verification.type, verification.value, this.activationUuid);
        }
    }

    private resendEmail(activationKey: string, notificationEmail: string): void {
        this.signUpAPIService
            .sendEmail(this.facility, activationKey)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.modalSuccess(notificationEmail);
                },
                error: (error) => this.modalError(error && error.error),
            });
    }

    private modalSuccess(notificationEmail: string): void {
        Swal.fire({
            html: `<h4 class='swal2-title pt-0 mb-0' id='swal2-title'>Excellent...</h4>
             </br>One more thing, we send an email to <strong>${notificationEmail}</strong> to confirm your account and be able to make payments`,
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Close',
            allowOutsideClick: false,
        }).then(() => {
            this.redirectToLogin();
        });
    }

    private modalError(error: any, errorDetails: errorDetails | undefined = undefined): void {
        Swal.fire({
            html: `${this.errorHandlerService.errorTemplate(error)}`,
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            allowOutsideClick: false,
        }).then(() => {
            if (errorDetails?.errorCode === signupErrors.DUPLICATED_ACCOUNT_NAME) this.onDuplicatedCompany();
        });
    }

    private openDialog(mfaType: string, contact: string, activationUuid: string): void {
        this.matDialog.open(this.twoFactorModal as ComponentType<unknown>, {
            id: 'two-factor',
            disableClose: false,
            data: { mfaType, contact, activationUuid },
        });
    }

    public modalAccountVerified(event: ResponseMFA): void {
        if (event && event.isVerified) {
            this.availableEdition = !this.availableEdition;
            this.personalForm.reset();
            this.accountForm.reset();
            this.stepper.reset();
            this.summaryService.clearGuestData();
            Swal.fire({
                html: `<div class="row" style="padding: 0 2rem;">
                <!--START: Sprintpay or facility logo -->
                <div class="col-12 accountCreated">
                    <figure class="facility__logo mt-5 mb-5">
                        <img class="d-block mx-auto my-0 h-100" src="./assets/images/sp-logo.svg" alt="{{facility}} logo" />
                    </figure>
                    <figure class="account__logo my-4">
                        <img
                            class="d-block mx-auto my-0 h-100"
                            src="./assets/images/sprintpay.svg"
                            alt="Account illustration logo"
                        />
                    </figure>
                    <p class="d-block w-100 text-center title">Your account has been created successfully</p>
                    <p class="text-center info">You can now start using our payment platform.</p>
                </div>
                <!--END: Sprintpay or facility logo -->
            </div>
                `,
                showConfirmButton: true,
                width: '40em',
                confirmButtonText: 'GO TO SIGN IN',
                customClass: {
                    confirmButton: 'confirmButton',
                },
                allowOutsideClick: false,
            }).then(() => {
                this.redirectToLogin();
            });
        }
    }

    public goBack(): void {
        switch (this.facility) {
            case 'alliance':
                window.location.replace(environment.projects.alliance + `/login`);
                break;
            default:
                this.location.back();
                break;
        }
    }

    public redirectToLogin(): void {
        switch (this.facility) {
            case 'alliance':
                window.location.replace(environment.projects.alliance + `/login`);
                break;
            default:
                this.router.navigate(['/login']);
                break;
        }
    }

    private onDuplicatedCompany(): void {
        this.accountForm.get('companyForm')?.get('companyName')?.setErrors({ duplicatedCompany: true });
        this.stepper.selectedIndex = 1;
    }

    private loadGuestInformation(): void {
        this.activatedRoute.params
            .pipe(
                take(1),
                map((params) => {
                    this.isGuestUpgradeFlow = !!params['guestEmail'];
                    this.isForgotPasswordFlow = !!params['forgotPasswordEmail'];
                    return params['guestEmail'] || params['forgotPasswordEmail'] || '';
                }),
                switchMap((email) => (email ? this.customerAPIService.getGuestInformation(email) : of(null))),
                take(1)
            )
            .subscribe({
                next: (guestInformation) => {
                    if (guestInformation) {
                        this.guestInformation = guestInformation;
                        const { countryCode, phone } = guestInformation;

                        if (guestInformation?.address) {
                            this.accountForm.get('addressForm')?.patchValue(guestInformation.address);
                            this.accountForm.controls.billingAddress.patchValue(true);
                        }

                        if (guestInformation) {
                            this.personalForm.patchValue(guestInformation);
                            this.personalForm.get('email')?.disable();
                            this.stepOneComponent?.setPhoneInfo({ code: countryCode ?? '+1', phone });
                        }
                    } else {
                        this.guestInformation = null;
                        this.isGuestUpgradeFlow = false;
                        this.isForgotPasswordFlow = false;
                    }
                },
                error: (error: HttpErrorResponse) => {
                    Swal.fire({
                        html: `${this.errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    public get isMfaNeeded(): boolean {
        return this.isForgotPasswordFlow || !this.isGuestUpgradeFlow;
    }

    public get isValidGuestUpgrade(): boolean {
        return (this.isGuestUpgradeFlow || this.isForgotPasswordFlow) && !!this.guestInformation;
    }
}
