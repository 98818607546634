<form [formGroup]="formName">
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{field.label}}</mat-label>
        <input
            matInput
            type="text"
            matTooltipClass="custom-tooltip"
            [formControlName]="field.name || ''"
            [matTooltip]="field.tooltipMessage || ''"
            [readonly]="field.isDisabled"
        />
        <mat-error>
            <app-dynamic-error [field]="field"></app-dynamic-error>
        </mat-error>
    </mat-form-field>
</form>
