import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user/user-session.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
    public facilityType: string;
    public facilityLogo: string;

    constructor(
        private _tokenService: TokenService,
        private _errorHandlerService: ErrorHandlerService,
        private _userSessionService: UserSessionService
    ) {
        this.facilityType = '';
        this.facilityLogo = '';
    }

    ngOnInit(): void {
        this._signInAsGuest();
    }

    /**
     * @method _signInAsGuest()
     * @description:
     */

    private _signInAsGuest(): void {
        const token: string | null = this._tokenService.getCurrentUser();

        if (!token) {
            this._userSessionService
                .signInAsGuest()
                .pipe(take(1))
                .subscribe({
                    error: (error: any): void => {
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }
}
