import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsTokensType, RedirectUrl } from '@cargos/sprintpay-models';
import { LoginAsCargoSprintUserResponse } from '@cargos/sprintpay-services/lib/apis/sprintpay/auth/models/auth-types';
import { SPRINTPAY_PAYMENT_CONFIG } from '@cargos/sprintpay_frontend_core_api/lib/utils/init';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { LogoutResponse } from 'src/app/models/auth/logout.model';
import { LoginForm } from 'src/app/models/auth/signup.model';
import { UncompressTokenResult } from 'src/app/utils/auth-types';
import { environment } from 'src/environments/environment';
import { AuthAPIService } from '../requests/auth-api.service';
import { CartBillService } from './cart/cart-service';
import { TokenService } from './token.service';
import { CustomerService } from './user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly _authentication: string;

    constructor(
        private _http: HttpClient,
        private _tokenService: TokenService,
        private _customerService: CustomerService,
        private _authApiService: AuthAPIService,
        private cartBillService: CartBillService
    ) {
        this._authentication = environment.uris.method.authentication;
    }

    /**
     * @method reviewBeforeLogout()
     * @description Check if the user is Requester/Approval to remove the payments from the cart
     */
    reviewBeforeLogout(): Observable<LogoutResponse> {
        const canDeleteCartAutomatically = this._customerService.getCanDeleteCartAutomatically();
        if (canDeleteCartAutomatically) {
            return this.cartBillService.deleteAndGetCart().pipe(
                take(1),
                switchMap(() => {
                    return this._getLogout();
                })
            );
        }

        return this._getLogout();
    }

    /**
     * @method reviewUserRequesterApproval()
     * @description Check if the user is Requester/Approval and if they have payments in the cart
     */

    reviewUserRequesterApproval(): Observable<boolean> {
        const company = this._customerService.getCompanyName();
        if (!!company) {
            return of(this.cartBillService.isThereItemsInCart());
        }
        return of(false);
    }

    /**
     * @method setConfigForPaymentMethodsLibrary()
     * @param (token: string)
     * @description Creates the configuration
     */

    private setConfigForPaymentMethodsLibrary(token: string): void {
        const paymentMethodsConfig = { token, environment: environment.environment };
        SPRINTPAY_PAYMENT_CONFIG(paymentMethodsConfig);
    }

    /**
     * @method initConfigTokens()
     * @param (token: CredentialsTokensType)
     * @description
     */

    initConfigTokens(tokens: CredentialsTokensType) {
        this.setConfigForPaymentMethodsLibrary(tokens.authorizationToken);
        this._tokenService.setAuthTokens(tokens);
    }

    /**
     * @method _getLogout()
     * @param (url: any)
     * @param (refreshToken: any)
     * @description
     */

    private _getLogout(): Observable<LogoutResponse> {
        const refreshToken: any = this._tokenService.getCurrentAccessControl();
        const isTokenExpired = this._tokenService.isTokenExpired(refreshToken);

        if (isTokenExpired) {
            const logoutResponse: LogoutResponse = {
                result: 'SUCCESS, TOKEN EXPIRED OR INVALID',
            };
            return of(logoutResponse);
        } else {
            const url: string = this._authentication + '/logoff';
            return this._http.get<LogoutResponse>(url, { params: { token: refreshToken } }).pipe(
                catchError(() => {
                    const logoutResponse: LogoutResponse = {
                        result: 'FAILURE, LOGOUT API ERROR',
                    };
                    return of(logoutResponse);
                })
            );
        }
    }

    /**
     * @method getIPAddress()
     * @description
     */
    getIPAddress(): Observable<string> {
        return this._http
            .get<{
                ip: string;
            }>('https://api.ipify.org/?format=json')
            .pipe(
                map((ipResponse) => ipResponse?.ip),
                catchError((error) => {
                    if (this._customerService.isYusenLogisticsUser()) {
                        return of('0.0.0.0');
                    }

                    return throwError(() => error);
                })
            );
    }

    /**
     * @method uncompressToken()
     * @param (compressToken: string)
     * @description
     */

    uncompressToken(compressToken: string, queryString?: string): Observable<UncompressTokenResult> {
        const compressUrl: string = `${this._authentication}/uncompress${queryString || ''}`;

        return this._http.post<UncompressTokenResult>(compressUrl, compressToken);
    }

    /**
     * @method compressToken()
     * @param (uncompressToken: string)
     * @description
     */

    compressToken(uncompressToken: string) {
        let uncompressUrl: string = `${this._authentication}/compress`;
        return this._http.post<any>(uncompressUrl, uncompressToken);
    }

    /**
     * @method current_user()
     * @description
     */

    get current_user(): any {
        const user = this._tokenService.getDecodedAccessToken()?.SPRINT_PAY_CUSTOMER;
        return user ? JSON.parse(user) : {};
    }

    /**
     * @method getSprintPayVersion()
     * @description
     */

    getSprintPayVersion(): string {
        return this.current_user.customer.sprintPayVersion;
    }

    /**
     * @method getSprintPayProfileType()
     * @description
     */

    getSprintPayProfileType(): string {
        return this.current_user.customer.profileType;
    }

    /**
     * @method signInAsGuest()
     * @description Sign in as guest
     */

    signInAsGuest(): Observable<CredentialsTokensType> {
        return this._http.get<CredentialsTokensType>(`${this._authentication}/guest?source=sprintpay`, {});
    }

    /**
     * @method validateActivationKey()
     * @description
     */

    validateActivationKey(activationKey: string): Observable<boolean> {
        return this._authApiService.validateActivationKeyRequest(activationKey);
    }

    /**
     * @method signIn()
     * @param (user: LoginForm)
     * @param (guestToken: number | null)
     * @description Sign in as authenticated customer
     */

    signIn(user: LoginForm): Observable<LoginAsCargoSprintUserResponse> {
        const url: string = `${this._authentication}/login?source=sprintpay&timestamp?${new Date().valueOf()}`;

        return this._http.post<LoginAsCargoSprintUserResponse>(url, user);
    }

    /**
     * Validates if the SSO email is valid and returns the redirect URL.
     * @param email - The SSO email to validate.
     * @returns An Observable of type RedirectUrl if the email is valid.
     */
    validateEmailSSO(email: string): Observable<RedirectUrl> {
        const url = `${this._authentication}/sso/redirecturl`;

        return this._http.post<RedirectUrl>(url, { email });
    }
}
