import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';

@Component({
    selector: 'app-add-more-containers',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
    ],
    templateUrl: './add-more-containers.component.html',
})
export class AddMoreContainersComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    hawbItems: any;
    paymentForm!: FormGroup;
    matcher: ErrorMatcher;
    labelForInput: string;
    dynamicField: any;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    private currentHawb: any;
    private readonly currentPayment: any;
    @ViewChild('hawbInput') hawbInput?: ElementRef<HTMLInputElement>;

    @Input() formValidState: any;
    @Output() returnDataContainer: EventEmitter<any> = new EventEmitter<any>();

    @Input() set isDinamycField(isDinamycField: any) {
        this.dynamicField = isDinamycField;
        if (isDinamycField) {
            this._setDynamicValidations(isDinamycField);
        }
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _paymentDetailService: PaymentDetailService
    ) {
        this.hawbItems = [];
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.labelForInput = this.currentPayment?.facility?.hawbFieldLabel;
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }

    ngAfterViewInit(): void {
        if (this.currentPayment?.details) {
            if (this.currentPayment?.details?.hawb !== null) {
                const hawbsList = Array.isArray(this.currentPayment.details.hawb)
                    ? this.currentPayment.details.hawb.join(',')
                    : this.currentPayment.details.hawb;

                this.hawbItems = this.currentPayment.details.hawb ? hawbsList.split(',') : [];
                this.currentHawb = this.hawbItems[this.hawbItems.length - 1];

                this.paymentForm.patchValue({
                    hawb: hawbsList,
                });
                this.returnDataContainer.emit(this.paymentForm.value);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.hawb.markAsTouched();
                    }
                }
            }
        }
    }

    ngOnDestroy(): void {
        const pendingHAWB: string = this.hawbInput?.nativeElement?.value || '';
        if (pendingHAWB.trim()) {
            this.hawbItems.push(pendingHAWB.trim());
            this.hawb.setValue(this.hawbItems.join(','));
            this.returnDataContainer.emit({ hawb: this.hawbItems.join(',') });
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            hawb: new FormControl<any | null>(null, []),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method product()
     * @description: Convenience getter for easy access to form fields
     */

    get hawb(): FormGroup {
        return this.paymentForm.get('hawb') as FormGroup;
    }

    /**
     * @method add()
     * @param (event: MatChipInputEvent)
     * @description Add elements to chip for HAWB
     */

    add(event: MatChipInputEvent): void {
        const value = event.value.split(',');
        value.forEach((item: string) => {
            if (item.trim()) {
                this.hawbItems.push(item.trim());
                this.currentHawb = item;
                this.hawb.setValue(this.hawbItems.join(','));
                this.returnDataContainer.emit(this.paymentForm.value);
            }
            event.chipInput!.clear();
        });
    }

    /**
     * @method remove()
     * @param (item: HawbItem)
     * @description Remove elements to chip for HAWB
     */

    remove(item: any): void {
        this.hawbItems.indexOf(item) >= 0 ? this.hawbItems.splice(this.hawbItems.indexOf(item), 1) : '';
        if (this.hawbItems.length < 1) {
            this.hawbItems = [];
            this.paymentForm.get('hawb')?.setValue(null);
            this.currentHawb = '';
        }
        this.hawb.setValue(this.hawbItems.length < 1 ? null : this.hawbItems.join(','));
        this.returnDataContainer.emit(this.paymentForm.value);
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method keyPressAlphaNumeric()
     * @param (event: any)
     * @description Review if the enter characters it's alphanumeric
     */

    keyPressAlphaNumeric(event: any): boolean {
        if (
            (/[a-zA-Z0-9]$/.test(String.fromCharCode(event.keyCode)) ||
                event.keyCode === 8 ||
                event.keyCode === 9 ||
                event.code.indexOf('Numpad') > -1) &&
            event.keyCode !== 81
        ) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    /**
     * @method setDynamicValidations()
     * @param (field: any)
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    private _setDynamicValidations(field: any): void {
        this.paymentForm.get(field.name)!.setValidators(null);
        this.paymentForm.get(field.name)!.setErrors(null);
        if (field.validations.required) {
            if (field.validations.required.value === true) {
                this.paymentForm.get(field.name)!.addValidators(Validators.required);
            }
        }
        field.validations.minlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.minLength(field.validations.minlength.value))
            : null;
        field.validations.maxlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.maxLength(field.validations.maxlength.value))
            : null;
        field.validations.pattern
            ? this.paymentForm.get(field.name)!.addValidators(Validators.pattern(field.validations.pattern.value))
            : null;
        this.paymentForm.get(field.name)!.updateValueAndValidity();
    }
}
