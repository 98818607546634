import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicField, OptionsInput } from '@cargos/sprintpay-models';
import { Subject, filter, finalize, map, take, takeUntil } from 'rxjs';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { FieldsDynamicFormService } from '../../services/fields.service';
import { DynamicErrorComponent } from '../dynamic-error/dynamic-error.component';

@Component({
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        DynamicErrorComponent,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        NgFor,
        NgIf,
        DisableControlDirective,
    ],
    selector: 'app-dynamic-select',
    templateUrl: './dynamic-select.component.html',
    styleUrls: ['./dynamic-select.component.scss'],
})
export class DynamicSelectComponent implements OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    private fieldWithDependency: boolean;
    private extraData: string;
    public formName: FormGroup;
    public fetchingData = false;
    public options: OptionsInput[] = [];

    @Input() field: DynamicField = {};

    constructor(
        private formGroupDirective: FormGroupDirective,
        private fieldsDynamicFormService: FieldsDynamicFormService,
        private cdRef: ChangeDetectorRef
    ) {
        this.formName = formGroupDirective.control;
    }

    ngAfterViewInit(): void {
        this.setOptions();
        this.subscribeToDependencyData();
        this.fetchDataAutoProvider();
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setOptions(): void {
        if (this.field.options && !this.field.dependentFieldId) {
            this.options = this.field.options;
            this.cdRef.detectChanges();
        }
    }

    subscribeToDependencyData(): void {
        if (!this.field?.dependentFieldId) {
            return;
        }

        this.fieldsDynamicFormService
            .getDependencyData()
            .pipe(
                filter((dependencyDataForm) => dependencyDataForm?.fieldId === this.field.dependentFieldId),
                map((dependencyDataForm) => {
                    return dependencyDataForm?.data?.map((data) => {
                        return {
                            displayValue: data?.description ? data?.name + ' - ' + data?.description : data?.name,
                            value: data?.name || '',
                        };
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((dependencyDataForm) => {
                this.options = dependencyDataForm;
            });
    }

    fetchDataAutoProvider(): void {
        if (this.field.apiProviderData && !this.field.providerId) {
            this.fieldsDynamicFormService
                .fetchDataAutoProvider(this.field)
                .pipe(
                    take(1),
                    map((response) => {
                        if (response.paymentTypes) {
                            return response.paymentTypes.map((item) => ({
                                displayValue: item,
                                value: item,
                            }));
                        }
                        return response?.locations?.map((item) => ({
                            displayValue: item.name,
                            value: item.id,
                        }));
                    }),
                    finalize(() => {
                        this.fetchingData = false;
                    })
                )
                .subscribe({
                    next: (data) => {
                        this.options = data;
                    },
                });
        }
    }

    hasDependencyField(): boolean {
        return !!this.field?.apiProviderData;
    }
}
