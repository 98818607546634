import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FacilityContact } from 'src/app/models/admin/facility-contacts/facility-contact';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../environments/environment';
import { Table } from '../../../models/ui/table.model';
import { PayToFacilityService } from '../../../services/pay-to-facility.service';
import { RestService } from '../../../services/rest.service';
import { SecurityService } from '../../../services/utils/security.service';
import { CustomerService } from '../../../services/utils/user/customer-handler.service';
import { paginatorSize, profileComponents } from '../../../utils/constants';
import { Sections } from '../../../utils/sections';

@Component({
    selector: 'app-facility-contacts',
    templateUrl: './facility-contacts.component.html',
})
export class FacilityContactsComponent implements OnInit {
    noInformation: boolean | undefined;
    noInformationMessage: string;
    amTable!: Table[];
    public facilityContacts: FacilityContact[] = [];
    sortableColumns: string[];
    private readonly _customer: any;
    readonly companyName: string;
    readonly startingLevel: boolean;
    private readonly _facilityUrl;
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;

    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _restService: RestService,
        private _securityService: SecurityService,
        private _payToFacilityService: PayToFacilityService,
        private _paymentFluxService: PaymentFluxService
    ) {
        this._breadcrumbService.set('@facility-contacts', 'Facility contacts');
        this.noInformationMessage = '';
        this.noInformation = undefined;
        this._facilityUrl = environment.uris.method.facilities;
        this._customer = this._customerService.getCustomer();
        this.companyName = this._customer.approvalLevels ? this._customer.approvalLevels.company.name : '';
        this.startingLevel = !!this._sessionService.getElement('startingLevel');
        this._typeSort = '';
        this._fieldToSort = '';
        this.sortableColumns = [];
        this._isSorting = false;
    }

    ngOnInit(): void {
        this.getFacilityContacts();
    }

    /**
     * @method _getFacilityContactsTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getFacilityContactsTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method getFacilityContacts()
     * @param (item?: any)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getFacilityContacts(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const shortUrl: string = `${this._facilityUrl}/getFacilities`;

        let longUrl: string = `${shortUrl}/?page=${currentSize}&size=${pageSize}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }
        longUrl = `${longUrl}&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method search()
     * @param (filter: any) Contains the searchTerm and paginator object
     */

    search(filter: any): void {
        if (filter.value) {
            const pageSize: number =
                filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : paginatorSize[0];
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._facilityUrl}/searchFacilities/?searchTerms=${
                filter.value
            }&page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getFacilityContacts(filter);
        }
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getFacilityContacts();
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Call the APU and return the data
     */

    private _getCompleteData(url: string, type?: string): void {
        this._restService
            .get(url, {})
            .then((result: any) => {
                let columns: string[] = Sections.columnsFacilityContacts;
                let columnsName: string[] = Sections.columnsNameFacilityContacts;
                this.sortableColumns = Sections.columnsSortableFacilityContacts;
                if (
                    !this._securityService.verifyComponentsSecurity(profileComponents.newPaymentFacilityReferences) ||
                    this._customer.isCreditHold
                ) {
                    const buttonColumn = 7;
                    columns[buttonColumn] = 'button-disabled';
                }

                if (result && result.content.length !== 0) {
                    this.facilityContacts = result.content.map((facility: any) => {
                        facility['button'] = 'Pay this facility';
                        facility['deliveryType'] = facility.deliveryType ? facility.deliveryType : 'N/A';
                        facility['city'] = facility.city ? facility.city : 'N/A';
                        facility['address'] = facility.address ? facility.address : 'N/A';
                        facility['phone'] = facility.phone ? facility.phone : 'N/A';
                        facility['firmsCode'] = facility.firmsCode ? facility.firmsCode : 'N/A';
                        facility['airportCode'] = facility.airportCode ? facility.airportCode : 'N/A';
                        facility['id'] = facility.id ? facility.id : 'N/A';
                        facility['link'] = `./paymentDetail/${facility.id}`;
                        return facility;
                    });
                } else if (result && result.content.length === 0 && this.facilityContacts !== undefined) {
                    this.facilityContacts = [];
                }

                this.noInformation = type === 'search' ? false : !(this.facilityContacts.length > 0);
                this.facilityContacts = this.formatDeliveryTypeColumn(this.facilityContacts);
                this._getFacilityContactsTableRender(this.facilityContacts, columns, columnsName, result.totalElements);

                if (this._isSorting) {
                    this._isSorting = false;
                }
            })
            .catch((error: any) => {
                if (this.facilityContacts !== undefined) {
                    this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                }
                if (this._isSorting) {
                    this._isSorting = false;
                } else {
                    this.noInformation = true;
                }
                this._ngxSpinnerService.hide();
            });
    }

    formatDeliveryTypeColumn(facilityContacts: FacilityContact[]): FacilityContact[] {
        if (!Array.isArray(facilityContacts)) {
            return facilityContacts;
        }

        return facilityContacts.map((contact: FacilityContact) => {
            if (contact?.facilityPaymentDeliveryNotificationShort) {
                return {
                    ...contact,
                    deliveryType: contact.facilityPaymentDeliveryNotificationShort,
                };
            }

            if (contact?.deliveryType) {
                let deliveryTypeValue = '';

                switch (contact.deliveryType.toLowerCase()) {
                    case 'electronic':
                        deliveryTypeValue = 'Electronic less than 1 hour';
                        break;
                    case 'messenger':
                        deliveryTypeValue = 'Messenger < 3 hour';
                        break;
                    case 'fedex':
                        deliveryTypeValue = 'FedEx = Next business day by 10:30 AM';
                        break;
                    default:
                        deliveryTypeValue = contact.deliveryType;
                }

                return {
                    ...contact,
                    deliveryType: deliveryTypeValue,
                };
            }

            return contact;
        });
    }

    payToFacility(facility: any): void {
        this._payToFacilityService.payToFacility(facility);
    }
}
