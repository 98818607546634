import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { DynamicField } from '@cargos/sprintpay-models';
import { Subject, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { DynamicFieldsForPayment, DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { DynamicFormCFComponent } from 'src/app/standalone-components/dynamic-form-cf/dynamic-form/dynamic-form.component';
import { InformationCollectorByPaymentService } from './services/information-collector-by-payment.service';

@Component({
    selector: 'app-information-collector-by-payment',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatStepperModule,
        DynamicFormCFComponent,
        MatDividerModule,
    ],
    templateUrl: './information-collector-by-payment.component.html',
    styleUrl: './information-collector-by-payment.component.scss',
})
export class InformationCollectorByPaymentComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public dynamicFieldsForPayment: DynamicFieldsForPayment[] = [];
    public formGroups: FormGroup[] = [];

    @ViewChildren('dynamicForms') dynamicForms: QueryList<DynamicFormCFComponent>;
    @Input() stepperOn = true;
    @Output() processCompleted = new EventEmitter<boolean>();
    @Input() section: number;

    constructor(
        private dynamicsFieldsService: DynamicFieldsService,
        private informationCollectorByPaymentService: InformationCollectorByPaymentService,
        private paymentFluxService: PaymentFluxService
    ) {}

    ngOnInit(): void {
        this.getDynamicFieldsForPayment();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getDynamicFieldsForPayment(): void {
        this.dynamicsFieldsService
            .getLoadingDynamicFields()
            .pipe(
                filter((loading) => !loading),
                switchMap(() => {
                    return this.dynamicsFieldsService.getDynamicFieldsForPayment$().pipe(
                        map((dynamicFieldsForPayment) => {
                            return this.removeSummaryStep(dynamicFieldsForPayment);
                        })
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (dynamicFieldsForPayment) => {
                    this.dynamicFieldsForPayment = dynamicFieldsForPayment;
                    setTimeout(() => {
                        this.formGroups = this.dynamicForms?.map((dynamicForm) => dynamicForm?.dynamicFormGroup) || [];
                        this.informationCollectorByPaymentService.setFormGroups(this.formGroups);
                        const fields = this.dynamicFieldsForPayment[this.section].fields?.filter(
                            (field) => !field.isReplacement
                        );
                        this.paymentFluxService.setField(this.section.toString(), fields);
                        this.saveCollectorFormsOnChange();
                        this.subscribeToCustomerGetInitialData();
                    }, 0);
                },
            });
    }

    removeSummaryStep(dynamicFieldsForPayment: DynamicFieldsForPayment[]): DynamicFieldsForPayment[] {
        return dynamicFieldsForPayment.filter((section) => !section?.label?.toLowerCase()?.includes('summary'));
    }

    onSubmitCollector(): void {
        if (this.informationCollectorByPaymentService.instant_formGroupsValidateForms) {
            this.processCompleted.emit(true);
        } else {
            this.markFormAsTouched();
        }
    }

    onCancelProccess(): void {
        this.processCompleted.emit(false);
    }

    saveCollectorFormsOnChange(): void {
        this.formGroups.forEach((form, index) => {
            let prevValue: (FormGroup | null)[] = [];

            form.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                if (form.valid) {
                    this.informationCollectorByPaymentService.setCollectorForms(form, index!);
                    prevValue[index] = form;
                    this.informationCollectorByPaymentService.setFormGroups(this.formGroups);
                    const dynamicField = this.dynamicFieldsForPayment[this.section].fields || [];
                    let dynamic: { [key: number]: string } = {};

                    const value = Object.keys(form.value).forEach((key: string): void => {
                        let field = dynamicField.find((item: DynamicField): boolean => item.name === key);
                        field?.id && (dynamic[field.id] = form.get(key)?.value);
                    });
                    this.informationCollectorByPaymentService.dynamicValues({ status: 'VALID', value, dynamic });
                } else if (prevValue[index] != null) {
                    this.informationCollectorByPaymentService.setCollectorForms(null, index!);
                    this.informationCollectorByPaymentService.setFormGroups([]);
                    prevValue[index] = null;
                    this.informationCollectorByPaymentService.dynamicValues({ status: 'INVALID' });
                }
            });
        });
    }

    subscribeCollectorForms(): void {
        this.informationCollectorByPaymentService
            .getCollectorForms$()
            .pipe(take(1))
            .subscribe({
                next: (forms) => {
                    if (forms?.length) {
                        forms.forEach((form, index) => {
                            if (form) {
                                this.formGroups[index].patchValue(form.getRawValue());
                            }
                        });
                    }
                },
            });
    }

    subscribeToCustomerGetInitialData(): void {
        this.subscribeCollectorForms();
    }

    onContinue(form: FormGroup): void {
        form.markAllAsTouched();
    }

    markFormAsTouched(): void {
        this.dynamicForms.forEach((form) => {
            form?.dynamicFormGroup?.markAllAsTouched();
        });
    }
}
