import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicField } from '@cargos/sprintpay-models';

@Component({
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatRadioModule, MatInputModule, MatTooltipModule, NgFor],
    selector: 'app-dynamic-radio',
    templateUrl: './dynamic-radio.component.html',
    styleUrls: ['./dynamic-radio.component.scss'],
})
export class DynamicRadioComponent {
    public formName: FormGroup;

    @Input() field: DynamicField | null = null;

    constructor(private formGroupDirective: FormGroupDirective) {
        this.formName = formGroupDirective.control;
    }
}
