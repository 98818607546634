import { Injectable } from '@angular/core';
import { DynamicField } from '@cargos/sprintpay-models';
import { DynamicRequestService } from '@cargos/sprintpay-services';
import { BehaviorSubject, Observable, debounceTime, map, of } from 'rxjs';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';

export interface DependencyDataForm {
    fieldId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

@Injectable({ providedIn: 'root' })
export class FieldsDynamicFormService {
    public dependencyData = new BehaviorSubject<DependencyDataForm | null>(null);
    private prevInputValue: string = ' ';
    private prevInputResponse: any = null;

    constructor(
        private dynamicRequestService: DynamicRequestService,
        private dynamicsFieldsService: DynamicFieldsService
    ) {}

    setDynamicFormFields(values: DependencyDataForm): void {
        this.dependencyData.next(values);
    }

    getDependencyData(): Observable<DependencyDataForm | null> {
        return this.dependencyData.asObservable();
    }

    get instant_dependency_data(): DependencyDataForm | null {
        return this.dependencyData.value;
    }

    fetchDataIfIsProvider(field: DynamicField, value: string = ''): Observable<unknown> {
        const shortValue: string = value.substring(0, 3);
        if (
            (shortValue && shortValue !== this.prevInputValue && shortValue?.length < 4) ||
            this.instant_dependency_data === null
        ) {
            this.prevInputValue = shortValue;

            if (field.apiProviderData) {
                return this.dynamicRequestService.dynamicRequest(field.apiProviderData + shortValue).pipe(
                    map((prevInputResponse) => {
                        this.setDynamicFormFields({
                            fieldId: field.id!,
                            data: prevInputResponse,
                        });

                        this.prevInputResponse = prevInputResponse;

                        return prevInputResponse;
                    }),
                    debounceTime(500)
                );
            } else {
                const options = this.dynamicsFieldsService.getFieldByDependencyFieldId(field.id!);
                const newValue = options
                    ?.filter(
                        (option) =>
                            option.criteria === 'all' ||
                            option.criteria?.split(',').find((criteria) => criteria === value.slice(0, criteria.length))
                    )
                    .map((item) => {
                        return { name: item.value, description: '' };
                    });
                this.setDynamicFormFields({
                    fieldId: field.id!,
                    data: newValue,
                });
                this.prevInputResponse = value;
            }
        }

        return of(this.prevInputResponse);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchDataAutoProvider(field: DynamicField, value: string = ''): Observable<any> {
        return this.dynamicRequestService.dynamicRequest(field.apiProviderData + value).pipe(debounceTime(500));
    }

    fetchAutoValidator(value: string): Observable<boolean> {
        return this.dynamicRequestService.dynamicRequest(value).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((response: any) => {
                return response['valid'] || response['isValid'];
            }),
            debounceTime(500)
        );
    }
}
