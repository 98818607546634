import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentPaymentMethodName } from '@cargos/sprintpay-models';
import { Observable, map, switchMap } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/standalone-components/confirmation-dialog/confirmation-dialog.component';
import { CartAPIService } from '../../requests/cart-api.service';
import { CustomerService } from '../user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    constructor(
        private _customerService: CustomerService,
        private _cartApiService: CartAPIService,
        private _dialog: MatDialog
    ) {}

    // TODO: Move this method to a more appropriate service - low
    createFile(openInvoiceIds: string[]): Observable<string> {
        const activeProfileView = this._customerService.getActiveProfileView();

        return this._customerService.getEnabledPaymentMethodByName$(ComponentPaymentMethodName.BANK_DEBIT).pipe(
            switchMap((enabledPaymentMethod) => {
                const paymentMethod = enabledPaymentMethod?.paymentMethod.paymentToken.paymentMethod;
                const paymentProcessors =
                    enabledPaymentMethod?.paymentProcessors && enabledPaymentMethod?.paymentProcessors[0];

                if (!paymentMethod || !paymentProcessors) return 'Failed';

                return this._cartApiService
                    .payOpenInvoices(
                        { activeProfileView, openInvoiceIds },
                        { paymentMethod, paymentProcessor: paymentProcessors }
                    )
                    .pipe(
                        map(() => {
                            this._dialog.open(ConfirmationDialogComponent, {
                                data: {
                                    title: 'We are processing your request',
                                    description:
                                        'This typically takes less than 5 min and once completed, <br> you will see the file in the Debit history section ',
                                    confirmText: 'CONTINUE',
                                    icon: 'floating-folder',
                                },
                            });

                            return 'Complete';
                        })
                    );
            })
        );
    }
}
