import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    constructor() {}

    /**
     * @method errorTemplate()
     * @param (error: any)
     * @description
     */

    errorTemplate(errorResponse: any): string {
        let template: string = `<ul style='list-style: none; padding:0 1rem'>`;

        const error = typeof errorResponse === 'string' ? this.getErrorsList(errorResponse) : errorResponse;

        const hasErrors: boolean =
            error && error.hasOwnProperty('errors') && Array.isArray(error.errors) && error.errors.length > 0;
        const isInactive: boolean = hasErrors && error.errors[0].includes('Your account is restricted');

        if (isInactive) {
            template += `<li style='padding: 1rem; font-weight: bolder;' class='modal-body__title'><h5>Inactive Account</h5></li>`;
            template += `<li style='text-align: center; margin-bottom:0.5rem'>Your account has been marked as inactive. Please contact support@cargosprint.com for further assistance.</li>`;
            return template;
        }

        template += `<li style='padding: 1rem; font-weight: bolder;' class='modal-body__title'><h5 id="error-modal-title">There was an error with your information, please see below.</h5></li>`;
        if (hasErrors) {
            for (let index = 0; index < error.errors.length; index++) {
                template += `<li id="error-modal-description" style='text-align: center; margin-bottom:0.5rem'>${error.errors[index]}</li>`;
            }
            template += `</ul>`;
        } else {
            template += `<li style='text-align: center; margin-bottom:0.5rem'>Something went wrong, try again later</li></ul>`;
        }
        return template;
    }

    /**
     * @method errorMsg()
     * @param (error: any)
     * @description
     */

    errorMsg(error: any): string {
        let template: string = '';
        if (error && error.errors && error.errors.length > 0) {
            for (let index = 0; index < error.errors.length; index++) {
                const element = error.errors[index];
                index < error.errors.length - 1 ? (template += element + ', ') : (template += element);
            }
        } else {
            template = 'Something went wrong, try again later';
        }
        return template;
    }

    /**
     * @method validateField()
     * @param (id: any)
     * @param (form: any)
     * @description
     */

    validateField(id: any, form: any): void {
        const field = document.getElementById(id)!;
        field.classList.remove('is-invalid');
        if (form.get(id).status === 'INVALID' || form.controls[id].hasError('minlength')) {
            field.classList.add('is-invalid');
            if (form.controls[id].errors.pattern) {
                let err = document.getElementById(id + '-err')!;
                err.innerHTML = ' -Invalid ' + id + ' format';
            }
        }
    }

    /**
     * @method isRight()
     * @param (id: any)
     * @description
     */

    isRight(id: any): void {
        const field = document.getElementById(id)!;
        field.classList.remove('err');
        try {
            let text = document.getElementById(id + '-err')!;
            text.innerHTML = '';
        } catch (err) {}
    }

    handleError(err: HttpErrorResponse): void {
        const error = this.errorMsg(err.error);
        Swal.fire({
            title: 'Something is wrong!',
            width: '45em',
            icon: 'error',
            text: error || 'We are having problems, Please try again.',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
        });
    }

    getErrorsList(errors: string): { errors: string[] } {
        try {
            const errorsList = JSON.parse(errors);
            return errorsList;
        } catch (e) {
            return { errors: [] };
        }
    }
}
