import { Injectable } from '@angular/core';
import { Customer } from '@cargos/sprintpay-models';
import { CanCreateApprovalFlow, PaymentReferenceLookup } from '@cargos/sprintpay-models/dist/src/customer/customer';
import { CustomerService } from './utils/user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class ApprovalFlowLevelsService {
    constructor(private customerService: CustomerService) {}

    isChargeToPayAvailable(chargeAmount: number): boolean {
        const levelsByFlow = this.getLevelsOfApprovalFlowByAmount(chargeAmount);
        if (levelsByFlow === 1) {
            return true;
        }
        return false;
    }

    getPaymentAmounts(): PaymentReferenceLookup[] {
        const customer = this.customerService.getCustomer();
        if (customer) {
            return (
                customer.approvalLevels?.company?.paymentReference?.find(
                    (reference) => reference?.name?.toLowerCase() === 'type'
                )?.paymentReferenceLookups || []
            );
        }
        return [];
    }

    getLevelsOfApprovalFlowByAmount(amount: number): number {
        const type = this.getTypeByAmount(amount);
        if (type) {
            return this.getApprovalFlow(type)?.approvalFlowLevels?.length || 0;
        }
        return 0;
    }

    getTypeByAmount(amount: number): string {
        const paymentType = this.getPaymentAmounts();
        let type: string | undefined = '';
        paymentType?.forEach((paymentAmount: PaymentReferenceLookup) => {
            const criteriaToValidate = paymentAmount?.criteria?.split(',');
            const min = Number(criteriaToValidate?.[0]);
            const max = Number(criteriaToValidate?.[1]);
            if (amount >= min && amount <= max) {
                type = paymentAmount.type;
                return;
            }
        });
        return type;
    }

    getApprovalFlow(type: string): CanCreateApprovalFlow | null {
        const customer: Customer | null = this.customerService.getCustomer();
        return (
            customer?.approvalLevels?.canCreateApprovalFlow?.find((item: CanCreateApprovalFlow) =>
                item.name?.includes(type)
            ) || null
        );
    }
}
