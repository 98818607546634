import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { Locations } from 'src/app/models/utils/locations.model';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { FacilityService } from 'src/app/services/facility.service';
import { DynamicFormComponent } from 'src/app/standalone-components/dynamic-form/dynamic-form.component';
import { Dynamic } from 'src/app/utils/companies/dynamic';
import { Domains, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepper-types';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { RestService } from '../../../../../../../services/rest.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../../../../../services/utils/user/customer-handler.service';
import { InformationCollectorByPaymentService } from '../../information-collector-by-payment/collector/services/information-collector-by-payment.service';
import { PaymentDetailService } from '../../payment-detail.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-company-reference',
    templateUrl: './company-reference.component.html',
})
export class CompanyReferenceComponent implements OnInit, OnDestroy {
    hasExternalLookup: boolean = false;
    companyReferenceForm!: FormGroup;
    matcher: ErrorMatcher;
    onEdition: boolean;
    company: any;
    paymentMethodTypes: any[];
    typeOptions!: string[];
    urlNextStep: string;
    dynamicFormValid: boolean;
    locationAssigned: any;
    locations: Locations[];
    disabledInput: boolean = false;
    private dynamicObject: any;
    private dynamicFormValue: any;
    private dynamicFieldsSection: boolean;
    private readonly currentPayment: Payment | null;
    private readonly paymentsUrl: string;
    private readonly approvalLevels: any;
    private unsubscribe$: Subject<void>;

    @ViewChild(DynamicFormComponent) private _dynamicFormComponent?: DynamicFormComponent;

    constructor(
        private _restService: RestService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _paymentDetailService: PaymentDetailService,
        private _stepperService: StepperService,
        private _formBuilder: FormBuilder,
        private _facilityService: FacilityService,
        private dynamicsFieldsService: DynamicFieldsService,
        private informationCollectorByPaymentService: InformationCollectorByPaymentService
    ) {
        this.unsubscribe$ = new Subject<void>();
        this.dynamicObject = {};
        this.dynamicFieldsSection = false;
        this.dynamicFormValid = false;
        const customer = this._customerService.getCustomer();
        this.company = customer?.approvalLevels?.company || '';
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.onEdition = !!this.currentPayment?.redirectRoute;
        this.paymentsUrl = environment.uris.method.payments;
        this.matcher = new ErrorMatcher();
        this.paymentMethodTypes = [];
        this.approvalLevels = customer?.approvalLevels
            ? customer?.approvalLevels?.company?.paymentReference?.map((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
        this.hasExternalLookup = !!this.currentPayment?.facility?.externalLookup;
        const facility = this.currentPayment?.facility || null;
        this._facilityService
            .getPaymentTypes(facility?.id || '')
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isCustomerEmailInDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : Dynamic.paymentTypesDynamic;
                    }
                    if (
                        this.currentPayment &&
                        this.currentPayment[
                            encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference'
                        ]?.type
                    ) {
                        this.verifyIfPaymentTypeExists(
                            this.currentPayment[
                                encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference'
                            ].type
                        );
                    }
                },
            });
        this.locationAssigned =
            customer?.approvalLevels && customer.approvalLevels.company
                ? customer.approvalLevels.company.firstLocation
                : null;
        this.locations = [];
        this.urlNextStep = this.currentPayment?.redirectRoute
            ? this.currentPayment?.redirectRoute
            : this.company.name && !this.hasExternalLookup
              ? '/admin/facilityPayments/newPayment/flux/paymentDetails'
              : this.company.name && this.hasExternalLookup
                ? '/admin/facilityPayments/newPayment/codafacility/paymentDetails'
                : '/admin/facilityPayments/newPayment/flux/customerReference';
    }

    ngOnInit(): void {
        this._getPaymentAmount(this.approvalLevels);
        this._setFromBuilder();
        this.subscribeHasDynamicFields();
        this.subscribeValidateForms();
        if (this.locationAssigned) {
            this.companyReferenceForm.get('locationId')!.setValue(this.locationAssigned.id);
            this.companyReferenceForm.get('locationName')!.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        if (
            this.currentPayment &&
            this.currentPayment[`${encodeURIComponent(this.company.name.toLowerCase())}CustomerReference`]
        ) {
            this.companyReferenceForm.patchValue(
                this.currentPayment[`${encodeURIComponent(this.company.name.toLowerCase())}CustomerReference`]
            );
            this.type && !this.hasExternalLookup ? this.type.setValidators([Validators.required]) : '';
        }
        if (this.paymentMethodTypes.length <= 1) {
            this.paymentAmount.setValue(this.paymentMethodTypes[0]);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    verifyIfPaymentTypeExists(currentPaymentType: string): void {
        if (!this.typeOptions.includes(currentPaymentType)) {
            this.typeOptions.push(currentPaymentType);
            this.disabledInput = true;
        }
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.companyReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.companyReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.companyReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method _getPaymentAmount()
     * @param (approvalLevels: any)
     * @description Convenience setter for easy access to form fields
     */

    private _getPaymentAmount(approvalLevels: any): void {
        this.paymentMethodTypes = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.companyReferenceForm = this._formBuilder.group({
            paymentAmount: new FormControl<string | null>(null, [Validators.required]),
            type: new FormControl<string | null>(null, [Validators.required]),
            locationId: new FormControl<string | null>(null, [Validators.required]),
            locationName: new FormControl<string | null>(null, [Validators.required]),
        });
        this.companyReferenceForm = this._paymentDetailService.validateCompanyForm(this.companyReferenceForm);
    }

    /**
     * @method getValues()
     * @description
     */
    getValues(): void {
        if (
            this.currentPayment?.id &&
            this.currentPayment[encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference']
                .paymentAmount !== this.companyReferenceForm.get('paymentAmount')?.value
        ) {
            this.currentPayment?.externalData
                ? (this.currentPayment.externalData.type = this.companyReferenceForm.get('paymentAmount')!.value)
                : '';
            this._paymentFluxService.setData(
                encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference',
                this.companyReferenceForm.getRawValue()
            );
            Swal.fire({
                title: 'Important !',
                icon: 'info',
                text: 'You have changed some key information! Please review your request before continuing.',
                showConfirmButton: true,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#14bb9c',
                allowOutsideClick: false,
            }).then((): void => {
                this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
            });
        } else {
            let companyReference = this.companyReferenceForm.getRawValue();
            this._stepperService[StepperSteps.STEP_ZERO].next(true);
            if (this.dynamicFieldsSection) {
                const dynamicFormValue = this.informationCollectorByPaymentService.instant_collector_forms;
                dynamicFormValue.forEach((element) => {
                    const value = element?.getRawValue();
                    Object.keys(value).forEach((key: any): void => {
                        companyReference[key] = value[key];
                    });
                });
            }
            this._paymentFluxService.setData(
                encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference',
                companyReference
            );
            this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
        }
        // }
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this.paymentsUrl + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.companyReferenceForm.get('locationName')!.setValue(item.name);
        }
    }

    subscribeHasDynamicFields(): void {
        this.dynamicsFieldsService
            .getFormFields(0)
            .pipe(take(1))
            .subscribe((response) => {
                this.dynamicFieldsSection = !!response.length;
            });
    }

    subscribeValidateForms(): void {
        this.informationCollectorByPaymentService
            .validateForms()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (validForm) => {
                    this.dynamicFormValid = validForm;
                },
            });
    }
}
