<!--START: CREDIT CARDS LIST -->
<mat-list>
    <!--START: CREDIT CARDS TITLE & CREATE BUTTON -->
    <div mat-subheader>
        <div class="d-flex w-100 mat-subheader__container d-flex justify-content-end align-items-center">
            <div class="d-flex align-items-center justify-content-end mat-subheader__button">
                <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                    (click)="openWarningPopup()"
                >
                    New credit card
                </button>
            </div>
        </div>
    </div>
    <!--END: CREDIT CARDS TITLE & CREATE BUTTON -->
    <!--START: CREDIT CARD LIST ITEM -->
    <ng-container *ngIf="!deletingCard && !isLoading && !!creditCards.length">
        <mat-list-item class="my-3 h-100" *ngFor="let card of creditCards; trackBy: trackBy">
            <div class="d-flex justify-content-between flex-row align-items-center w-100">
                <!--START: CREDIT CARD CARD -->
                <div class="d-flex justify-content-start flex-row align-items-center">
                    <img
                        *ngIf="card.accountingDetails.cardType === 'VISA'"
                        src="assets/images/payment-methods/visa.svg"
                        alt="Visa logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="card.accountingDetails?.cardType === 'MASTERCARD'"
                        src="assets/images/payment-methods/mastercard.svg"
                        alt="Mastercard logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="card.accountingDetails?.cardType === 'DISCOVER'"
                        src="assets/images/payment-methods/discover.svg"
                        alt="Discover logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="card.accountingDetails?.cardType === 'AMERICAN EXPRESS'"
                        src="assets/images/payment-methods/amex.svg"
                        alt="Discover logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="card.accountingDetails?.cardType === 'JCB'"
                        src="assets/images/payment-methods/types/jcb.svg"
                        alt="JCB logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="card.accountingDetails?.cardType === 'UNIONPAY'"
                        src="assets/images/payment-methods/types/unionpay.svg"
                        alt="UNIONPAY logo"
                        class="me-3"
                    />
                    <div class="payment__ending" mat-line>
                        <ng-container
                            *ngIf="card.accountingDetails?.cardType && card.accountingDetails?.lastFourDigits"
                        >
                            <span class="d-block"
                                ><strong>Ending in</strong> •••• {{card.accountingDetails.lastFourDigits}}</span
                            >
                        </ng-container>
                        <span class="d-block">
                            <strong>
                                <ng-container
                                    *ngIf="card.accountingDetails && card.accountingDetails.expirationYear && card.accountingDetails.expirationMonth"
                                >
                                    <span class="d-block">
                                        <ng-container
                                            *ngIf="+card.accountingDetails.expirationYear > currentYear || 
                                                        (+card.accountingDetails.expirationYear === currentYear && +card.accountingDetails.expirationMonth >= currentMonth); else expired"
                                        >
                                            <span class="foreground-disabled-text">
                                                Expiration date: {{ card.accountingDetails.expirationMonth
                                                }}/{{lastTwoDigits(card?.accountingDetails?.expirationYear) }}</span
                                            >
                                        </ng-container>
                                        <ng-template #expired>
                                            <span class="label-delete">
                                                Expired: {{card.accountingDetails.expirationMonth
                                                }}/{{lastTwoDigits(card?.accountingDetails?.expirationYear) }}</span
                                            >
                                        </ng-template>
                                    </span>
                                </ng-container>
                            </strong>
                        </span>
                    </div>
                </div>
                <!--END: CREDIT CARD CARD -->
                <!--START: CREDIT CARD DELETE BUTTON -->
                <div class="d-flex justify-content-end">
                    <div mat-line>
                        <button
                            mat-button
                            color="warn"
                            type="button"
                            class="mat-focus-indicator btn__sprintpay btn__sprintpay--linkDelete text-uppercase p-0 mt-0 border-0 rounded-0"
                            (click)="deleteCard(card.paymentToken?.token)"
                        >
                            Delete
                        </button>
                    </div>
                </div>
                <!--END: CREDIT CARD DELETE BUTTON -->
            </div>
        </mat-list-item>
    </ng-container>
    <!--END: CREDIT CARD LIST ITEM -->
</mat-list>
<!--END: CREDIT CARDS LIST -->
<!--START: NO CREDIT CARD -->
<ng-container *ngIf="noCreditCards && !deletingCard && !isLoading">
    <section class="container-fluid">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center mt-5">
            <div class="row">
                <div class="col-12">
                    <img
                        class="mx-auto d-block noPaymentMethod"
                        src="assets/images/home/frequent-facilities.svg"
                        alt="New user illustration"
                    />
                </div>
                <div class="col-12">
                    <h6 class="d-block text-center mt-5 mb-3">
                        Don’t fret! Click “NEW CREDIT CARD” to store your first payment method
                    </h6>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<!--END: NO CREDIT CARD -->
<!--START: LOADER -->
<ng-container *ngIf="deletingCard || isLoading ">
    <div class="d-flex justify-content-center">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</ng-container>
<!--END: LOADER -->
