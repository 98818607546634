import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentService } from '../../../services/payment.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../services/utils/user/customer-handler.service';
import { FilesContainerComponent } from '../../files/files-container';

@Component({
    selector: 'app-company-customer-reference',
    templateUrl: './company-customer-reference.component.html',
    standalone: true,
    imports: [CommonModule, MatButtonModule, FilesContainerComponent],
})
export class CompanyCustomerReferenceComponent implements OnInit, AfterViewInit, AfterViewChecked {
    public id: string;
    public hasExternalLookup: boolean = false;
    public companyName: string;
    public companyReferenceObj: any;
    public companyReferenceObjCopy: any;
    public companyReferenceTitle: any;
    public locationAssigned: any;
    public isStartingLevel: boolean;
    public pathFiles: string[];
    public titleFile: string;
    private company: any;
    private currentPayment: Payment | null;
    private customer: any;
    private dynamicFields: any;

    @Input() paymentDetail: any;

    constructor(
        private router: Router,
        private paymentFluxService: PaymentFluxService,
        private customerService: CustomerService,
        private paymentService: PaymentService,
        private changeDetectorRef: ChangeDetectorRef,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this.titleFile = '';
        this.pathFiles = [];
        this.dynamicFields = this.paymentFluxService.getDynamicFields();
        this.currentPayment = this.paymentFluxService.getCurrentPayment();
        this.customer = this.customerService.getCustomer();
        this.company = this.customerService.getCompanyName();
        this.companyName = this.company?.name || '';
        this.companyReferenceTitle = {};
        this.id = '';
        this.isStartingLevel = this.customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.getFormFields();
        this.companyReferenceObj =
            this.paymentDetail &&
            this.paymentDetail[`${encodeURIComponent(this.companyName.toLowerCase())}CustomerReference`]
                ? this.paymentDetail[`${encodeURIComponent(this.companyName.toLowerCase())}CustomerReference`]
                : {};
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this.currentPayment !== undefined
                  ? this.currentPayment?.id
                  : '';
        this.locationAssigned =
            this.customer.approvalLevels && this.customer.approvalLevels.company
                ? this.customer.approvalLevels.company.firstLocation
                : null;
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this.currentPayment?.facility
                  ? this.currentPayment.facility.externalLookup
                  : false;
    }

    ngAfterViewInit(): void {
        this.getLabels();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    keys(): string[] {
        return Object.keys(this.companyReferenceObjCopy ? this.companyReferenceObjCopy : []);
    }

    getLabels(): void {
        let title: any;
        this.companyReferenceObjCopy = Object.assign({}, this.companyReferenceObj);
        delete this.companyReferenceObjCopy.locationId;
        if (this.companyReferenceObjCopy && this._hidePaymentAmount) {
            delete this.companyReferenceObjCopy.paymentAmount;
        }
        if (this.companyReferenceObjCopy && this.dynamicFields && this.dynamicFields[0]) {
            Object.keys(this.companyReferenceObjCopy).forEach((key: any): void => {
                title = this.dynamicFields[0].filter((item: any) => item.name === key);
                this.companyReferenceTitle[key] =
                    key === 'paymentAmount'
                        ? 'Payment Amount'
                        : key === 'type'
                          ? 'Type'
                          : key === 'locationName'
                            ? 'Location'
                            : title[0]?.label;
            });
        }
        if (this.dynamicFields?.[0] && this.companyReferenceObjCopy) {
            const fileInput = this.dynamicFields[0]
                .filter((item: any) => item.fieldType === 'file')
                .map((filteredItem: any) => {
                    this.titleFile = filteredItem.label;
                    return filteredItem.name;
                });
            if (this.companyReferenceObjCopy && fileInput && this.companyReferenceObjCopy[fileInput]) {
                this.pathFiles = this.companyReferenceObjCopy[fileInput]?.split(',');
                delete this.companyReferenceObjCopy[fileInput];
            }
        }
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest(): void {
        const redirect: string = this.paymentDetail.redirectRoute ? this.paymentDetail.redirectRoute : this.router.url;
        this.paymentFluxService.setCurrentPayment(this.paymentDetail);
        if (!this.paymentFluxService.getDynamicValues()) {
            this.paymentFluxService.setDynamicValues(this.paymentDetail.dynamicValues);
        }
        this.router.navigate([
            `/admin/facilityPayments/newPayment/flux/${encodeURIComponent(this.companyName)}/Reference`,
        ]);
    }

    getFormFields(): void {
        this.dynamicFields = {};
        this.dynamicFieldsService
            .getFormFields(0)
            .pipe(take(1))
            .subscribe((result): void => {
                result?.map((item) => {
                    if (this.paymentDetail.dynamicValues && item.id && !item.isReplacement) {
                        this.companyReferenceObj[item.name!] =
                            this.paymentDetail.dynamicValues[item.id] !== ''
                                ? this.paymentDetail.dynamicValues[item.id] !== undefined
                                    ? this.paymentDetail.dynamicValues[item.id]
                                    : 'N/A'
                                : 'N/A';
                    }
                });
                this.dynamicFields[0] = result;
                this.getLabels();
            });
    }

    /**
     * @method _hidePaymentAmount()
     * @description Getter to check if customer has only one amount threshold option to hide it in template
     */

    private get _hidePaymentAmount(): boolean {
        const paymentReference = this.customer.approvalLevels?.company?.paymentReference.find(
            (paymentRef: any) => paymentRef.name && paymentRef.name.toLowerCase() === 'type'
        );

        return paymentReference?.paymentReferenceLookups?.length <= 1;
    }
}
