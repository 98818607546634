<!--START: CREDIT CARD ON HOLD-->
<div class="credit-card-hold mb-0 fw-normal" [ngClass]="{'d-none': cardClosed}" role="alert">
    <div class="d-flex">
        <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle exclamation-triangle-warm"></i>
        </div>
        <div class="flex-grow-1 ms-3">
            <button
                id="close-credit-hold-notice"
                type="button"
                class="d-flex align-content-center align-items-center close-banner-button"
                aria-label="Close"
                (click)="closeBanner()"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
            <p id="credit-hold-notice-title"><strong>Credit Hold Notice</strong></p>
            <p id="credit-hold-notice-description">
                Some features are currently disabled because there are unpaid invoices on your account. Please settle
                the outstanding invoices to continue using the payment platform without any interruptions. 
            </p>
        </div>
    </div>
</div>
<!--END: CREDIT CARD ON HOLD-->
