import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FileAttachmentService } from '@cargos/sprintpay-services';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentFluxService {
    private currentPayment: BehaviorSubject<Payment | null>;
    private dynamicfields: BehaviorSubject<any>;
    private dynamicValues: BehaviorSubject<any>;
    private customerReference: BehaviorSubject<string>;

    constructor(
        private sessionService: SessionService,
        private router: Router,
        private fileAttachmentService: FileAttachmentService
    ) {
        this.currentPayment = new BehaviorSubject<Payment | null>(null);
        this.dynamicfields = new BehaviorSubject(null);
        this.dynamicValues = new BehaviorSubject(null);
        this.customerReference = new BehaviorSubject('');
    }

    /**
     * @method getDynamicValues()
     * @description return the value of the dynamic values
     */

    getDynamicValues(): any {
        return this.dynamicValues.value ? this.dynamicValues.value : this.sessionService.getElement('dynamicValues');
    }

    /**
     * @method getDynamicFields()
     * @description return the value of the dynamic fields
     */

    getDynamicFields(): any {
        return this.dynamicfields.value ? this.dynamicfields.value : this.sessionService.getElement('dynamicFields');
    }

    /**
     * @method getCurrentPayment()
     * @description return the value of the current payment
     */

    getCurrentPayment(): Payment | null {
        return this.currentPayment.value ? this.currentPayment.value : this.sessionService.getElement('currentPayment');
    }

    getCurrentFacilityId(): string {
        return this.getCurrentPayment()?.facility?.id || '';
    }
    /**
     * @method getCurrentPayment()
     * @description return the value of the current payment as Observable
     */

    getCurrentPayment$(): Observable<Payment | null> {
        return this.currentPayment.asObservable();
    }

    /**
     * @method getDynamicFields()
     * @description return the value of the current payment as Observable
     */

    getDynamicFields$(): Observable<any> {
        return this.dynamicfields.asObservable();
    }

    /**
     * @method getDynamicValues()
     * @description return the value of the current payment as Observable
     */

    getDynamicValues$(): Observable<any> {
        return this.dynamicValues.asObservable();
    }

    /**
     * @method setCurrentPayment()
     * @param (currentPayment: any)
     * @description set current payment object
     */

    setCurrentPayment(currentPayment: any): void {
        this.sessionService.saveElement('currentPayment', currentPayment);
        this.currentPayment.next(currentPayment);
    }

    /**
     * @method setDynamicValues()
     * @param (dynamicValues: any)
     * @description set dynamic values object
     */

    setDynamicValues(dynamicValues: any): void {
        this.sessionService.saveElement('dynamicValues', dynamicValues);
        this.dynamicValues.next(dynamicValues);
    }

    /**
     * @method setDynamicValues()
     * @param (dynamicFields: any)
     * @description set dynamic values object
     */

    setDynamicFields(dynamicFields: any): void {
        this.sessionService.saveElement('dynamicFields', dynamicFields);
        this.dynamicfields.next(dynamicFields);
    }

    /**
     * @method setData()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in current payment
     */

    setData(step: string, value: any): void {
        let currentPayment: Payment | null = this.getCurrentPayment();
        let newCurrentPayment = currentPayment ? { ...currentPayment, [step]: value } : { [step]: value };
        this.setCurrentPayment(newCurrentPayment);
    }

    /**
     * @method setDataDynamic()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in dynamic values
     */

    setValue(step: string, value: any): void {
        let dynamicValues = this.getDynamicValues() || {};
        dynamicValues[step] = value;
        this.setDynamicValues(dynamicValues);
    }

    /**
     * @method setDataDynamic()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in dynamic values
     */

    setField(step: string, value: any): void {
        let dynamicFields = this.getDynamicFields() || {};
        dynamicFields[step] = value;
        this.setDynamicFields(dynamicFields);
    }

    /**
     * @method removeCurrentPayment()
     * @description remove current payment object
     */

    removeCurrentPayment(): void {
        this.sessionService.removeElement('currentPayment');
        this.currentPayment.next(this.sessionService.getElement('currentPayment'));
    }

    /**
     * @method removeDynamicValues()
     * @description remove current payment object
     */

    removeDynamicValues(): void {
        this.sessionService.removeElement('dynamicValues');
        this.dynamicValues.next(this.sessionService.getElement('dynamicValues'));
    }

    /**
     * @method removeDynamicFields()
     * @description remove current payment object
     */

    removeDynamicFields(): void {
        this.sessionService.removeElement('dynamicFields');
        this.dynamicfields.next(this.sessionService.getElement('dynamicFields'));
    }

    goBackToFacilitySearch(): void {
        const currentPayment = this.getCurrentPayment();
        if (!!currentPayment?.details?.nameFiles) {
            const files = currentPayment?.details?.nameFiles?.split(',') || [];
            files.forEach((path: string): void => {
                this.fileAttachmentService.removeAttachment(path).pipe(take(1)).subscribe();
            });
        }
        if (currentPayment && !currentPayment.id) {
            this.removeCurrentPayment();
            this.removeDynamicFields();
            this.removeDynamicValues();
        }
        this.sessionService.removeElement('payments_selected');
        this.router.navigate([
            currentPayment && currentPayment.redirectRoute
                ? currentPayment.redirectRoute
                : '/admin/facilityPayments/newPayment',
        ]);
    }

    setCustomerReference(reference: string): void {
        this.customerReference.next(reference);
    }

    getCustomerReference$(): Observable<string> {
        return this.customerReference.asObservable();
    }

    get instant_customer_reference(): string {
        return this.customerReference.value || '';
    }
}
