import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/services/home.service';
import { RestService } from 'src/app/services/rest.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-rejected-request',
    templateUrl: './rejected-request.component.html',
})
export class RejectedRequestComponent implements OnInit {
    rejectedRequest: any[];
    private _cartURL: string;

    constructor(
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _customerService: CustomerService,
        private _homeService: HomeService
    ) {
        this.rejectedRequest = [];
        this._cartURL = environment.uris.method.cart;
    }

    ngOnInit(): void {
        this.getRejectedRequest();
    }

    /**
     * @method getRejectedRequest()
     * @description Return all the rejected request by user
     */

    getRejectedRequest(): void {
        this._ngxSpinnerService.show();
        this._restService
            .get(`${this._cartURL}/payment-request/rejected?date=${new Date().valueOf()}`, {})
            .then((result: any): void => {
                this.rejectedRequest = result.content.slice(0, 8);
            })
            .finally((): void => {
                this._homeService.setRejected(!this.rejectedRequest.length);
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method isPaymentOwner()
     * @param (rejectedRequest: any)
     * @description Checks if current customer is owner of request
     */

    isPaymentOwner(rejectedRequest: any): boolean {
        return this._customerService.getCustomerId() === rejectedRequest.originalRequestor.id;
    }
}
