import { Component, ElementRef, OnInit, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Customer } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take, tap } from 'rxjs';
import { UploadPaymentRequestService } from 'src/app/services/requests/upload-payment-request.service';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { CustomerService } from '../../../../services/utils/user/customer-handler.service';
import { blackListFiles } from '../../../../utils/constants';

@Component({
    selector: 'app-upload-request',
    templateUrl: './upload-request.component.html',
})
export class UploadRequestComponent implements OnInit {
    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
    template: any;
    uploadForm!: FormGroup;
    formData: any;
    nameFilesArr: any[];
    private _blackListFile: string[];
    private readonly _paymentsURL: string;

    constructor(
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _breadcrumbService: BreadcrumbService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _restService: RestService,
        private _errorHandlerService: ErrorHandlerService,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private uploadPaymentRequestService: UploadPaymentRequestService
    ) {
        this._breadcrumbService.set('@upload-request', 'Upload request(s)');
        this.nameFilesArr = [];
        this._blackListFile = blackListFiles;
        this._paymentsURL = environment.uris.method.payments;
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.uploadForm = this._formBuilder.group({
            nameFiles: new FormControl<any | null>(null, [Validators.required]),
        });
    }

    /**
     * @method getNameFiles()
     * @description: Convenience getter for easy access to form fields
     */

    get getNameFiles(): FormGroup {
        return this.uploadForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method setNameFiles()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setNameFiles(event: any) {
        this.getNameFiles!.setValue(event);
    }

    /**
     * @method submitToApprover()
     * @description Send the file to create requests
     */

    submitToApprover(): void {
        if (this.nameFilesArr.length == 1) {
            this._ngxSpinnerService.show();
            const customer: Customer | null = this._customerService.getCustomer();
            const locationId: string = customer?.approvalLevels?.company?.firstLocation?.id?.toString() || '';
            this.uploadPaymentRequestService
                .uploadPaymentRequests(locationId, this.formData)
                .pipe(
                    take(1),
                    tap(() => Swal.close()),
                    finalize(() => this._ngxSpinnerService.hide())
                )
                .subscribe({
                    next: (response) => {
                        setTimeout(() => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Successful!',
                                    icon: 'success',
                                    text: response.message,
                                    showConfirmButton: true,
                                    allowOutsideClick: false,
                                }).then(() => {
                                    this._router.navigate(['/app/payments/pending-approval']);
                                });
                            } else {
                                Swal.fire({
                                    title: 'Oops...',
                                    icon: 'error',
                                    text: response.message,
                                    showConfirmButton: true,
                                    confirmButtonText: 'Cancel',
                                    confirmButtonColor: '#14bb9c',
                                    allowOutsideClick: false,
                                });
                                this.nameFilesArr = [];
                            }
                        }, 2000);
                    },
                    error: (err) => {
                        let template: string | Object;
                        if (err?.error && typeof err.error == 'string') {
                            template = JSON.parse(err.error);
                        } else if (err?.error?.errors && err?.error?.errors?.length > 1) {
                            template = this._errorHandlerService.errorTemplate(err.error);
                        } else {
                            template = this._errorHandlerService.errorMsg(err?.error);
                        }
                        Swal.fire({
                            html: `${template}`,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                        });
                    },
                });
        } else {
            Swal.fire({
                title: 'Oops...',
                text: 'Please upload a file',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'Cancel',
                confirmButtonColor: '#14bb9c',
                allowOutsideClick: false,
            });
        }
    }

    /**
     * @method dropHandler()
     * @param (event: any)
     * @description
     */

    dropHandler(event: any): void {
        event.preventDefault();
        if (event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer);
        }
        this.removeDragData(event);
    }

    /**
     * @method dragOverHandler()
     * @param (event: any)
     * @description
     */

    dragOverHandler(event: any): void {
        event.preventDefault();
    }

    /**
     * @method removeDragData()
     * @param (event: any)
     * @description
     */

    removeDragData(event: any): void {
        event.dataTransfer.items ? event.dataTransfer.items.clear() : event.dataTransfer.clearData();
    }

    /**
     * @method handleFileInput()
     * @param (event: any)
     * @description
     */

    handleFileInput(event: any): void {
        const files: FileList = event.files;
        this.getNameFiles.markAsTouched();
        if (!files?.length) {
            return;
        }
        this.template = '';
        this.formData = new FormData();
        if (files.length > 1 || files.length + this.nameFilesArr.length > 1) {
            Swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: 'You may upload at most 1 file CSV',
            });
        } else if (this._verifyFileExtensions(files[0].name)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You file contains different extensions.',
            });
        } else if (files[0].size / 1024 > 101) {
            Swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: 'Please select a file with less than 100 KB',
            });
            this.setNameFiles(null);
            this.uploadForm.value.nameFiles = null;
        } else {
            this.nameFilesArr.push(files[0].name);
            this.formData.append('file', files.item(0));
            if (files && files.item(0)?.name) {
                this.uploadForm.setValue({ nameFiles: files.item(0)!.name });
            }
        }
    }

    /**
     * @method _verifyFileExtensions()
     * @param (fileName: any)
     * @description Verify the file extensions
     */

    private _verifyFileExtensions(fileName: any) {
        let extensionContainsBlackListFile: boolean = false;
        fileName.split('.').map((item: string) => {
            if (this._blackListFile.indexOf(item.toLowerCase()) !== -1) {
                extensionContainsBlackListFile = true;
            }
        });
        return extensionContainsBlackListFile;
    }

    /**
     * @method removeFile()
     * @param (index: any)
     * @description
     */

    removeFile(index: any): void {
        this.nameFilesArr.splice(index, 1);
        this.nameFilesArr = [];
        this.setNameFiles = null;
        this.uploadForm.value.nameFiles = null;
        if (this.fileInput?.nativeElement) {
            this.fileInput.nativeElement.value = '';
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method downLoadExcel()
     * @description download excel template
     */
    downLoadExcel(): void {
        const EXCEL_URL = `${this._paymentsURL}/download/payment/template`;
        this._restService.getFile(EXCEL_URL, {}).subscribe((fileResponse: any) => {
            const link: HTMLAnchorElement = this._renderer.createElement('a');
            const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
            const url: any = this._domSanitizer.sanitize(
                SecurityContext.URL,
                this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
            );
            const extension = this._restService.getExtension(fileResponse.type);
            link.href = url;
            link.download = 'upload-requests-template' + extension;
            link.click();
            window.URL.revokeObjectURL(url);
        });
    }
}
