import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { catchError, debounceTime, forkJoin, of, take } from 'rxjs';
import { CompanyDetails } from 'src/app/models/utils/company';
import { CustomerAPIService } from 'src/app/services/requests/customer-api.service';
import { BranchSelectorService } from 'src/app/services/utils/branch-selector.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-branch-selector',
    standalone: true,
    imports: [CommonModule, SharedModule],
    providers: [CustomerAPIService],
    templateUrl: './branch-selector.component.html',
    styleUrl: './branch-selector.component.scss',
})
export class BranchSelectorComponent implements OnInit {
    @ViewChild('branchSelector') branchSelector!: MatSelect;

    public showBranchSelector = false;
    public selectedBranches: CompanyDetails[] = [];
    public branchesList: CompanyDetails[] = [];
    private appliedBranches: CompanyDetails[] = [];

    constructor(
        private _customerAPIService: CustomerAPIService,
        private _customerService: CustomerService,
        private _branchSelectorService: BranchSelectorService
    ) {}

    ngOnInit(): void {
        this._loadBranches();
    }

    private _loadBranches(): void {
        forkJoin([this._customerService.getParentBranchData$(true), this._customerAPIService.getBranches()])
            .pipe(
                debounceTime(100),
                take(1),
                catchError(() => of([]))
            )
            .subscribe(([parent, branches]) => {
                const availableBranches: CompanyDetails[] = branches;
                this.showBranchSelector =
                    branches?.length === 0 || (branches?.length === 1 && branches[0]?.id === parent?.id) ? false : true;
                this.initBranchSelector(availableBranches);
            });
    }

    private initBranchSelector(branches: CompanyDetails | CompanyDetails[]): void {
        this.branchesList = Array.isArray(branches) ? branches : [branches];
        this.selectedBranches = this.branchesList;
        this.appliedBranches = this.branchesList;
        this._branchSelectorService.saveBranches(this.selectedBranches);
    }

    public onOpenChange(event: boolean): void {
        if (!event) {
            this.selectedBranches = this.appliedBranches;
        }
    }

    public onApplyBranches(): void {
        this.appliedBranches = this.branchSelector.value as CompanyDetails[];
        this._branchSelectorService.saveBranches(this.appliedBranches);
        this.branchSelector.close();
    }

    public getBranchName(id: number | undefined): string {
        return this.branchesList.find((branch) => branch.id === id)?.accountName ?? '';
    }

    public toggleAllItemsSelection(event: MatCheckboxChange): void {
        this.selectedBranches = event.checked ? this.branchesList : [];
    }

    public get hasAllItemsSelected(): boolean {
        return this.branchesList.length === this.selectedBranches.length;
    }

    public get isCheckboxIndeterminate(): boolean {
        return this.selectedBranches.length > 0 && this.selectedBranches.length < this.branchesList.length;
    }
}
