import { Injectable } from '@angular/core';
import { PaymentCart, PaymentCartType, PaymentExternalData } from '@cargos/sprintpay-models';
import { PaymentRequest } from '../models/payments/payment-request.model';
import { PaymentSelected } from '../models/payments/payment-selected.model';
import { Payment } from '../models/payments/payment.model';
import { companyName } from '../utils/constants';
import { PromoCodeByAWBService } from './promo-code.service';
import { CustomerService } from './utils/user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(
        private promoCodeService: PromoCodeByAWBService,
        private _customerService: CustomerService
    ) {}

    /**
     * @method getStandardRequestData()
     * @param (data: any)
     * @description Creates the object to send the request
     */

    getStandardRequestData(currentPayment: Payment, payment?: PaymentSelected, dynamicValues?: any): any {
        const customer = this._customerService.getCustomer();
        const isExpeditors = customer?.expeditors;
        const isGeodis = this._customerService.isCustomerEmailInDomain(companyName.geodis.toLowerCase());

        let request: any = {
            awb: (payment ? currentPayment.details.jobLotNumber : currentPayment.details.awb) || '',
            amount: payment ? payment.amount || 0 : parseFloat(String(currentPayment.details.amount)),
            hawb: currentPayment.details.hawb || '',
            notes: currentPayment.reference?.notes || '',
            attachments: currentPayment.details.nameFiles || '',
            paymentType: payment ? payment.paymentType || '' : currentPayment.details.paymentType || '',
            facility: currentPayment.facility?.id,
            notificationEmail: currentPayment.reference?.email || '',
            customerReference: currentPayment.reference?.customerRef || '',
            paidTo: currentPayment.details.paidTo || 'test',
            id: currentPayment.id || '',
            source: 'sprintpay',
            dynamicValues: dynamicValues,
        };

        if (isExpeditors) {
            request['externalData'] = RequestService.getExpeditorsExternalData(currentPayment);
        }

        if (isGeodis) {
            request['externalData'] = RequestService.getGeodisExternalData(currentPayment);
        }

        request.externalData = {
            awbId: payment?.awbId,
            hawbId: payment?.hawbId,
            paymentType: payment?.paymentType,
            id: payment?.id,
            name: payment?.name,
            source: payment?.source,
            chargeId: payment?.chargeId,
            ...request.externalData,
            ...currentPayment.details.externalData,
        };

        return request;
    }

    /**
     * @method getRequestData()
     * @param (data: any)
     * @description Creates the object to send the request
     */

    getRequestData(
        data: any,
        company?: string,
        dynamicValues?: any,
        companyId?: any,
        paymentAmount?: string,
        externalData?: PaymentExternalData
    ): object {
        let request: any = {
            id: data.id,
            awb: data.details.awb,
            amount: Number.parseFloat(data.details.amount),
            hawb: data.details.hawb || '',
            notes: data.reference && data.reference.notes ? data.reference.notes : '',
            attachments: data.details.nameFiles || '',
            paymentType: RequestService.getPaymentType(data, company),
            facility: data.facility.id,
            notificationEmail: data.reference ? data.reference.email : '',
            customerReference: data.reference ? data.reference.customerRef : '',
            paidTo: data.details.paidTo ? data.details.paidTo : 'test',
        };
        if (company) {
            switch (company) {
                case companyName.emotrans:
                    request['externalData'] = RequestService.getEmotransExternalData(data, paymentAmount);
                    break;
                case companyName.geodis:
                    request['externalData'] = RequestService.getGeodisExternalData(data, paymentAmount);
                    break;
                case companyName.kn:
                    request['externalData'] = RequestService.getKnExternalData(data, paymentAmount);
                    break;
                case companyName.schenker:
                    request['externalData'] = RequestService.getSchenkerExternalData(data, paymentAmount);
                    break;
                case companyName.dhl:
                    request['externalData'] = RequestService.getDhlExternalData(data);
                    break;
                case companyName.rhenus:
                    request['externalData'] = RequestService.getRhenusExternalData(data, paymentAmount);
                    break;
                default:
                    RequestService.getDynamicExternalData(
                        request,
                        data,
                        dynamicValues,
                        companyId,
                        company,
                        paymentAmount
                    );
                    break;
            }
        }
        request.externalData = {
            ...request.externalData,
            ...externalData,
        };
        return request;
    }

    /**
     * @method getExpeditorsExternalData()
     * @param (data: any)
     * @description Return the external data Expeditors
     */

    static getExpeditorsExternalData(data: Payment): object {
        return {
            source: 'EXPEDITORS',
            glCode: data.reference?.glCode || null,
            eiReferenceFileNumber: data.reference?.customerRef || null,
        };
    }

    /**
     * @method getSchenkerExternalData()
     * @param (data: any)
     * @description Return the external data Schenker
     */

    static getSchenkerExternalData(data: Payment, paymentAmount?: string): object {
        return {
            costCenter: data?.schenkerCustomerReference?.costCenter || null,
            type: paymentAmount ? paymentAmount : data?.schenkerCustomerReference?.paymentAmount,
            voucher: data?.schenkerCustomerReference?.voucher,
            referenceNumber: data?.reference?.customerRef || null,
            awbId: data.details.awb,
        };
    }

    /**
     * @method getEmotransExternalData()
     * @param (data: any)
     * @description Return the external data Emotrans
     */

    static getEmotransExternalData(data: Payment, paymentAmount?: string): object {
        return {
            type: paymentAmount ? paymentAmount : data?.emotransCustomerReference?.paymentAmount || null,
            referenceNumber: data?.reference?.customerRef || null,
            awbId: data.details.awb,
            externalReferenceNum: data.reference?.externalReferenceNum || '',
        };
    }

    /**
     * @method getKnExternalData()
     * @param (data: any)
     * @description Return the external data KN
     */

    static getKnExternalData(data: Payment, paymentAmount?: string): object {
        return {
            type: paymentAmount ? paymentAmount : data?.knCustomerReference?.paymentAmount || null,
            referenceNumber: data?.reference?.customerRef || null,
            awbId: data.details.awb,
        };
    }

    /**
     * @method getGeodisExternalData()
     * @param (data: any)
     * @description Return the external data Geodis
     */

    static getGeodisExternalData(data: Payment, paymentAmount?: string): object {
        return {
            type: paymentAmount ? paymentAmount : data?.geodisCustomerReference?.paymentAmount || null,
            referenceNumber: data?.reference?.customerRef || null,
            awbId: data.details.awb,
            externalReferenceNum: data?.reference?.externalReferenceNum || null,
            eiReferenceFileNumber: data.details?.eiReferenceFileNumber || null,
            source: 'sprintpay',
        };
    }

    /**
     * @method getRhenusExternalData()
     * @param (data: any)
     * @description Return the external data Rhenus
     */

    static getRhenusExternalData(data: Payment, paymentAmount?: string): object {
        return {
            type: paymentAmount ? paymentAmount : data?.rhenusCustomerReference?.paymentAmount || null,
            referenceNumber: data?.reference?.customerRef || null,
            awbId: data.details.awb,
            externalReferenceNum: data?.reference?.externalReferenceNum || null,
        };
    }

    /**
     * @method getDhlExternalData()
     * @param (data: any)
     * @description Return the external data Dhl
     */

    static getDhlExternalData(data: Payment): object {
        return {
            source: data?.dhlCustomerReference?.source,
            sourceSystem: data?.dhlCustomerReference?.sourceSystem,
            product: data?.dhlCustomerReference?.product,
            station: data?.dhlCustomerReference?.station,
            type: data?.dhlCustomerReference?.type,
            awbId: data.details.awb,
        };
    }

    /**
     * @method getDynamicExternalData()
     * @param (data: any)
     * @description Return the external data Dhl
     */

    static getDynamicExternalData(
        request: PaymentRequest,
        data: Payment,
        dynamicValues: any,
        companyId: string,
        companyName: string,
        paymentAmount?: string
    ): object {
        const companyRefKey = `${encodeURIComponent(companyName.toLowerCase())}CustomerReference` as
            | 'schenkerCustomerReference'
            | 'geodisCustomerReference'
            | 'rhenusCustomerReference'
            | 'dhlCustomerReference'
            | 'emotransCustomerReference'
            | 'knCustomerReference';

        request['externalData'] = {
            awbId: data?.details?.awb,
            type: paymentAmount ? paymentAmount : data?.[companyRefKey]?.paymentAmount,
            referenceNumber: data?.reference?.customerRef,
        };
        request['company'] = { id: companyId };
        request['dynamicValues'] = dynamicValues;
        return request;
    }

    /**
     * @method getPaymentType()
     * @param (data: any)
     * @description Return the external data Dhl
     */

    static getPaymentType(data: any, company?: string): string {
        let paymentType: string = '';
        if (company) {
            paymentType =
                data?.[`${encodeURIComponent(company.toLowerCase())}CustomerReference`]?.type ||
                data?.details?.paymentType ||
                '';
        } else {
            paymentType = data?.details?.itemType || 'N/A';
        }
        return paymentType;
    }

    // TODO: Validate if the following method is needed, - medium
    // since there is a method called _buildClientCart in the cart-pay.service.ts file
    // that is being used in the same way as the following method
    /**
     * @method updateRequestBeforePay()
     * @param (data: any)
     * @description Creates the object to send the request
     */

    updateRequestBeforePay(cartBill: PaymentCart[]): PaymentCartType[] {
        const newCart: PaymentCartType[] = [];

        cartBill.forEach((request: PaymentCart) => {
            const awbPaidWithPromoCode = this.promoCodeService.isAwbPaidWithPromoCode(request.awb || '');

            let newRequest: PaymentCartType = {
                id: request.id,
                amount: request.amount,
                awb: request.awb,
                cargoFacility: request.facility ? request.facility.name : '',
                customerReference: request.customerReference,
                hawb: request.hawb,
                notes: request.notes,
                paidTo: request.facility ? request.facility.paidTo : '',
                paymentType: request.paymentType,
            };

            if (!!request.nonReservationFee && request.paymentType === 'ISC') {
                newRequest.nonReservationFee = request.nonReservationFee;
                if (awbPaidWithPromoCode) {
                    newRequest.promoCodeId = this.promoCodeService.getPromoCodeByAWB(request.awb || '');
                }
            }
            newCart.push(newRequest);
        });

        return newCart;
    }

    /**
     * @method getStandardRequestDataOfExternalLookup()
     * @param (data: any)
     * @description Creates the object to send the request
     */

    getStandardRequestDataOfExternalLookup(currentPayment: Payment, payment: any): any {
        const customer = this._customerService.getCustomer();
        const isExpeditors = customer?.expeditors;
        const isGeodis = this._customerService.isCustomerEmailInDomain(companyName.geodis.toLowerCase());

        let request: any = {
            awb: (payment ? currentPayment.details.jobLotNumber : currentPayment.details.awb) || '',
            amount: payment ? payment['Amount to pay'] : 0,
            hawb: currentPayment.details.hawb || '',
            notes: currentPayment.reference?.notes || '',
            attachments: currentPayment.details.nameFiles || '',
            paymentType: payment ? payment.paymentType || '' : currentPayment.details.paymentType || '',
            facility: currentPayment.facility?.id,
            notificationEmail: currentPayment.reference?.email || '',
            customerReference: currentPayment.reference?.customerRef || '',
            paidTo: currentPayment.details.paidTo || 'test',
            id: currentPayment.id || '',
            source: 'sprintpay',
        };

        if (isExpeditors) {
            request['externalData'] = RequestService.getExpeditorsExternalData(currentPayment);
        }

        if (isGeodis) {
            request['externalData'] = RequestService.getGeodisExternalData(currentPayment);
        }

        if (payment) {
            request.externalData = {
                awbId: payment.awbId,
                chargeId: payment.chargeId,
                hawbId: payment.hawbId,
                paymentType: payment.paymentType,
                id: payment.id,
                name: payment.name,
                source: payment.source,
            };
        }

        return request;
    }
}
