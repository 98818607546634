import { ComponentType } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Customer, PaymentCart, PaymentCartType } from '@cargos/sprintpay-models';
import { Subject, combineLatest, finalize, map, of, switchMap, take, takeUntil } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { CodaCharge } from 'src/app/models/utils/dynamic-fields-by-house';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { ApprovalFlowLevelsService } from 'src/app/services/approval-flow-levels.service';
import { CodaFluxFormsService } from 'src/app/services/coda-flux-form.service';
import { PaymentRequestService } from 'src/app/services/payment-request.service';
import { RequestService } from 'src/app/services/requests.service';
import { CartHandlerService } from 'src/app/services/utils/cart/cart-handler';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { companyName as companyNameObject } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
import { VoucherRequestComponent } from './voucher-request/voucher-request.component';

@Component({
    selector: 'app-button-charge',
    templateUrl: './button-charge.component.html',
})
export class ButtonChargeComponent implements OnInit, OnDestroy {
    @ViewChild('voucherNumberModal') voucherNumberModal!: ComponentType<VoucherRequestComponent>;

    public isLoading = false;
    public isOnCart: boolean = false;
    public isSubmitted: boolean = false;
    public createApprovalFlow: number = 0;
    public buttonLabel: string = '';
    private paymentRequestId!: number | null;
    public dynamicValues: any;
    public paymentDetailForm!: FormGroup;
    public standardReferenceForm!: FormGroup;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private currentCharge: CodaCharge;
    private typeAmount: string = '';
    private currentPayment: Payment | null;
    private _customer: Customer | null;
    private schenkerCompany: boolean = false;
    private request: any;
    readonly companyName: string;

    @Input() set charge(charge: CodaCharge) {
        this.currentCharge = charge;
        this.createApprovalFlow = this._approvalFlowService.getLevelsOfApprovalFlowByAmount(
            charge['Amount to pay'] || 0
        );
        if (this.createApprovalFlow > 0) {
            this.typeAmount = this._approvalFlowService.getTypeByAmount(charge['Amount to pay'] || 0) || '';
        }
        this.buttonLabel = this.createApprovalFlow <= 1 ? 'ADD TO CART' : 'SUBMIT TO APPROVER';
    }

    constructor(
        private _approvalFlowService: ApprovalFlowLevelsService,
        private _paymentRequestService: PaymentRequestService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _matDialog: MatDialog,
        private _codaFluxFormService: CodaFluxFormsService,
        private _createRequest: RequestService,
        private _errorHandlerService: ErrorHandlerService,
        private cartHandlerService: CartHandlerService,
        private cartBillService: CartBillService,
        private initialConfigService: InitialConfigService
    ) {
        this._customer = this._customerService.getCustomer();
        this.companyName = this._customerService.getCompanyName();
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.schenkerCompany = this.companyName === companyNameObject.schenker;
    }

    ngOnInit(): void {
        this.subscribeCurrentCart();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCurrentCart(): void {
        this.isLoading = true;
        // TODO: We shouldn't subscribe to submitted items and cart items at the same time, we should only subscribe to one of them based on the type of the charge - low
        this.cartBillService
            .getCartPaymentRequests$()
            .pipe(
                switchMap((cart: PaymentCart[]) => {
                    if (!!cart.length) {
                        return this.cartHandlerService.getChargeInPaymentCartByAmountAndTypeAndHawb$(
                            this.currentCharge['Amount to pay'] || 0,
                            this.currentCharge['Description'] || '',
                            this.currentCharge['HBL'] || ''
                        );
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (paymentCart) => {
                    this.isLoading = false;
                    this.paymentRequestId = paymentCart?.id || null;
                    this.isOnCart = !!paymentCart?.id;
                },
                error: () => {
                    this.isLoading = false;
                },
            });

        this.cartHandlerService
            .getSubmittedItems$()
            .pipe(
                switchMap((submittedPayments) => {
                    if (submittedPayments && submittedPayments.length) {
                        return this.cartHandlerService.getChargeSubmittedByAmountAndTypeAndHawb$(
                            this.currentCharge['Amount to pay'] || 0,
                            this.currentCharge['Description'] || '',
                            this.currentCharge['HBL'] || ''
                        );
                    }

                    return of(null);
                }),
                finalize(() => {
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (paymentCart) => {
                    this.isSubmitted = !!paymentCart;
                },
            });
    }

    handleChargeAction(): void {
        combineLatest([
            this._codaFluxFormService.getCustomerReferenceForm(),
            this._codaFluxFormService.getPaymentDetailsForm(),
        ])
            .pipe(
                take(1),
                map(([customerReferenceForm, paymentDetailsForm]) => {
                    return {
                        customerReferenceForm: customerReferenceForm.valid,
                        paymentdetailsForm: paymentDetailsForm.valid,
                    };
                })
            )
            .subscribe({
                next: (form) => {
                    if (!form.paymentdetailsForm || !form.customerReferenceForm) {
                        this._codaFluxFormService.setPaymentDetailsFormAsTouched(true);
                        this._codaFluxFormService.setCustomerReferenceFormAsTouched(true);
                        return;
                    }
                    if (this.createApprovalFlow > 0) {
                        this.startApprovalFlowLevel(this.typeAmount);
                    } else {
                        this.addPaymentRequest(this.currentCharge);
                    }
                },
            });
    }

    removeCharge(): void {
        if (this.paymentRequestId) {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            this._paymentRequestService
                .removePaymentRequest(this.paymentRequestId)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1)).subscribe();
                        this.getAndSetCurrentCharges();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        const errorMsg = this._errorHandlerService.errorMsg(error.error);
                        Swal.fire({
                            html: errorMsg,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }

    getAndSetCurrentCharges(): void {
        this.cartHandlerService
            .remove3rdPartyItems(
                this.currentCharge['Amount to pay'] || 0,
                this.currentCharge['Description'] || '',
                this.currentCharge['HBL'] || ''
            )
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    this.cartHandlerService.reset3rdPartyItems();
                    result?.forEach((item: any) => this.cartHandlerService.setAndSave3rdPartyItems(item));
                },
            });
    }

    startApprovalFlowLevel(typeAmount: string): void {
        this.isLoading = true;
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.dynamicValues = this._paymentFluxService.getDynamicValues();
        const companyId = this._customerService.getCompanyId();
        const source = this._customer?.approvalLevels?.company?.name;
        if (this.currentPayment && this.currentPayment.details) {
            const paymentDetailsSection = this.currentPayment.details;
            paymentDetailsSection.amount = this.currentCharge['Amount to pay'] || 0;
            paymentDetailsSection.paymentType = this.currentCharge['Description'];
            paymentDetailsSection.hawb = this.currentCharge['HBL'];
            paymentDetailsSection.awb = this.currentCharge['HBL'] || '';
            paymentDetailsSection.externalData = { chargeId: this.currentCharge['Charge Code'] };
            this._paymentFluxService.setData('details', paymentDetailsSection);
        }
        this.request = this._createRequest.getRequestData(
            this.currentPayment,
            this.companyName,
            this.dynamicValues,
            companyId,
            typeAmount,
            { chargeId: this.currentCharge['Charge Code'] }
        );
        if (this.request.externalData) {
            this.request.externalData.source = this.currentPayment?.source || source;
        }
        if (this.schenkerCompany) {
            this.openDialog();
        } else {
            this.submitItem(this.currentPayment, this.request);
        }
    }

    setVoucherNumber(voucher: string): void {
        this.request.externalData['voucher'] = voucher;
        this.submitItem(this.currentPayment, this.request);
    }

    submitItem(currentPayment: any, request: any): void {
        const requestAddedToCart = this._approvalFlowService.isChargeToPayAvailable(
            this.currentCharge['Amount to pay'] || 0
        );
        const companyNameObject = encodeURIComponent(this.companyName.toLowerCase());
        const locationId =
            this.companyName && currentPayment[`${companyNameObject}CustomerReference`]
                ? currentPayment[`${companyNameObject}CustomerReference`].locationId
                : this._customer?.approvalLevels
                  ? this._customer?.approvalLevels?.company?.firstLocation?.id
                  : 'N/A';
        this._paymentRequestService
            .startApprovalFlow(request, locationId)
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    if (requestAddedToCart) {
                        this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1)).subscribe();
                        this.currentCharge['Voucher #'] = this.schenkerCompany ? response.externalData?.voucher : '';
                        this.cartHandlerService.setAndSave3rdPartyItems(this.currentCharge);
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: errorMsg,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    addPaymentRequest(charge: CodaCharge): void {
        this.isLoading = true;
        const currentPayment = this._paymentFluxService.getCurrentPayment();
        this.dynamicValues = this._paymentFluxService.getDynamicValues();
        if (currentPayment && currentPayment.details) {
            const paymentDetailsSection = currentPayment.details;
            paymentDetailsSection.amount = charge['Amount to pay'] || 0;
            paymentDetailsSection.paymentType = charge['Description'];
            paymentDetailsSection.hawb = charge['HBL'];
            paymentDetailsSection.awb = charge['HBL'] || '';
            paymentDetailsSection.externalData = { chargeId: charge['Charge Code'] || '' };
            this._paymentFluxService.setData('details', paymentDetailsSection);
        }
        const paymentRequest: PaymentCartType = currentPayment
            ? this._createRequest.getStandardRequestData(currentPayment, undefined, this.dynamicValues)
            : '';
        Object.entries(paymentRequest).map((prop): void => {
            if (!prop[1]) {
                delete paymentRequest[prop[0] as keyof PaymentCart];
            }
        });
        this._paymentRequestService
            .addPaymentRequest(paymentRequest)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(take(1)).subscribe();
                    this.isLoading = false;
                    this.cartHandlerService.setAndSave3rdPartyItems(charge);
                },
                error: (error) => {
                    this.isLoading = false;
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: errorMsg,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    openDialog(): void {
        this._matDialog.open(this.voucherNumberModal, {
            id: 'voucher',
            disableClose: true,
            width: '50%',
            maxWidth: '50%',
        });
    }
}
