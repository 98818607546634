import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { CartHandlerService } from 'src/app/services/utils/cart/cart-handler';
import { CartService } from 'src/app/services/utils/cart/cart-v1.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { companyName as companyNameObject } from 'src/app/utils/constants';
@Component({
    selector: 'app-charges-table',
    templateUrl: './charges-table.component.html',
    styleUrls: ['./charges-table.component.scss'],
})
export class ChargesTableComponent implements OnInit, OnDestroy {
    charges: any = [];
    totalAmount: number = 0;
    displayedColumnsName: string[] = ['HBL', 'Description', 'Amount to pay', 'Select'];
    private unsubscribe$: Subject<void> = new Subject<void>();
    private schenkerCompany: boolean = false;
    @Output() currentCharges: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _customerService: CustomerService,
        private _cartService: CartService,
        private cartHandlerService: CartHandlerService
    ) {
        const companyName = this._customerService.getCompanyName();
        this.schenkerCompany = companyName === companyNameObject.schenker;
    }
    ngOnInit(): void {
        this.getCharges();
        if (this.schenkerCompany) {
            this.displayedColumnsName.splice(3, 0, 'Voucher #');
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getCharges(): void {
        combineLatest([this.cartHandlerService.get3rdPartyItems$(), this.getSubmittedItems()])
            .pipe(
                map(([chargesOnCart, chargesSubmitted]) => {
                    return [...chargesOnCart, ...chargesSubmitted];
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (payments) => {
                    this.currentCharges.emit(payments);
                    this.totalAmount = 0;
                    this.charges = payments;
                    payments?.forEach((charge) => (this.totalAmount += charge['Amount to pay']));
                },
            });
    }

    getSubmittedItems(): Observable<any> {
        return this.cartHandlerService.getSubmittedItems$().pipe(
            map((chargesSubmitted: any) => {
                const submitted: any = [];
                chargesSubmitted?.forEach((charge: any) => {
                    submitted.push({
                        'Amount to pay': charge.amount,
                        Description: charge.paymentType,
                        HBL: charge.hawb,
                        'Voucher #': this.schenkerCompany ? charge.externalData?.voucher : '',
                    });
                });
                return submitted;
            })
        );
    }
}
