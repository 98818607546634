import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { NotAvailablePipe } from 'src/app/pipes/not-available.pipe';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { Action, Data } from 'src/app/utils/bulk-payment-result-types';
import { Sections } from 'src/app/utils/sections';

@Component({
    templateUrl: './bulk-payment-result.component.html',
    standalone: true,
    imports: [CommonModule, MatExpansionModule, MatIconModule, NotAvailablePipe],
    encapsulation: ViewEncapsulation.None,
})
export class BulkPaymentResultComponent implements OnInit, OnDestroy {
    awbHeader: string;
    hawbHeader: string;
    customerRefHeader: string;
    statusIconMap: Record<string, string>;
    callAction: Subject<Action>;

    @Input() data!: Data;

    constructor(
        private _customerService: CustomerService,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this.awbHeader = 'MAWB/MBL';
        this.hawbHeader = 'HAWB/HBL/Container';
        this.customerRefHeader = 'Customer reference';
        this.statusIconMap = {
            success: 'add_shopping_cart',
            failed: 'warning',
            'added to cart': 'add_shopping_cart',
            'submitted to approver': 'send',
        };
        this.callAction = new Subject();
    }

    ngOnInit(): void {
        this._setCustomerReferenceLabel();
    }

    ngOnDestroy(): void {
        this.callAction.complete();
    }

    /**
     * @method emitAction()
     * @param (action: string)
     * @description Emits action to call from external component
     */

    emitAction(action: Action): void {
        this.callAction.next(action);
    }

    /**
     * @method _setCustomerReferenceLabel()
     * @description Set the customer reference title
     */

    private async _setCustomerReferenceLabel(): Promise<void> {
        let customerReferenceTitle = 'Customer reference';
        this.dynamicFieldsService.getFormFields(2).subscribe((dynamicFields): void => {
            const customerRef = dynamicFields?.find((dynamicField) => dynamicField.name === 'customerRef');

            if (customerRef && customerRef.isReplacement && customerRef.label) {
                customerReferenceTitle = customerRef.label;
            } else {
                const customerRef = Sections.customerReferenceStartingLevelLabelTags({
                    company: this._customerService.getCompanyName(),
                    turnOn: true,
                });
                if (customerRef !== '') {
                    customerReferenceTitle = customerRef;
                }
            }
        });

        if (this.data.data.every((item) => !item.errors?.length)) {
            this.customerRefHeader = customerReferenceTitle;
        } else if (this.data.data.every((item) => item.errors?.length)) {
            this.customerRefHeader = 'Field name';
        } else {
            this.customerRefHeader = `Field name/${customerReferenceTitle}`;
        }
    }
}
