<form [formGroup]="formName">
    @if (field.name && field.options) {
    <p>{{field.label}}</p>
    @for (option of field.options; track option; let idx = $index) {
    <mat-checkbox
        class="mat-small w-100"
        color="primary"
        (change)="selectOption(option.value, $event.checked)"
        [checked]="values.includes(option.value)"
    >
        {{option.displayValue}}
    </mat-checkbox>
    } }
</form>
