import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { DynamicField } from '@cargos/sprintpay-models';
import { Validation } from '@cargos/sprintpay-models/dist/src/dynamic-values/dynamic-fields';
import { CustomPatterns, CustomValidators } from '@cargos/sprintpay-utils';
import { DynamicFieldsForPayment } from 'src/app/services/dynamic-fields.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { DynamicCheckboxesComponent } from '../dynamic-field/dynamic-checkboxes/dynamic-checkboxes.component';
import { DynamicFieldComponent } from '../dynamic-field/dynamic-field.component';
import { DynamicInputComponent } from '../dynamic-field/dynamic-input/dynamic-input.component';
import { DynamicRadioComponent } from '../dynamic-field/dynamic-radio/dynamic-radio.component';
import { DynamicSelectComponent } from '../dynamic-field/dynamic-select/dynamic-select.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFieldComponent,
        DynamicInputComponent,
        DynamicSelectComponent,
        DynamicRadioComponent,
        DynamicCheckboxesComponent,
    ],
    selector: 'app-dynamic-form-cf',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormCFComponent {
    public dynamicFormGroup: FormGroup;
    public modelForm: DynamicFieldsForPayment;

    @Input() set modelForms(modelForm: DynamicFieldsForPayment) {
        this.modelForm = this.removeIsReplacement(modelForm);
        this.buildForm();
    }

    constructor(private paymentFluxService: PaymentFluxService) {}

    buildForm(): void {
        const formGroupFields = this.getFormControlsFields();
        this.dynamicFormGroup = new FormGroup(formGroupFields);
    }

    getDynamicValue(field): string {
        const dynamicValues = this.paymentFluxService.getDynamicValues();
        let newValue = '';
        if (dynamicValues) {
            Object.keys(dynamicValues).forEach((key: string) => {
                if (key === field?.id?.toString()) {
                    newValue = dynamicValues[key];
                }
            });
        }
        return newValue;
    }

    getFormControlsFields(): { [key: string]: FormControl<string> } {
        const formGroupFields = {};
        this.modelForm?.fields?.forEach((field: DynamicField) => {
            const fieldProps = field;
            const validators = this.getValidators(fieldProps.validations || []);
            if (field.name) {
                const value = this.getDynamicValue(field);
                formGroupFields[field.name] = new FormControl(value, validators);
            }
        });

        return formGroupFields;
    }

    getValidators(validations: Validation[]): ValidatorFn[] | null {
        if (!validations.length) {
            return null;
        }

        const validators: ValidatorFn[] = [];

        validations?.forEach(({ value, name }) => {
            switch (name) {
                case 'min':
                    validators.push(Validators.min(Number(value)));
                    break;
                case 'max':
                    validators.push(Validators.max(Number(value)));
                    break;
                case 'required':
                    if (value === 'true') {
                        validators.push(Validators.required);
                    }
                    break;
                case 'requiredTrue':
                    if (value === 'true') {
                        validators.push(Validators.requiredTrue);
                    }
                    break;
                case 'email':
                    if (value === 'true') {
                        validators.push(CustomValidators.regex(new RegExp(CustomPatterns.EMAIL)));
                    }
                    break;
                case 'minLength':
                    validators.push(Validators.minLength(Number(value)));
                    break;
                case 'maxLength':
                    validators.push(Validators.maxLength(Number(value)));
                    break;
                case 'pattern':
                    let patterns: string | RegExp | string[] | RegExp[] = [];
                    if (Array.isArray(value)) {
                        patterns = value.map((pattern: string | RegExp) => {
                            let newPattern: string | RegExp = pattern;
                            if (typeof pattern === 'string' || pattern instanceof String) {
                                newPattern = pattern.replace(/^\/|\/$/g, '');
                            }
                            return new RegExp(newPattern);
                        });
                    } else {
                        let newPattern: string = '';

                        if (typeof value === 'string' || value instanceof String) {
                            newPattern = value.replace(/^\/|\/$/g, '');
                        }

                        patterns = new RegExp(newPattern);
                    }

                    validators.push(CustomValidators.regex(patterns, 'pattern'));
                    break;
                case 'nullValidator':
                    if (value === 'true') {
                        validators.push(Validators.nullValidator);
                    }
                    break;
                default:
                    break;
            }
        });

        return validators;
    }

    removeIsReplacement(modelForm: DynamicFieldsForPayment): DynamicFieldsForPayment {
        return {
            ...modelForm,
            fields: modelForm?.fields?.filter((field) => !field.isReplacement),
        };
    }
}
