import { Injectable } from '@angular/core';
import {
    ComponentPaymentMethodName,
    FlowType,
    PayloadPayPal,
    PaymentCart,
    PaymentMetaData,
    PaymentMethods,
    PaymentMethodsType,
    PaymentRequest,
    PaymentResponse,
    PaymentToken,
    ProcessorName,
} from '@cargos/sprintpay-models';
import { PaymentService } from '@cargos/sprintpay-services';
import { Observable } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { PayloadGuest } from 'src/app/utils/cart-types';

interface EnabledPaymentType {
    paymentMethod: { name: ComponentPaymentMethodName };
}

@Injectable()
export class CartPayService {
    constructor(
        private customerService: CustomerService,
        private paymentFluxService: PaymentFluxService,
        private paymentService: PaymentService,
        private cartBillService: CartBillService
    ) {}

    pay(requests: PaymentRequest[], payloadPayPal: PayloadPayPal): Observable<PaymentResponse[]> {
        const payments = requests.map((request) => {
            // getCartPaymentRequests is false due this method is just being used by paypal, if you add another payment method please update the code
            const cartItems = this.cartBillService.getCartPaymentRequests(false);
            const totalAmount = this.cartBillService.getTotal();
            const clientCart = this.buildClientCart(cartItems);

            request =
                request.paymentMethod === PaymentMethods.PAYPAL
                    ? this.buildPaypalPaymentRequest(
                          request.paymentMethod,
                          totalAmount,
                          payloadPayPal,
                          clientCart,
                          request.paymentToken
                      )
                    : this.customerService.isGuest()
                      ? this.buildGuestPaymentRequest(clientCart, payloadPayPal as PayloadGuest)
                      : this.buildPaymentRequest(request);

            return request;
        });

        return this.paymentService.pay(payments);
    }

    /**
     * @method buildClientCart()
     * @param (cart: PaymentCart[])
     * @description Prepare client cart to make payment
     */

    private buildClientCart(cart: PaymentCart[]): PaymentCart[] {
        return cart.map((item) => {
            const clientObject = {
                ...item,
                cargoFacility: item.facility?.name || '',
                paidTo: item.facility?.paidTo || '',
            };

            if (this.customerService.isGuest()) {
                delete clientObject.customer;
                delete clientObject.originalRequestor;
            }

            return clientObject;
        });
    }

    /**
     * @method buildPaymentRequest()
     * @param (paymentMethod: PaymentMethodsType)
     * @param (payload: PaymentToken)
     * @description Prepare payment request for CreditCard, Echeck and CargosprintCredit payment
     */

    // Deprecated
    private buildPaymentRequest(request: PaymentRequest): PaymentRequest {
        // TODO: Why we are getting this from instant_customer_reference? - high
        const customerReference = this.paymentFluxService.instant_customer_reference;
        // const paymentToken = JSON.parse(this.customerService.getDecodedToken().SOURCE).enabledPaymentMethods.find(
        //     ({ paymentMethod }: EnabledPaymentType) =>
        //         paymentMethod.name === ComponentPaymentMethodName.CARGOSPRINT_CREDIT
        // ).paymentMethod.paymentToken;
        const paymentRequest: PaymentRequest = {
            ...request,
            clientCart: this.buildClientCart(request.clientCart),
            customerReference,
        };

        return paymentRequest;
    }

    /**
     * @method buildPaypalPaymentRequest()
     * @param (paymentMethod: PaymentMethodsType)
     * @param (payload: PayloadPayPal)
     * @description Prepare payment request for Paypal payment
     */

    private buildPaypalPaymentRequest(
        paymentMethod: PaymentMethodsType,
        amount: number,
        payload: PayloadPayPal,
        currentCart: PaymentCart[],
        paymentToken: PaymentToken | undefined
    ): PaymentRequest {
        // TODO: Why we are getting this from instant_customer_reference? - high
        const customerReference = this.paymentFluxService.instant_customer_reference;
        // const paymentToken = JSON.parse(this.customerService.getDecodedToken().SOURCE).enabledPaymentMethods.find(
        //     ({ paymentMethod }: EnabledPaymentType) => paymentMethod.name === ComponentPaymentMethodName.PAYPAL
        // ).paymentMethod.paymentToken;
        return {
            paymentMetaData: this.buildPaymentMetadata(payload),
            payPalPaymentDataRequest: {
                accountType: FlowType.Checkout,
                amount,
                email: payload.details?.email || '',
                firstName: payload.details?.firstName || '',
                fullName: payload.details?.shippingAddress?.recipientName,
                lastName: payload.details?.lastName || '',
                payerId: payload.details?.payerId || '',
                paymentNonce: payload.nonce || '',
                phoneNumber: this.customerService.getCustomer()?.phone || '',
            },
            paymentToken,
            clientCart: this.buildClientCart(currentCart),
            paymentMethod,
            customerReference,
        };
    }

    /**
     * @method buildGuestPaymentRequest()
     * @param (payload: PayloadGuest)
     * @description Prepare payment request for guest customer
     */

    private buildGuestPaymentRequest(currentCart: PaymentCart[], payload: PayloadGuest): PaymentRequest {
        const customerReference = this.paymentFluxService.instant_customer_reference;

        return {
            paymentMetaData: {
                ...payload.paymentMetadata,
                customerReference,
            },
            braintreePaymentRequest: {
                ...payload.paymentMetadata,
                customerReference,
                paymentNonce: payload.nonce,
            },
            customerReference,
            paymentNonce: payload.nonce,
            paymentToken: {
                paymentMethod: {
                    name: ComponentPaymentMethodName.CREDIT_CARD,
                    logTime: 10,
                    componentName: PaymentMethods.CREDIT_CARD,
                },
                paymentProcessor: {
                    name: ProcessorName.Braintree,
                },
                token: null,
                paymentMethodNumber: payload.paymentMethodNumber,
                paymentInstitution: payload.paymentInstitution,
                paymentMethodExpirationMonth: payload.paymentMethodExpirationMonth,
                paymentMethodExpirationYear: payload.paymentMethodExpirationYear,
            },
            clientCart: this.buildClientCart(currentCart),
            paymentMethod: PaymentMethods.CREDIT_CARD,
        };
    }

    buildPaymentMetadata(payload: PayloadPayPal): PaymentMetaData {
        const customerInformation = (<PayloadPayPal>payload).details;

        return PaymentMetaData.fromJson({
            firstName: customerInformation?.firstName || '',
            lastName: customerInformation?.lastName || '',
            email: customerInformation?.email || '',
            phoneNumber: customerInformation?.phone || '',
            state: customerInformation?.shippingAddress?.state || '',
            city: customerInformation?.shippingAddress?.city || '',
            country: customerInformation?.shippingAddress?.countryCode || '',
            postalCode: customerInformation?.shippingAddress?.postalCode || '',
            street: customerInformation?.shippingAddress?.line1,
        });
    }
}
