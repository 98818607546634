import { Injectable, signal } from '@angular/core';
import { swalAlert } from '../models/utils/swal.models';

@Injectable({
    providedIn: 'root',
})
export class SwalService {
    public count = signal<number>(0);
    public swals = signal<swalAlert[]>([]);

    addSwal(alertContent: swalAlert): void {
        this.count.update((value) => value + 1);
        const newAlert: swalAlert = { ...alertContent, id: this.count() };
        this.swals.update((swal) => [...swal, newAlert]);
    }

    removeSwal(id: number): void {
        this.swals.update((swals) => [...swals.filter((swal) => swal.id !== id)]);
    }

    clearSwal(): void {
        this.swals.set([]);
    }
}
