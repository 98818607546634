import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { environment } from 'src/environments/environment';
import { UserProperties } from './user';

export class ExpeditorsUser implements UserProperties {
    constructor(
        public dynamicFields: DynamicField[] = [],
        public dynamicSections: DynamicSections[] = []
    ) {
        const paymentURL = environment.uris.method.payments;

        this.dynamicSections = [
            {
                id: 1,
                label: 'Payment Details',
                orderNumber: 0,
                emailDomain: null,
            },
            {
                id: 2,
                label: 'Customer Reference',
                orderNumber: 1,
                emailDomain: null,
            },
        ];
        this.dynamicFields = [
            {
                name: 'eiReferenceFileNumber',
                label: 'File #',
                section: 1,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage:
                    'Your own personal reference # so you can remember this payment. For example your file #.',
                providerId: true,
                apiProviderData: `${paymentURL}/glCodes/search/`,
                validations: [
                    {
                        id: 0,
                        name: 'pattern',
                        value: /^[a-zA-Z0-9]{1,10}$/,
                        message: 'Maximum 10 alpha-numeric characters allowed',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 1,
                        name: 'maxlength',
                        value: '255',
                        message: 'Maximum length exceeded (255 characters maximum)',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your file #.',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                name: 'glCode',
                label: 'GL Code',
                section: 1,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                dependencyFieldName: 'eiReferenceFileNumber',
                validations: [
                    {
                        id: 0,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your file #.',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
        ];
    }
}
